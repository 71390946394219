import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const filterBy = [
  {
    title: "AD",
    value: "AD",
  },
  {
    title: "AA",
    value: "AA",
  },
  {
    title: "AG",
    value: "AG",
  },
  {
    title: "Tampilkan Semua",
    value: "ALL",
  },
] as const;
export const sortBy = [
  {
    title: "Asc Nama Agen",
    value: "asc",
  },
  {
    title: "Desc Nama Agen",
    value: "desc",
  },
] as const;

export type IUnitPayload = {
  filterBy: typeof filterBy[number]["value"]; //ALL,AA,AG,AD
  sortDir: typeof sortBy[number]["value"];
  size: number;
  page: number;
  channelType: "1";
  salesforceId: string | number;
  agentCode: string | number;
};

export interface IUnitInitialState {
  unit: {
    payloads: IUnitPayload;
    state: {
      selectedFilter: typeof filterBy[number];
      selectedSort: typeof sortBy[number];
    };
  };
}

const initialState: IUnitInitialState = {
  unit: {
    payloads: {
      filterBy: "ALL",
      sortDir: "asc",
      size: 10,
      page: 1,
      channelType: "1",
      salesforceId: "",
      agentCode: "",
    },
    state: {
      selectedFilter: filterBy[3],
      selectedSort: sortBy[0],
    },
  },
};

const TaskListSlice = createSlice({
  name: "tasklist",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<typeof filterBy[number]>) => {
      state.unit.state.selectedFilter = action.payload;
      state.unit.payloads.filterBy = action.payload.value;
      state.unit.payloads.size = 10;
    },
    setSort: (state, action: PayloadAction<typeof sortBy[number]>) => {
      state.unit.state.selectedSort = action.payload;
      state.unit.payloads.sortDir = action.payload.value;
      state.unit.payloads.size = 10;
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.unit.payloads.size = state.unit.payloads.size + action.payload;
    },
    setAgentCode: (state, action: PayloadAction<{ salesforceId: string; agentCode: string }>) => {
      state.unit.payloads.salesforceId = action.payload.salesforceId;
      state.unit.payloads.agentCode = action.payload.agentCode;
    },
  },
});

export const { setFilter, setSort, setSize, setAgentCode } = TaskListSlice.actions;
export default TaskListSlice.reducer;
