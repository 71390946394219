import ProfileOrange from "../../assets/profile-orange.png";
import AccessOrange from "../../assets/access-orange.png";
import { useNavigate } from "react-router-dom";

import { useGetSecretaryQuery } from "../../services/secretary";
import { useSessionStorage } from "usehooks-ts";
import { Login } from "../../services/login";
import { Skeleton, Typography } from "@mui/material";
import { EmptyState } from "../../Components/atom/data-grid";
import * as analyticConst from "../../resource/analytic_const";
import { logEvents } from "../../utils/analytics";
import { useEffect } from "react";
const Secretary = () => {
  const navigate = useNavigate();
  const [user] = useSessionStorage<Login | undefined>("user", undefined);
  const { data, isLoading, isError } = useGetSecretaryQuery({
    leaderNumber: user?.agentData.agentNumber || "",
  });
  useEffect(() => {
    logEvents("List Sekretaris", {
      content_id: analyticConst.Sekretaris_Page,
      agent_code: user?.agentData.agentNumber,
    });
  }, []);
  return (
    <>
      <div className="flex flex-col justify-between lg:flex-row gap-6 mb-20 lg:mb-4 h-[48px] lg:items-center">
        <h1 className="font-lato text-[24px] font-extrabold text-[#1F2937] ">
          Daftar Sekretaris/Admin
        </h1>
      </div>
      {isError && (
        <EmptyState
          width={350}
          height={350}
          wording={"Maaf, data tidak tersedia. Mohon coba beberapa saat lagi."}
        />
      )}
      {!isError && data?.length === 0 && (
        <EmptyState width={350} height={350} />
      )}
      {isLoading && <Loader />}
      {(!isError || data?.length !== 0) &&
        data?.map((data, key) => (
          <div
            className="lg:w-[100%] h-full bg-white rounded-lg p-6 flex flex-col justify-between lg:flex-row mb-4 lg:items-center"
            key={key}
          >
            <div className="flex flex-col gap-2 items-center lg:items-start mb-6 lg:mb-0">
              <div className="flex flex-row gap-4 items-center">
                <p className="font-lato text-lg font-bold text-[#1F2937]">
                  {data.secretariesName}
                </p>
                {data.status !== "1" ? (
                  <p className="text-xs font-lato bg-[#FEF2F2] py-[6px] px-[10px] text-primary rounded-md text-center">
                    Tidak Aktif
                  </p>
                ) : (
                  <p className="text-xs font-lato bg-[#F2F7E8] py-[6px] px-[10px] text-[#7DAD2B] rounded-md text-center">
                    Aktif
                  </p>
                )}
              </div>
              <div>
                <button
                  className=""
                  onClick={() =>
                    navigate(`/secretary/${data.pruforceId}`, {
                      state: {
                        data: data,
                      },
                    })
                  }
                >
                  <p className="font-lato text-[16px] text-primary underline font-bold cursor-pointer">
                    Lihat Rincian
                  </p>
                </button>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-1">
              <div className="flex flex-col lg:flex-row gap-4 lg:w-52">
                <div className="flex gap-[14px]">
                  <img
                    src={ProfileOrange}
                    alt="Profile Orange"
                    className="w-[40px] h-[40px]"
                  />
                  <div>
                    <p className="font-lato text-[12px] text-[#9CA3AF] mb-[2px]">
                      PRUForce ID
                    </p>
                    <p className="font-lato text-[14px]  font-bold">
                      {data.pruforceId}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-4 lg:w-52">
                <div className="flex gap-[14px]">
                  <img
                    src={AccessOrange}
                    alt="Profile Orange"
                    className="w-[40px] h-[40px]"
                  />
                  <div>
                    <p className="font-lato text-[12px] text-[#9CA3AF] mb-[2px]">
                      Jenis Akses
                    </p>
                    <p className="font-lato text-[14px]  font-bold">
                      {data.secretariesType}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export const Loader = () => (
  <>
    {Array(10)
      .fill("")
      .map((_, i) => (
        <Typography variant="h1" key={i}>
          <Skeleton />
        </Typography>
      ))}
  </>
);

export default Secretary;
