import { useState, useEffect } from "react";
import ProfileOrange from "../../../assets/profile-orange.png";
import { LoadingCircle } from "../../../Components/Loading/loadingCircle";
import { useGetMyTeamMutation } from "../../../services/myteam";
import { Login } from "../../../services/login";

import { useSessionStorage } from "usehooks-ts";

const Agents = () => {
  const [getMyTeams, { isLoading, isError }] = useGetMyTeamMutation();
  // const [
  //   getCountGroup,
  //   { isLoading: isGetCountGroupLoading, isError: isGetCountGroupError },
  // ] = useGetMyTeamMutation();
  // const [
  //   getCountAdonad,
  //   { isLoading: isGetCountAdonadLoading, isError: isGetCountAdonadError },
  // ] = useGetMyTeamMutation();

  const [data] = useSessionStorage<Login | undefined>("user", undefined);
  // const isAA =
  //   data?.agentData.userType === "agent" && data?.agentData.agentType === "AA";
  const [total, setTotal] = useState({
    group: 0,
    unit: 0,
    adonad: 0,
  });

  const getMyTeam = () => {
    //Get total unit count
    getMyTeams({
      agentCode: data ? data.agentData.agentNumber : "",
      channelType: "1",
      size: 1,
      page: 1,
      sortBy: "",
      sortDir: "asc",
      pageType: "unit",
      agentType: "ALL",
      searchBy: "",
      filterBy: "",
    })
      .unwrap()
      .then((v) => {
        if (v.total) setTotal((prevState) => ({ ...prevState, unit: v.total }));
      })
      .catch((err) => {
        setTotal({
          group: 0,
          unit: 0,
          adonad: 0,
        });
        console.error("Fetch Error");
        console.error(err);
      });

    //Get total group count
    // getCountGroup({
    //   agentCode: data ? data.agentData.agentNumber : "",
    //   channelType: "1",
    //   size: 1,
    //   page: 1,
    //   sortBy: "",
    //   sortDir: "asc",
    //   pageType: "group",
    //   agentType: "ALL",
    //   searchBy: "",
    //   filterBy: "",
    // })
    //   .unwrap()
    //   .then((v) => {
    //     if (v.total)
    //       setTotal((prevState) => ({ ...prevState, group: v.total }));
    //   })
    //   .catch((err) => {
    //     console.error("Fetch Error");
    //     console.error(err);
    //   });

    //Get total adonad count
    // getCountAdonad({
    //   agentCode: data ? data.agentData.agentNumber : "",
    //   channelType: "1",
    //   size: 1,
    //   page: 1,
    //   sortBy: "",
    //   sortDir: "asc",
    //   pageType: "adonad",
    //   agentType: "ALL",
    //   searchBy: "",
    //   filterBy: "",
    // })
    //   .unwrap()
    //   .then((v) => {
    //     if (v.total)
    //       setTotal((prevState) => ({ ...prevState, adonad: v.total }));
    //   })
    //   .catch((err) => {
    //     console.error("Fetch Error");
    //     console.error(err);
    //   });
  };

  useEffect(() => {
    getMyTeam();
  }, []);

  return (
    <div className="flex gap-[26px] lg:flex-row flex-col">
      <div className="flex gap-[14px]">
        <img
          src={ProfileOrange}
          alt="Profile Orange"
          className="w-[40px] h-[40px]"
        />
        <div>
          <p className="font-lato text-[12px] text-[#9CA3AF] mb-[2px]">
            Total Unit
          </p>
          <p className="font-lato text-[14px]  font-bold">
            {isError ? (
              "-"
            ) : isLoading ? (
              <LoadingCircle />
            ) : (
              total.unit + " Agents"
            )}
          </p>
        </div>
      </div>
      {/* Temporarily Hide Total Group & Total AD On AD */}
      {/* {!isAA && (
        <div className="flex gap-[14px]">
          <img
            src={ProfileOrange}
            alt="Profile Orange"
            className="w-[40px] h-[40px]"
          />
          <div>
            <p className="font-lato text-[12px] text-[#9CA3AF] mb-[2px]">
              Total Group
            </p>
            <p className="font-lato text-[14px]  font-bold">
              {isGetCountGroupError ? (
                "-"
              ) : isGetCountGroupLoading ? (
                <LoadingCircle />
              ) : (
                `${total.group} Agents`
              )}
            </p>
          </div>
        </div>
      )}
      {!isAA && (
        <div className="flex gap-[14px]">
          <img
            src={ProfileOrange}
            alt="Profile Orange"
            className="w-[40px] h-[40px]"
          />
          <div>
            <p className="font-lato text-[12px] text-[#9CA3AF] mb-[2px]">
              Total AD on AD
            </p>
            <p className="font-lato text-[14px]  font-bold">
              {isGetCountAdonadError ? (
                "-"
              ) : isGetCountAdonadLoading ? (
                <LoadingCircle />
              ) : (
                total.adonad + " Agents"
              )}
            </p>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Agents;
