import React from "react";
import { CircularProgress } from "@mui/material";

import { LoadingScreenProps } from "./types";

const LoadingScreen: React.FC<LoadingScreenProps> = ({ isLoading = false, className }) => {
  return (
    <>
      {isLoading && (
        <div className={`bg-white w-[100%] flex justify-center items-center ${className !== undefined ? className : ""}`}>
          <CircularProgress sx={{ color: "#ED1B2E" }} />
        </div>
      )}
    </>
  );
};

export default LoadingScreen;
