import { createPortal } from "react-dom";
import { ReactComponent as Info } from "../../../assets/info.svg";
import { ReactComponent as Error } from "../../../assets/error.svg";
import { ReactComponent as Success } from "../../../assets/success.svg";
import { ReactComponent as Warning } from "../../../assets/warning.svg";
import { Button } from "../button";
import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

const portal = document.querySelector("#portal") as HTMLElement;

const types = {
  info: <Info />,
  error: <Error />,
  success: <Success />,
  warning: <Warning />,
  construction: (
    <div className="rounded-full w-16 aspect-square p-2 border-solid border-[5px] border-[#F16F55]">
      <WrenchScrewdriverIcon className="w-full h-full text-[#F16F55]" />
    </div>
  ),
};

interface ModalProps {
  show?: boolean;
  onBackdropPress?: React.MouseEventHandler<HTMLDivElement>;
  type: keyof typeof types;
  cancleButtonText?: string;
  confirmButtonText?: string;
  onCancle?: () => void;
  onConfirm?: () => void;
  title?: string;
  description?: string | ReactNode;
}

const Modal = ({
  show,
  onBackdropPress,
  type,
  cancleButtonText,
  confirmButtonText,
  onCancle,
  onConfirm,
  description,
  title,
}: ModalProps) => {
  if (!show) return null;

  return createPortal(
    <>
      <div
        onClick={onBackdropPress}
        className="w-full h-full bg-[rgba(55,65,81,0.6)] fixed top-0 left-0 right-0 bottom-0 z-[2000] flex items-center justify-center"
      >
        <div
          className="bg-white opacity-100 rounded-lg w-96   "
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={`px-8 pt-8 ${
              !cancleButtonText && !confirmButtonText
                ? "pb-8"
                : title !== "" && title !== null && title !== undefined
                ? ""
                : "pb-2"
            }`}
          >
            <div className="flex justify-center">{types[type]}</div>

            {title !== "" && title !== null && title !== undefined && (
              <div className="flex justify-center mt-[30px]">
                <p className="font-lato text-gray-700 text-xl ">{title}</p>
              </div>
            )}
            <div
              className={`flex justify-center ${
                title !== "" && title !== null && title !== undefined
                  ? "mt-[16px]"
                  : "mt-[24px]"
              }`}
            >
              <p className="font-lato text-gray-700 flex text-center ">
                {description}
              </p>
            </div>
          </div>
          {cancleButtonText || confirmButtonText ? (
            <div className="flex justify-center mt-8 p-4 border-t-[1px] border-[#E9ECEF] gap-6">
              {cancleButtonText && (
                <Button onClick={onCancle} outlined>
                  {cancleButtonText}
                </Button>
              )}
              {confirmButtonText && (
                <Button onClick={onConfirm} className="bg-[#CB1335]">
                  {confirmButtonText}
                </Button>
              )}
            </div>
          ) : null}

        </div>
      </div>
    </>,
    portal
  );
};

export default Modal;
