import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { FileBase64, NewsDetails } from "./types"

export const newsDetailsApi = createApi({
    reducerPath: "newsDetails",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
        prepareHeaders: (headers) => {
            const token_quarkus = sessionStorage.getItem("token")
              ? JSON.parse(sessionStorage.getItem("token") || "")
              : null;
            const token_jboss = sessionStorage.getItem("oldtoken")
              ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
              : null;
            headers.set("Authorization", "");
            if (token_quarkus && token_jboss) {
              headers.set("Authorization", "Bearer " + token_quarkus);
              headers.set("X-Old-Authorization", "Bearer " + token_jboss);
            }
            headers.set("Content-Type", "application/json");
      
            return headers;
          },
    }),
    endpoints: (builder) => ({
        getFileBase64: builder.query<FileBase64, any>({
            query: ({fileName}) => ({
                url: "/base/commonFile/getFileBase64",
                method: "POST",
                body: {
                    fileName,
                    module: "newsUpdate"
                }
            })
        }),
        getDummyNews: builder.query<NewsDetails[], any>({
            query: () => ({
                url: "/newsUpdate/newsListMobile",
                method: "POST",
                body: {
                    "page": 1,
                    "max": 30,
                    "agentNumber": "00000782",
                    "searchBy": "",
                    "filterByCategory": "",
                    "sortBy": "publishDateDesc",
                    "agentType": "AG"
                }
            })
        })
    })
})

export const {
    useGetFileBase64Query, useLazyGetFileBase64Query, useGetDummyNewsQuery, useLazyGetDummyNewsQuery
} = newsDetailsApi