import React, { Suspense, useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Login } from "./services/login";
import Loading from "./Components/Loading";
import { useSessionStorage } from "usehooks-ts";

import DashboardPage from "./pages/Dashboard";
import ProfilePage from "./pages/Profile";
import Help from "./pages/Help";
import DetailSekretaris from "./pages/DaftarSekretaris/detailSekretaris";
import "./utils/analytics";
import Modal from "./Components/atom/modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./store";
import { setUnAuthorizeModal } from "./app.slice";
import NotFound from "./pages/NotFound";
const LoginPage = React.lazy(() => import("./pages/Login/login"));
const NewsDetailPage = React.lazy(() => import("./pages/NewsDetail"));

const MYTeam = React.lazy(() => import("./pages/MyTeam"));
const MYTeamDetail = React.lazy(() => import("./pages/MyTeamDetail"));

const Persistency = React.lazy(() => import("./pages/Persistency"));
const PersistencyRolling = React.lazy(
  () => import("./pages/PersistencyRolling")
);
const PersistencyRollingDetail = React.lazy(
  () => import("./pages/PersistencyRolling/Details")
);
const PersistencyLama = React.lazy(() => import("./pages/PersistencyLama"));
const PersistencyRollingLamaDetail = React.lazy(
  () => import("./pages/PersistencyLama/Details")
);

const News = React.lazy(() => import("./pages/News"));
const RincianMAPA = React.lazy(() => import("./pages/Dashboard/RincianMAPA"));
const Layout = React.lazy(() => import("./Components/Layout/index"));
const FAQ = React.lazy(() => import("./pages/FAQ"));
const Dictionary = React.lazy(() => import("./pages/Dictionary"));
const ProduksiRincian = React.lazy(() => import("./pages/ProduksiRincian"));
const SearchDictionary = React.lazy(
  () => import("./pages/Dictionary/searchDictionary")
);
const DaftarIstilah = React.lazy(
  () => import("./pages/Dictionary/daftarIstilah")
);
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const DaftarSekretaris = React.lazy(() => import("./pages/DaftarSekretaris"));

const Proposal = React.lazy(() => import("./pages/Proposal"));
const RincianProposal = React.lazy(() => import("./pages/Proposal/Rincian"));
const Campaign = React.lazy(() => import("./pages/Campaign"));
const CampaignDetails = React.lazy(() => import("./pages/CampaignDetails"));

const Produksi = React.lazy(() => import("./pages/Produksi"));
const Polis = React.lazy(() => import("./pages/Polis"));
const TaskList = React.lazy(() => import("./pages/TaskList"));
const RincianPolis = React.lazy(() => import("./pages/Polis/Rincian"));
const ProductionDetail = React.lazy(() => import("./pages/ProductionDetail"));
const DocumentDownload = React.lazy(() => import("./pages/DocumentDownload"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const ProduksiUnitPolis = React.lazy(
  () => import("./pages/ProduksiRincian/UnitRincian/Detail")
);

// const NotFound = React.lazy(() => import("./pages/NotFound"));

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [user] = useSessionStorage<Login | undefined>("user", undefined);
  const { unauthorize_modal } = useSelector(
    (state: RootState) => state.appSlice
  );

  const isAgent = user
    ? user.agentData.userType === "agent" && user?.agentData.agentType === "AG"
    : false;
  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute user={!user} redirectPath="/" />}>
          <Route
            path="/login"
            element={
              <Suspense fallback={<Loading />}>
                <LoginPage />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute user={user} />}>
          <Route
            path="/news"
            element={
              <Suspense fallback={<Loading />}>
                <News />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute user={user} />}>
          <Route
            element={
              <Suspense fallback={<Loading />}>
                <Layout />
              </Suspense>
            }
          >
            <Route index element={<DashboardPage />} />
            <Route path="profile" element={<ProfilePage />} />
            {!isAgent && <Route path="profile/myteam" element={<MYTeam />} />}
            {!isAgent && (
              <Route path="profile/myteam/:id" element={<MYTeamDetail />} />
            )}

            <Route path="mapa" element={<RincianMAPA />} />
            <Route path="news/:id" element={<NewsDetailPage />} />
            {/* <Route path="myteam" element={<MYTeam />} /> */}
            <Route path="secretary" element={<DaftarSekretaris />} />
            <Route path="secretary/:id" element={<DetailSekretaris />} />

            <Route path="help" element={<Help />} />
            <Route path="help">
              <Route path="faq" element={<FAQ />} />
              <Route path="privacypolicy" element={<PrivacyPolicy />} />
              <Route path="dictionary" element={<Dictionary />} />
              <Route path="dictionary/search" element={<SearchDictionary />} />
              <Route path="contactus" element={<ContactUs />} />
              <Route
                path="dictionary/search/istilah"
                element={<DaftarIstilah />}
              />
            </Route>

            <Route path="persistency" element={<Persistency />} />

            <Route path="persistency/rolling">
              <Route index element={<PersistencyRolling />} />
              <Route
                path="unit/:agentNumber"
                element={<PersistencyRollingDetail />}
              />
            </Route>
            <Route path="persistency/lama">
              <Route index element={<PersistencyLama />} />
              <Route
                path="unit/:agentNumber"
                element={<PersistencyRollingLamaDetail />}
              />
            </Route>

            <Route path="produksi">
              <Route index element={<Produksi />} />
              <Route path="details">
                <Route index element={<ProductionDetail />} />
                <Route path=":monthYear">
                  <Route index element={<ProduksiRincian />} />
                  <Route
                    path="polis/:polisId"
                    element={<ProduksiUnitPolis />}
                  />
                </Route>
              </Route>
            </Route>

            <Route path="polis">
              <Route index element={<Polis />} />
              <Route path="rincian/:polisId" element={<RincianPolis />} />
              <Route
                path="rincian/:polisId/:agentNumber"
                element={<RincianPolis />}
              />
            </Route>

            <Route path="tasks" element={<TaskList />} />

            <Route path="documents" element={<DocumentDownload />} />
            <Route path="proposal">
              <Route index element={<Proposal />} />
              <Route path="rincian/:polisId" element={<RincianProposal />} />
            </Route>

            <Route path="campaign">
              <Route index element={<Campaign />} />
              <Route path=":campaignId" element={<CampaignDetails />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Modal
        show={unauthorize_modal}
        type="error"
        description="Request tidak valid, silahkan login ulang"
        onConfirm={() => dispatch(setUnAuthorizeModal(false))}
        onBackdropPress={() => dispatch(setUnAuthorizeModal(false))}
        confirmButtonText="Ok"
      />
    </>
  );
}

const ProtectedRoute = ({
  user,
  redirectPath = "/login",
}: {
  user: any;
  redirectPath?: string;
}) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default App;
