import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EmergencyContactResponse } from "./emergencyContact";

export interface PayloadMyteamInterface {
  agentCode: string;
  channelType: string;
  size: number;
  page: number;
  sortBy: string;
  sortDir: string;
  pageType: string;
  agentType: string;
  searchBy: string;
  filterBy?: string;
}

export interface AgentTeamInterface {
  agentType: string;
  agentNumber: string;
  clientName: string;
  [key: string]: any;
}

export interface PayloadMyTeamDetailInterface {
  agentNumber: string;
  agentCode: string;
  isUnit: string;
  channelType: string;
}

export interface TeamSayaResponse {
  data: AgentTeamInterface[];
  total: number;
}

export const myteamApi = createApi({
  reducerPath: "myteamApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      headers.set("Authorization", "");
      if (token) {
        headers.set("Authorization", "Bearer " + token);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMyTeam: builder.mutation<any, PayloadMyteamInterface>({
      query: (payload) => ({
        url: "/web/general/agentprofile/getListAgent",
        method: "POST",
        body: payload,
      }),
      transformResponse: (rawResult: TeamSayaResponse) => {
        if (!rawResult) return;
        let newmap: AgentTeamInterface[] = rawResult.data.map(
          (d: AgentTeamInterface, i: number) => {
            return {
              ...d,
              no: i + 1,
            };
          }
        );

        return { ...rawResult, data: newmap };
      },
    }),
    getMyTeamExcel: builder.mutation<any, PayloadMyteamInterface>({
      query: (payload) => ({
        url: "/web/general/agentprofile/getListAgent",
        method: "POST",
        body: payload,
      }),
    }),
    getEmergencyContactMyTeamDetail: builder.query<
      EmergencyContactResponse,
      any
    >({
      query: (payload) => {
        return {
          url: "/web/emergency/EmergencyContact/showEmergencyContact",
          method: "GET",
          params: {
            agentNum: payload.agentData,
          },
        };
      },
    }),
  }),
});

export const {
  useGetMyTeamMutation,
  useGetMyTeamExcelMutation,
  useLazyGetEmergencyContactMyTeamDetailQuery,
} = myteamApi;
