import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface CampaignDetailsType {
  id: number;
  campaignName: string;
  campaignCode: string;
  description: string;
  syaratKetentuan: string;
  periodDateFrom: string;
  periodDateTo: string;
  updatedDate: string;
  updatedBy: string;
  agentType: string;
  campaignType: boolean;
  fileNameExcel: null;
  fileNameMajor: null;
  fileNameNBAdditional: null;
  fileNameMajorAdditional: null;
  imageName: string;
  imageBase64: null;
  fileBase64: null;
  defaultView: null;
  basePathFile: null;
  basePathFileMajor: null;
  basePathFileNBAdditional: null;
  basePathFileMajorAdditional: null;
  title: null;
  start: null;
  end: null;
  priority: number;
  campaignStatus: null;
  allClient: boolean;
  filteredExistingClient: boolean;
  newClient: boolean;
  allClientMajor: boolean;
  filteredExistingMajor: boolean;
  clientTypeNB: boolean;
  clientTypeMajor: boolean;
  selectedNbAdditional: null;
  selectedMajorAdditional: null;
  processUploadAdditionalNB: null;
  processUploadAdditionalMajor: null;
  published: boolean;
  createdDate: string;
  createdBy: string;
  dataClient: null;
  dataAdditionalDto: null;
  dataMajorAdditionalDto: null;
  fileAttahmentDto: null;
  dataAdditional: DataAdditional;
  dataAdditionalMajor: DataAdditionalMajor;
  customerCampaign: null;
}

export interface DataAdditional {
  additional5: string;
  additional3: string;
  download4: string;
  additional4: string;
  download5: string;
  additional1: string;
  download2: string;
  additional2: string;
  download3: string;
  download1: string;
  id: string;
}

export interface DataAdditionalMajor {}

const campaignApi = createApi({
  reducerPath: "campaignApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    //The other campaign endpoints are located in news services
    getCampaignDetail: builder.query<
      CampaignDetailsType,
      { campaignId: string }
    >({
      query: ({ campaignId }) => ({
        url: "/web/resource/campaign/getListCampaign",
        method: "POST",
        body: {
          id: campaignId,
        },
      }),
      transformResponse: (res: CampaignDetailsType[]) => {
        return res[0];
      },
    }),
  }),
});

export const { useGetCampaignDetailQuery } = campaignApi;

export default campaignApi;
