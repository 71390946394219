import React from "react";
import toastss, { Toast as Toasts } from "react-hot-toast";

const types = {
  error: {
    bg: "#F45151",
    color: "#F9FAFB",
  },
};

interface IToast {
  t: Toasts;
  type: keyof typeof types;
  children?: React.ReactNode;
}

export const Toast: React.FC<IToast> = ({ t, type, children }) => {
  return (
    <div
      className={`${t.visible ? "animate-enter" : "animate-leave"} max-w-md w-full p-3 bg-[${
        types[type].bg
      }] shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 justify-between`}>
      <p className={`font-lato text-sm text-[${types[type].color}] text-center`}>{children}</p>
      <button onClick={() => toastss.remove(t.id)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill={`${types[type].color}`}
          className='w-5 h-5 '>
          <path d='M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z' />
        </svg>
      </button>
    </div>
  );
};
