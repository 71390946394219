import React from "react";

import "./personal-data.css";
import chatInfo from "../../../assets/chat-info.svg";
import bookAlt from "../../../assets/book-alt.svg";
import postCard from "../../../assets/postcard.svg";

import IconedTitle from "./IconedTitle";
import LoadingScreen from "../LoadingScreen";
import EmptyCPDBanner from "../EmptyCPDBanner";

import { User, PersonalDataTabProps } from "./type";

import { useSessionStorage } from "usehooks-ts";

const monthIDN = [
  { month: "Januari" },
  { month: "Februari" },
  { month: "Maret" },
  { month: "April" },
  { month: "Mei" },
  { month: "Juni" },
  { month: "Juli" },
  { month: "Agustus" },
  { month: "September" },
  { month: "Oktober" },
  { month: "November" },
  { month: "Desember" },
];

const PersonalDataTab: React.FC<PersonalDataTabProps> = ({
  emergencyContact,
  isEmergencyContactFetching,
}) => {
  
  const [user] = useSessionStorage<User | undefined>("user", undefined);
  const renderAddress = () => {
    let address = [];
    if (user?.agentData.address1) address.push(user?.agentData.address1);
    if (user?.agentData.address2) address.push(user?.agentData.address2);
    if (user?.agentData.address3) address.push(user?.agentData.address3);
    if (user?.agentData.address4) address.push(user?.agentData.address4);
    if (user?.agentData.address5) address.push(user?.agentData.address5);
    if (address.length) return address.join("-");
    return "-";
  };
  const renderAddressEmergency = () => {
    if (emergencyContact?.length) {
      let address = [];

      if (emergencyContact[0].address1)
        address.push(emergencyContact[0].address1);
      if (emergencyContact[0].address2)
        address.push(emergencyContact[0].address2);
      if (emergencyContact[0].address3)
        address.push(emergencyContact[0].address3);
      if (address.length) return address.join(", ");
      return "-";
    } else {
      return "-";
    }
  };

  const renderDate = (props: any) => {
    let fixedProps = "";
    if (user?.agentData.dateOfBirth) {
      if((props.length > 1 && props.length < 10)) {
        let yearSub = props.substring(0, 4);
        let monthSub = props.substring(4, 6);
        let daySub = props.substring(6, 8);
        fixedProps = yearSub + '-' + monthSub + '-' + daySub;
      }

      let fullDate = new Date((props.length > 1 && props.length < 10) ? fixedProps : props);
      let date = fullDate.getDate();
      let month = fullDate.getMonth();
      let year = fullDate.getFullYear();
      let dateIndonesia = date + " " + monthIDN[month].month + " " + year;
      return dateIndonesia;
    } else {
      return "-";
    }
  };

  return (
    <div className="personal-data-tab-container grid content-center lg:grid-rows-2 lg:grid-cols-2 gap-y-[3rem] lg:gap-[2rem]">
      <section className="lg:row-span-2 w-full">
        <IconedTitle
          headerTag="3"
          src={postCard}
          title="Identitas"
          alt="Post Card"
        />
        <table className="personal-data-content font-lato ml-4 text-[14px] md:text-[16px]">
          <tbody>
            <tr>
              <td>Nomor Identitas</td>
              <td>
                {user?.agentData.idNumber ? user?.agentData.idNumber : "-"}
              </td>
            </tr>
            <tr>
              <td>Tanggal Lahir</td>
              <td>{renderDate(user ? user?.agentData.dateOfBirth : "-")}</td>
            </tr>
            <tr>
              <td>Status Pernikahan</td>
              <td>{user?.agentData.marital ? user?.agentData.marital : "-"}</td>
            </tr>
            <tr>
              <td>Alamat Rumah</td>
              <td>{renderAddress()}</td>
            </tr>
            <tr>
              <td>Lokasi Kantor</td>
              <td>{user?.agentData.office ? user?.agentData.office : "-"}</td>
            </tr>
            <tr>
              <td>Data Rekening</td>
            </tr>
            <tr>
              <td className="indented">Nama Bank</td>
              <td>
                {user?.agentData?.agentBankNameBABR
                  ? user.agentData.agentBankNameBABR
                  : "-"}
              </td>
            </tr>
            <tr>
              <td className="indented">Nomor Rekening</td>
              <td>
                {user?.agentData.agentAccountNumber
                  ? user?.agentData.agentAccountNumber
                  : "-"}
              </td>
            </tr>
            <tr>
              <td className="indented">Nama Pemilik Rekening</td>
              <td>
                {user?.agentData.agentAccountName
                  ? user?.agentData.agentAccountName
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <IconedTitle headerTag="3" src={bookAlt} title="Kontak" alt="Book" />
        <table className="personal-data-content ml-4 text-[14px] md:text-[16px]">
          <tbody>
            <tr>
              <td>Email</td>
              <td>
                {user?.agentData.agentEmailAddress
                  ? user?.agentData.agentEmailAddress
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Nomor Handphone</td>
              <td>
                {user?.agentData.agentMobilephone1
                  ? user?.agentData.agentMobilephone1
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Nomor Telp. Rumah</td>
              <td>
                {user?.agentData.agentMobilephone2
                  ? user?.agentData.agentMobilephone2
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Nomor Telp. Kantor</td>
              <td>
                {user?.agentData.agentMobilephone3
                  ? user?.agentData.agentMobilephone3
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <IconedTitle
          headerTag="3"
          src={chatInfo}
          title="Kontak Darurat"
          alt="Chat Info"
        />
        {!isEmergencyContactFetching ? (
          emergencyContact !== undefined && emergencyContact.length > 0 ? (
            <table className="personal-data-content ml-4 text-[14px] md:text-[16px]">
              <tbody>
                <tr>
                  <td>Nama</td>
                  <td>
                    {emergencyContact[0].contactName
                      ? emergencyContact[0].contactName
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Hubungan</td>
                  <td>
                    {emergencyContact[0].relation
                      ? emergencyContact[0].relation
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Nomor Telepon</td>
                  <td>
                    {emergencyContact[0].phoneNumber
                      ? emergencyContact[0].phoneNumber
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Alamat Rumah</td>
                  <td>{renderAddressEmergency()}</td>
                </tr>
              </tbody>
            </table>
          ) : emergencyContact === undefined ? (
            <EmptyCPDBanner message="Gagal memuat kontak darurat. Silahkan coba beberapa saat lagi." />
          ) : (
            <EmptyCPDBanner message="Tidak dapat menemukan kontak darurat." />
          )
        ) : (
          <LoadingScreen isLoading={true} />
        )}
      </section>
    </div>
  );
};

export default PersonalDataTab;
