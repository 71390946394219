import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface RincianPembayaranPremiData {
  boServiceStatus: string;
  descriptionStatus: string;
  dataAvailability: string;
  emailServiceStatus: string;
}

export const rincianPembayaranPremiApi = createApi({
  reducerPath: "rincianPembayaranPremi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendMailPembayaranPremi: builder.mutation<RincianPembayaranPremiData, any>({
      query: ({ policyNumber, agentCode, email }) => ({
        url: "/web/policy/sendMailPremium",
        method: "POST",
        body: {
          policyNumber,
          email,
          agentCode,
        },
      }),
    }),
  }),
});

export const { useSendMailPembayaranPremiMutation } = rincianPembayaranPremiApi;
