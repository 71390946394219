// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
let analytics: any;
if (process.env.REACT_APP_FIREBASE_APIKEY) {
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

type TEventParams = {
  content_id: string;
  [key: string]: any;
};

export const logEvents = (eventName: string, eventParams: TEventParams) => {
  let eventParamsTemp = {};
  if (eventParams.agent_code) {
    eventParamsTemp = {
      ...eventParams,
      agent_code: "agent-" + eventParams.agent_code,
    };
  } else {
    eventParamsTemp = eventParams;
  }
  logEvent(analytics, eventName, eventParamsTemp);
};
