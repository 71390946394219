import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  PieChart,
} from "recharts";

const renderLeftAxis = (value: any, type: string) => {
  if (type === "percentage") {
    return value + "%";
  } else {
    if (value.length <= 3) {
      return parseInt(value).toFixed().toString();
    } else if (value.length > 3 && value.length <= 6) {
      return (parseInt(value) / 10000).toFixed().toString();
    } else if (value.length > 6 && value.length < 10) {
      return (parseInt(value) / 1000000).toFixed().toString() + "jt";
    } else if (value.length >= 10) {
      return (parseInt(value) / 1000000000).toFixed(1).toString() + "m";
    } else {
      return value;
    }
  }
};

// const renderRightAxis = (value: any) => {
//   console.log(value);

//   return value;
// };
// const renderMinMaxGrowth = (data: any) => {
//   let min = Math.min(...data.map((d: any) => parseInt(d.growth)));
//   let max = Math.max(...data.map((d: any) => parseInt(d.growth)));

//   return [min - 10, max + 10];
// };

const renderColorLegendText = (value: string) => {
  return (
    <span style={{ color: "#314151" }}>
      {isNaN(Number(value)) ? value[0].toUpperCase() + value.slice(1) : value}
    </span>
  );
};

export interface ChartBarProps {
  data: any;
  children: React.ReactNode;
  type: string;
  height?: string | number;
  CustomTooltip?: any;
  allowDecimals?: boolean;
}

export const ChartBar: React.FC<ChartBarProps> = ({
  data,
  children,
  type,
  CustomTooltip,
  height,
  allowDecimals = true,
}) => {
  const renderRightAxis = () => {
    let findBigGrowth = data.filter(
      (d: any) => d.growth > 100 || d.growth < -100
    );
    if (findBigGrowth.length) {
      // let max = Math.max(...findBigGrowth.map((d: any) => parseInt(d.growth)));
      return 68;
    }
    return 48;
  };
  return (
    <ResponsiveContainer width="100%" height={height ? height : "80%"}>
      <ComposedChart
        margin={{ top: 10, right: 0, bottom: 0, left: 0 }}
        data={data}
        barGap={0}
      >
        <CartesianGrid stroke="#ccc" vertical={false} strokeDasharray="1 1" />
        <XAxis
          tickLine={false}
          axisLine={false}
          dataKey="name"
          style={{
            fontSize: 11.5,
          }}
          tickMargin={10}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          yAxisId="left"
          allowDecimals={allowDecimals}
          tickFormatter={(value: any) => {
            return renderLeftAxis(`${value}`, type);
          }}
          style={{
            fontSize: 11,
          }}
          width={40}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          yAxisId="right"
          orientation="right"
          tick={{ fontSize: 12 }}
          tickFormatter={(value: any) => {
            return value.toFixed().toString() + "%";
          }}
          style={{
            fontSize: 12,
          }}
          //domain={renderMinMaxGrowth(data)}
          width={renderRightAxis()}
        />
        {children}
        <Tooltip wrapperStyle={{ fontSize: 12 }} content={CustomTooltip} />
        <Legend
          iconSize={12}
          wrapperStyle={{
            fontSize: 12,
            top: -45,
          }}
          formatter={renderColorLegendText}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export const ChartPie = ({ children }: any) => {
  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <ul>
        {payload.map((entry: any, index: number) => (
          <li key={`item-${index}`} className="flex gap-1 items-center">
            <div
              className={`w-4 h-4`}
              style={{ backgroundColor: entry.color }}
            ></div>
            {entry.value} : {entry.payload.value}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        {children}
        <Tooltip wrapperStyle={{ fontSize: 12 }} />
        <Legend
          iconSize={12}
          wrapperStyle={{ fontSize: 12 }}
          layout="vertical"
          align="right"
          verticalAlign="middle"
          content={renderLegend}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
