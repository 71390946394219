import { Checkbox, Pagination, PaginationItem } from "@mui/material";
import {
  DataGrid as DataGridMUI,
  DataGridProps,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridColDef,
} from "@mui/x-data-grid";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../../utils/breakpoint";
import { Select } from "../select";
import { ReactComponent as EmptyStateSVG } from "../../../assets/no-result.svg";
import { ReactComponent as NoGPSSVG } from "../../../assets/no-gps.svg";
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const rowPerPageOptionsList = useMemo(() => [10, 25, 50, 100], []);

  const [selectedPerson, setSelectedPerson] = useState<number>(
    rowPerPageOptionsList[0]
  );

  useEffect(() => {
    apiRef.current.setPageSize(rowPerPageOptionsList[0]);
  }, []);

  return (
    <>
      <div className=" w-full flex items-center flex-wrap md:flex-nowrap">
        <div className="flex flex-row gap-1 items-center mr-1 mb-2 md:mb-0">
          <div className="ml-2">
            <p>Menampilkan:</p>
          </div>
          <div className="min-w-[100px] ">
            <Select
              value={selectedPerson}
              onChange={(v) => {
                apiRef.current.setPageSize(v);
                setSelectedPerson(v);
              }}
            >
              <Select.Button>{selectedPerson}</Select.Button>
              <Select.ButtonArrow />
              <Select.Options>
                {rowPerPageOptionsList.map((person) => (
                  <Select.Option key={person} value={person}>
                    {person}
                  </Select.Option>
                ))}
              </Select.Options>
            </Select>
          </div>
        </div>
        <Pagination
          shape="rounded"
          sx={{
            ".MuiPagination-ul": {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
              ...(breakpoint === "mobile" && {
                justifyContent: "space-between",
              }),
            },
            ".MuiPaginationItem-root": {
              backgroundColor: "white",
            },
            ".MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#FDB3A3",
              color: "white",
            },
            ".MuiPaginationItem-root.Mui-selected:hover": {
              backgroundColor: "#FDB3A3",
              color: "white",
            },
            ".MuiPaginationItem-previousNext": {
              backgroundColor: "#ED1B2E",
              color: "white",
            },
            ".MuiPaginationItem-root.MuiPaginationItem-previousNext.Mui-disabled":
              {
                backgroundColor: "#E5E7EB",
                color: "black",
              },
            ".MuiPaginationItem-root.MuiPaginationItem-previousNext:hover": {
              backgroundColor: "#ED1B2E",
            },
          }}
          renderItem={(item) => <PaginationItem {...item} />}
          count={pageCount}
          siblingCount={0}
          boundaryCount={1}
          page={page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
      </div>
    </>
  );
}

const CheckboxWrapper = forwardRef((props, ref: any) => (
  <Checkbox
    {...props}
    sx={{
      color: "#D2D5DA",
      "&.Mui-checked": {
        color: "#9CA3AF",
      },
    }}
    ref={ref}
  />
));

export const EmptyState: React.FC<{ width?: number; height?: number, wording?: string }> = ({
  width = 250,
  height = 250,
  wording="Data tidak tersedia"
}) => {
  return (
    <div className="w-full h-full flex justify-center items-center flex-col">
      <div>
        <EmptyStateSVG width={width} height={height} />
      </div>
      <div>
        <p className="font-lato text-gray-400 text-2xl">{wording}</p>
      </div>
    </div>
  );
};

export const PreData: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="w-full  absolute   flex justify-center items-center flex-col">
      <div>
        <NoGPSSVG width={250} height={250} />
      </div>
      <div>
        <p className="font-lato text-gray-400 text-center md:text-lg lg:text-2xl">
          {children}
        </p>
      </div>
    </div>
  );
};
export const PreText: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <tbody>
      <tr>
        <td>
          <div className="w-full  absolute   flex justify-center items-center flex-col">
            <div>
              <NoGPSSVG width={250} height={250} />
            </div>
            <div>
              <p className="font-lato text-gray-400 text-2xl">{children}</p>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export const DataGrid = React.forwardRef<HTMLDivElement, DataGridProps>(
  (props, ref) => (
    <DataGridMUI
      components={{
        Pagination: CustomPagination,
        BaseCheckbox: CheckboxWrapper,
        NoRowsOverlay: EmptyState,
        NoResultsOverlay: EmptyState,
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
        ".MuiDataGrid-columnHeader": {
          outline: "none",
        },
        ".MuiDataGrid-columnHeaders": {
          backgroundColor: "#ED1B2E",
          color: "white",
          border: "none",
        },
        ".MuiDataGrid-cell": {
          backgroundColor: "white",
          border: "none",
        },
        ".MuiDataGrid-row": {
          border: "none",
          marginTop: 1,
        },
        ".MuiDataGrid-footerContainer": {
          border: "none",
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
        },
        ".MuiDataGrid-checkboxInput": {
          color: "#D2D5DA",
        },
        ".MuiPagination-root.MuiPagination-text": {
          width: "100%",
        },
        ".MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon": {
          color: "white",
        },
      }}
      hideFooterSelectedRowCount
      disableColumnMenu
      {...props}
      ref={ref}
    />
  )
);
interface PreTextTableInterface {
  columns: GridColDef[];
  PreText: React.FunctionComponent;
}
export const PreTextTable: React.FC<PreTextTableInterface> = (props) => {
  const { columns, PreText } = props;
  return (
    <DataGridMUI
      components={{
        NoRowsOverlay: PreText,
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
        ".MuiDataGrid-columnHeader": {
          outline: "none",
        },
        ".MuiDataGrid-columnHeaders": {
          backgroundColor: "#ED1B2E",
          color: "white",
          border: "none",
        },
        ".MuiDataGrid-cell": {
          backgroundColor: "white",
          border: "none",
        },
        ".MuiDataGrid-row": {
          border: "none",
          marginTop: 1,
        },
        ".MuiDataGrid-footerContainer": {
          border: "none",
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
        },
        ".MuiDataGrid-checkboxInput": {
          color: "#D2D5DA",
        },
        ".MuiPagination-root.MuiPagination-text": {
          width: "100%",
        },
        ".MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon": {
          color: "white",
        },
      }}
      hideFooterSelectedRowCount
      disableColumnMenu
      rows={[]}
      hideFooter
      columns={columns}
    />
  );
};
