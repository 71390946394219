import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export type EmergencyContactResponse = EmergencyContactData[];

export interface EmergencyContactData {
  agentNumber: string;
  contactName: string;
  relation: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  address3: string;
}

export const emergencyContactApi = createApi({
  reducerPath: "emergencyContactApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      headers.set("Authorization", "");
      if (token) {
        headers.set("Authorization", "Bearer " + token);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEmergencyContact: builder.query<EmergencyContactResponse, any>({
      query: ({ agentNumber }) => {
        return {
          url: "/web/emergency/EmergencyContact/showEmergencyContact",
          method: "GET",
          params: {
            agentNum: agentNumber,
          },
        };
      },
    }),
  }),
});

export const { useGetEmergencyContactQuery, useLazyGetEmergencyContactQuery } =
  emergencyContactApi;
