import { Line } from "recharts";
import { ChartBar } from "../../../Components/atom/chart";
import { memo } from "react";
import { LoaderChart } from "../../../Components/atom/loader-chart";
import { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import moment from "moment";
import { Link } from "react-router-dom";
import { AccessMVP2 } from "../../../utils/whitelist";
type TData = {
  period: string;
  totalprsctyapicurrent: number;
  totalprsctyapirolling: number;
};
type TPerssistencyChart = {
  data: TData[];
  loading: boolean;
  date: string;
};
export const PerssistencyChart = memo((props: TPerssistencyChart) => {
  const { data, loading, date } = props;

  const perssistency = useConfigPerssistencyHooks(data);
  const [tooltip, setTooltip] = useState(false);
  const accessMVP2 = AccessMVP2();
  const handleTooltipClose = () => {
    setTooltip(false);
  };

  const handleTooltipOpen = () => {
    setTooltip(true);
  };
  if (loading)
    return (
      <div className="w-full h-full">
        <LoaderChart title="Persistensi" />
      </div>
    );

  var idLocale = require("moment/locale/id");
  moment.updateLocale("id", idLocale);
  var retrieveDate = new Date(date);
  var momentDate = moment(retrieveDate).format("LLLL");

  return (
    <div className=" h-full w-full  bg-white rounded-lg p-4 pb-8 printable">
      <div className="flex gap-2 items-center ">
        <p className="font-lato text-[18px] font-bold ">
          {accessMVP2 ? (
            <Tooltip
              title="Klik judul untuk melihat detail"
              placement="top"
              arrow
            >
              <Link className="cursor-pointer" to="/persistency">
                Persistensi
              </Link>
            </Tooltip>
          ) : (
            <Tooltip title="Segera Hadir" placement="top" arrow>
              <Link className="cursor-pointer" to="#">
                Persistensi
              </Link>
            </Tooltip>
          )}
        </p>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            title={
              accessMVP2 ? "Klik judul untuk melihat detail" : "Segera Hadir"
            }
            placement="top"
            arrow
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={tooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <InformationCircleIcon
              onClick={handleTooltipOpen}
              className="w-[18px] h-[20.02px] text-[#4B5563] lg:hidden visible"
            />
          </Tooltip>
        </ClickAwayListener>
      </div>
      <p className="pb-6 mb-6 text-[11px]">Data diperbarui {momentDate} WIB</p>
      <ChartBar
        data={perssistency}
        type="percentage"
        CustomTooltip={<CustomTooltip />}
      >
        <Line
          type="monotone"
          dataKey="rolling"
          stroke="#fb5b50"
          strokeWidth={4}
          dot={true}
          yAxisId="left"
        />
        <Line
          type="monotone"
          dataKey="lama"
          stroke="#DEAA58"
          strokeWidth={4}
          dot={true}
          yAxisId="left"
        />
      </ChartBar>
    </div>
  );
});
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const getConfigPersistency = (props: any) => {
  if (props) {
    return props.map((d: any) => {
      let split = d.period.split("-");
      let month = moment(`${split[1]}-${split[0]}-01`).month();

      return {
        name: months[month],
        rolling: d.totalprsctyapirolling > 0 ? d.totalprsctyapirolling : 0,
        lama: d.totalprsctyapicurrent > 0 ? d.totalprsctyapicurrent : 0,
      };
    });
  }
};
const useConfigPerssistencyHooks = (props: any) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props) {
      let new_data = getConfigPersistency(props);
      setData(new_data);
    }
  }, [props]);
  return data;
};

const CustomTooltip = ({ active, payload, label, type }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip shadow-lg"
        style={{ backgroundColor: "white", padding: 10 }}
      >
        <p className="label text-lg">{`${label} `}</p>
        <p className="intro">
          Persistensi <i>rolling</i> : {payload[0].payload.rolling}%
        </p>
        <p className="intro">Persistensi lama : {payload[0].payload.lama}%</p>
      </div>
    );
  }

  return null;
};
