import { useState, useEffect } from "react";
import { ProductionChart } from "./ProductionChart";
import { PerssistencyChart } from "./PerssistencyChart";
import { ActiveAgentChart } from "./ActiveAgentChart";
import { ActiveRatioChart } from "./ActiveRatioChart";
import { ManPowerChart } from "./ManPowerChart";
import { ErrorChart, ErrorPieChart } from "./ErrorChart";
import {
  useGetProductionSummaryQuery,
  useGetPerssistencySummaryQuery,
  useGetPerssistencySummaryKpmQuery,
  dashboardApi,
} from "../../services/dashboard";
import { useGetPersistencyGraphCRQuery } from "../../services/persistency";
import {
  mapaApi,
  useGetActiveAgentQuery,
  useGetActiveRatioQuery,
  useGetManPowerQuery,
} from "../../services/mapa";
import { ReactComponent as Download } from "../../assets/download.svg";
import { CURRENT_YEAR, PREVIOUS_YEAR } from "../../utils/year";
import { Login } from "../../services/login";

import { useSessionStorage } from "usehooks-ts";
import filter from "../../Components/atom/SelectFilter/filter.json";
import { Button } from "../../Components/atom/button";
import { printToPDF } from "../../utils/printToPDF";
import { LoadingCircle } from "../../Components/Loading/loadingCircle";
import { useDispatch } from "react-redux";
import SelectFilter from "../../Components/atom/SelectFilter";
import moment from "moment";
import * as analyticConst from "../../resource/analytic_const";
import { logEvents } from "../../utils/analytics";
type TUsedFilter = {
  id: string;
  name: string;
  label: string;
  officeCd?: string;
};
type TKpmList = {
  officeName: string;
  officeCd: string;
};

function DashboardPage() {
  const dispatch = useDispatch();
  const [user] = useSessionStorage<Login | undefined>("user", undefined);
  const [filterdashboard, setFilterDashboard] = useSessionStorage<
    TUsedFilter | undefined
  >("filterdashboard", undefined);

  const [usedFilter, setUsedFilter] = useState<TUsedFilter>(
    filterdashboard ? filterdashboard : filter[0]
  );
  useEffect(() => {
    logEvents("Dashboard", {
      content_id: analyticConst.Dashboard_Page,
      agent_code: user?.agentData.agentNumber,
    });
  }, []);
  useEffect(() => {
    setFilterDashboard(usedFilter);
  }, [usedFilter]);

  const isAgent =
    user?.agentData.userType === "agent" && user?.agentData.agentType === "AG";
  const isAgentLeader =
    user?.agentData.userType === "agent" && user?.agentData.agentType === "AD";

  const isAA =
    user?.agentData.userType === "agent" && user?.agentData.agentType === "AA";

  const isSecretary = user?.agentData.userType === "secretary";
  const isCentralAdmin = user?.agentData.userType === "central admin";

  const isHaveKpm = user?.oldAuth.agentData.kpmList.length;

  const WIDGET_PERSISTENCY = user?.mobileMenu?.find(
    (d: any) => d.name === "WIDGET_PERSISTENCY"
  );

  const WIDGET_PRODUCTION = user?.mobileMenu?.find(
    (d: any) => d.name === "WIDGET_PRODUCTION"
  );

  // const WIDGET_MAPA_DASHBOARD = user?.mobileMenu?.find(
  //   (d: any) => d.name === "WIDGET_MAPA_DASHBOARD"
  // );
  let list_kpm_filter: TUsedFilter[] = user
    ? user.oldAuth.agentData.kpmList.map((d: TKpmList) => ({
        id: "K",
        name: "kpm",
        label: `${d.officeCd} - ${d.officeName}`,
        officeCd: d.officeCd,
      }))
    : [];
  const listsFilter = () => {
    const filter_list = filter.filter((d) => d.name !== "adonad");
    if (isCentralAdmin) {
      return filter_list;
    } else if (isSecretary) {
      if (WIDGET_PERSISTENCY || WIDGET_PRODUCTION)
        return filter_list.filter((d: any) => d.name !== "kpm");
      else return [];
    } else {
      if (isAgentLeader) {
        if (isHaveKpm) {
          return filter_list;
        } else {
          return filter_list.filter((d: any) => d.name !== "kpm");
        }
      } else if (isAA) {
        return filter_list.filter(
          (d: any) => d.name !== "kpm" && d.name !== "group"
        );
      } else {
        return [];
      }
    }
  };
  const {
    data: production,
    isFetching: isLoadingProduction,
    isError: isErrorProduction,
  } = useGetProductionSummaryQuery(
    {
      agent_cd: user ? user.agentData.agentNumber : "",
      office_cd: usedFilter.name !== "kpm" ? "" : usedFilter.officeCd,
      tab_type: usedFilter.id,
      snap: {
        snap_year: CURRENT_YEAR,
        snap_month: new Date().getMonth() + 1,
      },
    },
    {
      skip: !user,
    }
  );
  //buat get last update chart persistency
  const { data: graphData } = useGetPersistencyGraphCRQuery(
    {
      agentCode: user ? user.agentData.agentNumber : "",
    },
    {
      skip: !user,
    }
  );
  const {
    data: perssistency,
    isFetching: isLoadingPerssistency,
    isError: isErrorPerssistency,
  } = useGetPerssistencySummaryQuery(
    {
      agent_cd: user ? user.agentData.agentNumber : "",
      date_start: moment().format("YYYY-MM-DD"),
      interval_number: "12",
    },
    {
      skip: !user || usedFilter.name === "kpm",
    }
  );

  const {
    data: perssistency_kpm,
    isFetching: isLoadingPerssistencyKpm,
    // isError: isErrorPerssistencyKpm,
  } = useGetPerssistencySummaryKpmQuery(
    {
      officeCode: usedFilter.officeCd || "",
      date_start: moment().format("YYYY-MM-DD"),
      interval_number: "12",
      agent_cd: user ? user.agentData.agentNumber : "",
    },
    { skip: usedFilter.name !== "kpm" || !user }
  );

  const currentYearMapaParams = {
    type:
      usedFilter.name === "unit" ||
      usedFilter.name === "group" ||
      usedFilter.name === "kpm"
        ? usedFilter.name
        : "individu",
    period: CURRENT_YEAR,
    agentNumber: user ? user.agentData.agentNumber : "",
    periodType: "month",
  };
  const lastYearMapaParams = {
    type:
      usedFilter.name === "unit" ||
      usedFilter.name === "group" ||
      usedFilter.name === "kpm"
        ? usedFilter.name
        : "individu",
    period: PREVIOUS_YEAR,
    agentNumber: user ? user.agentData.agentNumber : "",
    periodType: "month",
  };
  const {
    data: manPower,
    isFetching: isLoadingManPower,
    isError: isErrorManPower,
  } = useGetManPowerQuery(currentYearMapaParams, {
    skip:
      currentYearMapaParams === undefined ||
      currentYearMapaParams.type === "individu" ||
      !user ||
      usedFilter.name === "kpm",
  });
  const {
    data: activeAgentCy,
    isFetching: isLoadingActiveAgentCy,
    isError: isErrorActiveAgentCy,
  } = useGetActiveAgentQuery(currentYearMapaParams, {
    skip:
      currentYearMapaParams === undefined ||
      currentYearMapaParams.type === "individu" ||
      !user ||
      usedFilter.name === "kpm",
  });
  const {
    data: activeAgentLy,
    isFetching: isLoadingActiveAgentLy,
    isError: isErrorActiveAgentLy,
  } = useGetActiveAgentQuery(lastYearMapaParams, {
    skip:
      currentYearMapaParams === undefined ||
      currentYearMapaParams.type === "individu" ||
      !user,
  });

  const {
    data: activeRatioCy,
    isFetching: isLoadingActiveRatioCy,
    isError: isErrorActiveRatioCy,
  } = useGetActiveRatioQuery(currentYearMapaParams, {
    skip:
      currentYearMapaParams === undefined ||
      currentYearMapaParams.type === "individu" ||
      !user ||
      usedFilter.name === "kpm",
  });

  const {
    data: activeRatioLy,
    isFetching: isLoadingActiveRatioLy,
    isError: isErrorActiveRatioLy,
  } = useGetActiveRatioQuery(lastYearMapaParams, {
    skip:
      lastYearMapaParams === undefined ||
      lastYearMapaParams.type === "individu" ||
      !user ||
      usedFilter.name === "kpm",
  });

  const payloadChange = (e: any) => {
    setUsedFilter(e);
    logEvents("select_content", {
      filter_dashboard: e.label,
      content_id: analyticConst.ACTION_FILTER_DASHBOARD,
    });
  };

  const [onPrint, setOnPrint] = useState(false);

  const onDownload = () => {
    setOnPrint(true);
    printToPDF({
      filterType: usedFilter,
      production,
      persistency:
        usedFilter.name !== "kpm"
          ? perssistency?.All[usedFilter.label]
          : perssistency_kpm?.data,
      activeAgent: [activeAgentCy, activeAgentLy],
      activityRatio: [activeRatioCy, activeRatioLy],
    }).then(() => {
      setOnPrint(false);
    });
    logEvents("select_content", {
      download_dashboard: usedFilter.label,
      content_id: analyticConst.ACTION_DOWNLOAD_DASHBOARD,
    });
  };
  const isKPMHavePilotPersistency = () => {
    const officeCd = user?.oldAuth.agentData.kpmList[0]?.officeCd;
    const persistency_pilot = user?.oldAuth.agentData.kpmPilotList.find(
      (d) => d.widgetRoleId === "WIDGET_PERSISTENCY_DASHBOARD"
    );
    if (usedFilter.name === "kpm") {
      if (persistency_pilot === undefined) return true;
      if (
        officeCd !== undefined &&
        persistency_pilot.kpmList.includes(officeCd)
      ) {
        return true;
      }
      return false;
    }

    return true;
  };

  const refreshPersistency = () => {
    if (usedFilter.name === "kpm") {
      dispatch(dashboardApi.util.invalidateTags(["KPM"]));
    } else {
      dispatch(dashboardApi.util.invalidateTags(["PERSISTENCY"]));
    }
  };

  const isKPMHavePilotProduction = () => {
    const officeCd = user?.oldAuth.agentData.kpmList[0]?.officeCd;
    const targetedKPMPilot = user?.oldAuth.agentData.kpmPilotList.find(
      (listItem) => listItem.widgetRoleId === "WIDGET_PRODUCTION_DASHBOARD"
    );

    if (usedFilter.name === "kpm") {
      if (targetedKPMPilot === undefined) return true;
      if (
        officeCd !== undefined &&
        targetedKPMPilot.kpmList.includes(officeCd)
      ) {
        return true;
      }
      return false;
    }

    return true;
  };

  const refreshProduksi = () =>
    dispatch(dashboardApi.util.invalidateTags(["PRODUKSI"]));

  const refreshManPower = () =>
    dispatch(mapaApi.util.invalidateTags(["MANPOWER"]));

  const refreshActiveRatio = () =>
    dispatch(mapaApi.util.invalidateTags(["ACTIVITY_RATIO"]));

  const refreshActiveAgent = () =>
    dispatch(mapaApi.util.invalidateTags(["ACTIVE_AGENT"]));

  return (
    <div>
      <div className="flex justify-between items-center mb-10 flex-col md:flex-row gap-2  ">
        <h1 className="font-lato text-[24px] font-extrabold text-[#1F2937]">
          Dashboard
        </h1>

        <div className="flex gap-2 lg:flex-row flex-col lg:w-auto w-full">
          {!isAgent && (
            <div
              className={`${
                isHaveKpm ? "lg:w-[300px]" : "lg:w-[200px]"
              } w-full z-10`}
            >
              {/* <Select value={usedFilter.name} onChange={payloadChange}>
                <Select.Button>{usedFilter.label}</Select.Button>
                <Select.ButtonArrow />
                <Select.Options>
                  <p className="font-bold ml-3 py-3">Tenaga Pemasar</p>
                  {listsFilter().map((filter) => (
                    <Select.Option
                      key={filter.label}
                      disabled={false}
                      value={filter}
                    >
                      {filter.label}
                    </Select.Option>
                  ))}
                  <p className="font-bold ml-3 py-3">KPM Manager</p>
                  {list_kpm_filter.map((filter) => (
                    <Select.Option
                      key={filter.label}
                      disabled={false}
                      value={filter}
                    >
                      {filter.label}
                    </Select.Option>
                  ))}
                  {isHaveKpm ? (
                    <>
                      <p className="font-bold ml-3 py-3">KPM Manager</p>
                      <Select.Option
                        key={list_kpm.id}
                        disabled={false}
                        value={list_kpm}
                      >
                        {list_kpm.label}
                      </Select.Option>
                    </>
                  ) : null}
                </Select.Options>
              </Select> */}
              <SelectFilter
                value={usedFilter}
                onChange={payloadChange}
                list_tenaga_pemasar={listsFilter()}
                list_kpm_manager={list_kpm_filter}
              />
            </div>
          )}

          <Button
            className="h-[42px] flex flex-row items-center pl-4 pr-4 gap-2 justify-center"
            disabled={onPrint}
            onClick={onDownload}
          >
            {onPrint ? <LoadingCircle classname="fill-white" /> : <Download />}

            <p className="text-[16px] font-medium font-lato h-full text-white items-center flex">
              Download
            </p>
          </Button>
        </div>
      </div>

      {isAgent && (
        <div className="flex flex-wrap justify-between flex-col lg:flex-row gap-8 mb-8">
          <div className="lg:w-[48%] w-full md:h-[330px] h-[280px]">
            {isErrorProduction ? (
              <ErrorChart
                loading={isLoadingProduction}
                title="Production"
                isError={true}
                onRefresh={refreshProduksi}
              />
            ) : (
              <ProductionChart
                data={production}
                loading={isLoadingProduction}
              />
            )}
          </div>
          <div className="lg:w-[48%] w-full md:h-[330px] h-[280px]">
            {isErrorPerssistency ? (
              <ErrorChart
                loading={isLoadingPerssistency}
                title="Persistensi"
                isError={true}
                onRefresh={refreshPersistency}
              />
            ) : (
              <PerssistencyChart
                data={
                  usedFilter.name !== "kpm"
                    ? perssistency?.All[usedFilter.label]
                    : perssistency_kpm?.data
                }
                loading={
                  usedFilter.name !== "kpm"
                    ? isLoadingPerssistency
                    : isLoadingPerssistencyKpm
                }
                date={
                  usedFilter.name !== "kpm"
                    ? graphData?.date
                    : perssistency_kpm?.date
                }
              />
            )}
          </div>
        </div>
      )}
      {(isAgentLeader || isAA) && (
        <div className="flex flex-wrap justify-between flex-col lg:flex-row gap-8 mb-8">
          {isAgentLeader && isKPMHavePilotProduction() && (
            <div className="lg:w-[48%] w-full md:h-[330px] h-[350px]">
              {isErrorProduction ? (
                <ErrorChart
                  loading={isLoadingProduction}
                  title="Produksi"
                  isError={true}
                  onRefresh={refreshProduksi}
                />
              ) : (
                <ProductionChart
                  data={production}
                  loading={isLoadingProduction}
                />
              )}
            </div>
          )}
          {isAgentLeader && isKPMHavePilotPersistency() && (
            <div className="lg:w-[48%] w-full md:h-[330px] h-[350px]">
              {isErrorPerssistency ? (
                <ErrorChart
                  loading={isLoadingPerssistency}
                  title="Persistensi"
                  isError={true}
                  onRefresh={refreshPersistency}
                />
              ) : (
                <PerssistencyChart
                  data={
                    usedFilter.name !== "kpm"
                      ? perssistency?.All[usedFilter.label]
                      : perssistency_kpm?.data
                  }
                  loading={
                    usedFilter.name !== "kpm"
                      ? isLoadingPerssistency
                      : isLoadingPerssistencyKpm
                  }
                  date={
                    usedFilter.name !== "kpm"
                      ? graphData?.date
                      : perssistency_kpm?.date
                  }
                />
              )}
            </div>
          )}
          {isAA && usedFilter.id !== "G" && usedFilter.id !== "K" && (
            <div className="lg:w-[48%] w-full md:h-[330px] h-[350px]">
              {isErrorProduction ? (
                <ErrorChart
                  loading={isLoadingProduction}
                  title="Production"
                  isError={true}
                  onRefresh={refreshProduksi}
                />
              ) : (
                <ProductionChart
                  data={production}
                  loading={isLoadingProduction}
                />
              )}
            </div>
          )}
          {isAA && usedFilter.id !== "G" && usedFilter.id !== "K" && (
            <div className="lg:w-[48%] w-full md:h-[330px] h-[350px]">
              {isErrorPerssistency ? (
                <ErrorChart
                  loading={isLoadingPerssistency}
                  title="Persistensi"
                  isError={true}
                  onRefresh={refreshPersistency}
                />
              ) : (
                <PerssistencyChart
                  data={
                    usedFilter.name !== "kpm"
                      ? perssistency?.All[usedFilter.label]
                      : perssistency_kpm?.data
                  }
                  loading={
                    usedFilter.name !== "kpm"
                      ? isLoadingPerssistency
                      : isLoadingPerssistencyKpm
                  }
                  date={
                    usedFilter.name !== "kpm"
                      ? graphData?.date
                      : perssistency_kpm?.date
                  }
                />
              )}
            </div>
          )}
          {usedFilter.id !== "I" && usedFilter.id !== "K" && (
            <div className="lg:w-[48%] w-full md:h-[360px] h-[350px]">
              {manPower?.data[0] === undefined || isErrorManPower ? (
                <ErrorPieChart
                  loading={isLoadingManPower}
                  isError={isErrorManPower}
                  onRefresh={refreshManPower}
                />
              ) : (
                <ManPowerChart
                  data={manPower?.data}
                  loading={isLoadingManPower}
                />
              )}
            </div>
          )}
          {usedFilter.id !== "I" && usedFilter.id !== "K" && (
            <div className="lg:w-[48%] w-full md:h-[360px] h-[350px]">
              {isErrorActiveRatioCy ||
              isErrorActiveRatioLy ||
              (activeRatioCy?.data.length === 0 &&
                activeRatioLy?.data.length === 0) ? (
                <ErrorChart
                  loading={isLoadingActiveRatioCy}
                  title="Activity Ratio"
                  isError={true}
                  onRefresh={refreshActiveRatio}
                />
              ) : (
                <ActiveRatioChart
                  data={[activeRatioCy, activeRatioLy]}
                  loading={[isLoadingActiveRatioCy, isLoadingActiveRatioLy]}
                />
              )}
            </div>
          )}
          {usedFilter.id !== "I" && usedFilter.id !== "K" && (
            <div className="lg:w-[48%] w-full md:h-[360px] h-[350px]">
              {isErrorActiveAgentCy ||
              isErrorActiveAgentLy ||
              (activeAgentCy?.data.length === 0 &&
                activeAgentLy?.data.length === 0) ? (
                <ErrorChart
                  loading={isLoadingActiveAgentCy}
                  title="Active Agent"
                  isError={true}
                  onRefresh={refreshActiveAgent}
                />
              ) : (
                <ActiveAgentChart
                  data={[activeAgentCy, activeAgentLy]}
                  loading={[isLoadingActiveAgentCy, isLoadingActiveAgentLy]}
                />
              )}
            </div>
          )}
        </div>
      )}
      {isSecretary && (
        <div className="flex flex-wrap justify-between flex-col lg:flex-row gap-8 mb-8">
          {WIDGET_PRODUCTION && (
            <div className="lg:w-[48%] w-full md:h-[330px] h-[350px]">
              {isErrorProduction ? (
                <ErrorChart
                  loading={isLoadingProduction}
                  title="Produksi"
                  isError={true}
                  onRefresh={refreshProduksi}
                />
              ) : (
                <ProductionChart
                  data={production}
                  loading={isLoadingProduction}
                />
              )}
            </div>
          )}
          {WIDGET_PERSISTENCY && (
            <div className="lg:w-[48%] w-full md:h-[330px] h-[350px]">
              {isErrorPerssistency ? (
                <ErrorChart
                  loading={isLoadingPerssistency}
                  title="Persistensi"
                  isError={true}
                  onRefresh={refreshPersistency}
                />
              ) : (
                <PerssistencyChart
                  data={
                    usedFilter.name !== "kpm"
                      ? perssistency?.All[usedFilter.label]
                      : perssistency_kpm?.data
                  }
                  loading={
                    usedFilter.name !== "kpm"
                      ? isLoadingPerssistency
                      : isLoadingPerssistencyKpm
                  }
                  date={
                    usedFilter.name !== "kpm"
                      ? graphData?.date
                      : perssistency_kpm?.date
                  }
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardPage;
