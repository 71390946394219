import { configureStore } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { loginApi } from "./services/login";
import { hospitalApi } from "./services/hospital";
import { dashboardApi } from "./services/dashboard";
import { mapaApi } from "./services/mapa";
import { myteamApi } from "./services/myteam";
import { myteamJbossApi } from "./services/myteam-jboss";
import { fatcaApi } from "./services/fatca";
import kantorKeagenanSlice from "./pages/Login/KantorKeagenan/slice";
import rsRekananSlice from "./pages/Login/RsRekanan/slice";
import rincianMapaSlice from "./pages/Dashboard/RincianMAPA/slice";
import productionSlice from "./pages/Produksi/slice";
import rincianProduksiSlice from "./pages/ProduksiRincian/slice";
import fatcaSlice from "./pages/Profile/DataKeagenan/slice";
import tasklistSlice from "./pages/TaskList/slice";
import { CPDApi } from "./services/CPD/CPD";
import { newsDetailsApi } from "./services/newsDetails/newsDetails";
import { dictionaryApi } from "./services/dictionary";
import { newsApi } from "./services/news";
import { emergencyContactApi } from "./services/emergencyContact";
import { newEmergencyContactApi } from "./services/newEmergencyContact";
import { persistencyApi } from "./services/persistency";
import { proposalApi } from "./services/proposal";
import { rincianPembayaranPremiApi } from "./services/rincianPembayaranPremi";
import { produksiApi } from "./services/produksi";
import { polisApi } from "./services/polis";
import { ManfaatAsuransiApi } from "./services/manfaatAsuransi/manfaatAsuransi";
import { documentDownloadApi } from "./services/documentDonwload";
import { lastDataUpdateApi } from "./services/lastDataUpdate";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PersistConfig,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { kantorKeagenanApi } from "./services/kantorKeagenan";
import transformExpire from "./utils/persistExpired";
import { secretaryApi } from "./services/secretary";
import { notificationsApi } from "./services/notifications";
import { claimHistoryApi } from "./services/claimHistory";
import documentDownloadSlice from "./pages/DocumentDownload/slice";
import { tasksApi } from "./services/tasks/tasks";
import individuProposalSlice from "./pages/Proposal/Individu/slice";
import unitProposalSlice from "./pages/Proposal/Unit/slice";
import groupProposalSlice from "./pages/Proposal/Group/slice";
import individuPolisSlice from "./pages/Polis/Individu/slice";
import unitPolisSlice from "./pages/Polis/Unit/slice";
import groupPolisSlice from "./pages/Polis/Group/slice";
import appSlice from "./app.slice";
import { login2Api } from "./services/login2";
import campaignApi from "./services/campaign";

import { isRejectedWithValue } from "@reduxjs/toolkit";
import { setUnAuthorizeModal } from "./app.slice";
// const dispatch = useDispatch();
const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

const persistKantorKeagenanConfig: PersistConfig<any> = {
  key: "kantorKeagenan",
  storage,
  transforms: [transformExpire(ONE_DAY * 7, "kantorKeagenanExpiredTime")],
};

const persistEmergencyContactConfig: PersistConfig<any> = {
  key: "emergencyContact",
  storage,
  transforms: [transformExpire(ONE_DAY * 7, "emergencyContactExpiredTime")],
};

const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (
        action.payload?.shortCode === "NOT_AUTHORIZED" ||
        action.payload.status === 401 ||
        action.payload.originalStatus === 403 ||
        action.payload.data === "HTTP 401 Unauthorized"
      ) {
        store.dispatch(setUnAuthorizeModal(true));
        // sessionStorage.removeItem("oldtoken");
        // sessionStorage.removeItem("token");
        // sessionStorage.removeItem("user");
        // window.location.href = "/";
        // alert("hell");
      }
    }

    return next(action);
  };

export const store = configureStore({
  reducer: {
    kantorKeagenan: kantorKeagenanSlice,
    rsRekanan: rsRekananSlice,
    rincianMapa: rincianMapaSlice,
    documentDownload: documentDownloadSlice,
    production: productionSlice,
    tasklist: tasklistSlice,
    fatcaStatus: fatcaSlice,
    rincianProduksi: rincianProduksiSlice,
    individuProposal: individuProposalSlice,
    unitProposal: unitProposalSlice,
    groupProposal: groupProposalSlice,
    individuPolis: individuPolisSlice,
    unitPolis: unitPolisSlice,
    groupPolis: groupPolisSlice,
    appSlice: appSlice,

    [loginApi.reducerPath]: loginApi.reducer,
    [hospitalApi.reducerPath]: hospitalApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [mapaApi.reducerPath]: mapaApi.reducer,
    [myteamApi.reducerPath]: myteamApi.reducer,
    [myteamJbossApi.reducerPath]: myteamJbossApi.reducer,
    [fatcaApi.reducerPath]: fatcaApi.reducer,
    [persistencyApi.reducerPath]: persistencyApi.reducer,
    [CPDApi.reducerPath]: CPDApi.reducer,
    [newsDetailsApi.reducerPath]: newsDetailsApi.reducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [kantorKeagenanApi.reducerPath]: persistReducer(
      persistKantorKeagenanConfig,
      kantorKeagenanApi.reducer
    ),
    [emergencyContactApi.reducerPath]: persistReducer(
      persistEmergencyContactConfig,
      emergencyContactApi.reducer
    ),
    [newsApi.reducerPath]: newsApi.reducer,
    [newEmergencyContactApi.reducerPath]: newEmergencyContactApi.reducer,
    [secretaryApi.reducerPath]: secretaryApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [produksiApi.reducerPath]: produksiApi.reducer,

    [proposalApi.reducerPath]: proposalApi.reducer,
    [polisApi.reducerPath]: polisApi.reducer,
    [rincianPembayaranPremiApi.reducerPath]: rincianPembayaranPremiApi.reducer,
    [claimHistoryApi.reducerPath]: claimHistoryApi.reducer,
    [documentDownloadApi.reducerPath]: documentDownloadApi.reducer,
    [ManfaatAsuransiApi.reducerPath]: ManfaatAsuransiApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
    [lastDataUpdateApi.reducerPath]: lastDataUpdateApi.reducer,
    [login2Api.reducerPath]: login2Api.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(loginApi.middleware)
      .concat(hospitalApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(mapaApi.middleware)
      .concat(myteamApi.middleware)
      .concat(myteamJbossApi.middleware)
      .concat(fatcaApi.middleware)
      .concat(CPDApi.middleware)
      .concat(newsDetailsApi.middleware)
      .concat(dictionaryApi.middleware)
      .concat(newsApi.middleware)
      .concat(secretaryApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(newEmergencyContactApi.middleware)
      .concat(persistencyApi.middleware)
      .concat(produksiApi.middleware)
      .concat(proposalApi.middleware)
      .concat(polisApi.middleware)
      .concat(rincianPembayaranPremiApi.middleware)
      .concat(claimHistoryApi.middleware)
      .concat(documentDownloadApi.middleware)
      .concat(ManfaatAsuransiApi.middleware)
      .concat(tasksApi.middleware)
      .concat(lastDataUpdateApi.middleware)
      .concat(login2Api.middleware)
      .concat(campaignApi.middleware)
      .concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
