import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IUnitPayload } from "../../pages/TaskList/slice";

import {
  ICPD,
  IRekrutmen,
  IUnit,
  IUnits,
  UnitFilterType,
  SesiTraining,
} from "./types";

export const tasksApi = createApi({
  reducerPath: "tasksApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }

      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRekrutmen: builder.query<
      IRekrutmen,
      {
        agentCode: string | number;
        salesforceId: string | number;
        size: number;
        offset: number;
      }
    >({
      query: (payload) => ({
        url: "/web/task/toDoRecruitmentCount",
        method: "POST",
        body: {
          body: payload,
          access_token:
            "Bearer " + JSON.parse(sessionStorage.getItem("token") || ""),
        },
      }),
    }),
    getListPerundingan: builder.query<
      [
        {
          total: number;
        }
      ],
      {
        agentNumber: string;
        salesforceId: string;
        agentCode: string;
        pageType?: string;
      }
    >({
      query: (payload) => ({
        url: "/web/task/findTotalNewBusinessPending",
        method: "POST",
        body: payload,
      }),
    }),
    getProsesBilling: builder.query<
      [
        {
          total: number;
        }
      ],
      {
        agentNumber: string;
        salesforceId: string;
        agentCode: string;
        pageType?: string;
      }
    >({
      query: (payload) => ({
        url: "/web/task/findTotalBillingPendingRequirement",
        method: "POST",
        body: payload,
      }),
    }),
    getProsesPerubahanPolis: builder.query<
      [
        {
          total: number;
        }
      ],
      {
        agentNumber: string;
        salesforceId: string;
        agentCode: string;
        pageType?: string;
      }
    >({
      query: (payload) => ({
        url: "/web/task/findTotalPolicyHolderPendingRequirement",
        method: "POST",
        body: payload,
      }),
    }),
    getPendingProsesKlaim: builder.query<
      [
        {
          total: number;
        }
      ],
      {
        agentNumber: string;
        salesforceId: string;
        agentCode: string;
        pageType?: string;
      }
    >({
      query: (payload) => ({
        url: "/web/task/findTotalClaimPendingRequirement",
        method: "POST",
        body: payload,
      }),
    }),
    getPolisLewatJatuhTempo: builder.query<
      [
        {
          total: number;
        }
      ],
      {
        agentNumber: string;
        salesforceId: string;
        agentCode: string;
        pageType?: string;
      }
    >({
      query: (payload) => ({
        url: "/web/task/findTotalAfterDueDate",
        method: "POST",
        body: payload,
      }),
    }),
    getUlangTahun: builder.query<
      {
        totalclient: number;
      },
      {
        agentNumber: string;
        salesforceId: string;
        agentCode: string;
        pageType?: string;
      }
    >({
      query: (payload) => ({
        url: "/web/task/getTotalClientBirthDayTodoByAgent",
        method: "POST",
        body: payload,
      }),
    }),
    getCPD: builder.query<
      ICPD,
      {
        agentCode: string | number;
        listEventId: number[];
      }
    >({
      query: (payload) => ({
        url: "/web/task/search",
        method: "POST",
        body: {
          data: payload,
          page: 1,
          size: 99999,
        },
      }),
    }),
    getUnit: builder.query<IUnits, IUnitPayload>({
      query: (payload) => ({
        url: "/web/task/findUnit",
        method: "POST",
        body: payload,
      }),
      transformResponse: (res: IUnit[], _, args) => {
        return { data: res, lastPage: args.size > res.length };
      },
    }),
    getUnitFilter: builder.query<UnitFilterType[], any>({
      query: ({ agentNumber, salesforceId, agentCode }) => ({
        url: "/web/task/findFilterUnit",
        method: "POST",
        body: {
          agentNumber,
          salesforceId,
          agentCode,
        },
      }),
    }),
    getSesiTraining: builder.query<SesiTraining, { agentNumber: string }>({
      query: ({ agentNumber }) => {
        const dateBegin = new Date();
        const dateEnd = new Date();

        dateBegin.setHours(0);
        dateBegin.setMinutes(0);
        dateBegin.setSeconds(0);

        dateEnd.setHours(23);
        dateEnd.setMinutes(59);
        dateEnd.setSeconds(59);

        return {
          url: "/web/task/schedule",
          method: "POST",
          body: {
            agentCode: agentNumber,
            cityId: null,
            fromDate: dateBegin.toISOString(),
            toDate: dateEnd.toISOString(),
            trainingId: null,
          },
        };
      },
    }),
  }),
});

export const {
  useGetRekrutmenQuery,
  useGetListPerundinganQuery,
  useGetProsesBillingQuery,
  useGetProsesPerubahanPolisQuery,
  useGetPendingProsesKlaimQuery,
  useGetPolisLewatJatuhTempoQuery,
  useGetUlangTahunQuery,
  useGetCPDQuery,
  useGetUnitQuery,
  useLazyGetUnitQuery,
  useGetUnitFilterQuery,
  useGetSesiTrainingQuery,
} = tasksApi;
