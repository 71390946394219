import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export type INotifications = INotification[];

export interface INotification {
  processed: boolean;
  event_id: number;
  time_stamp: string;
  alert: string;
  payload: Payload;
  opened: boolean;
  id: number;
  module_name: string;
  userid: string;
}

export interface Payload {
  status?: string;
  reqId: string;
  id?: string;
  message?: string;
  newsId: any;
  agentType: any;
  cityName?: string;
  remarkPending: any;
  trainingName?: string;
  trainingScheduleId?: number;
  startDate?: string;
  expiredDate?: string;
  result?: string;
  agentName_agentNumber?: string;
  periodePromosi?: string;
  secretaryName?: string;
  secretaryType?: string;
  pruforceId?: string;
}

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getNotifications: builder.query<
      INotifications,
      {
        userid: string;
        filterBy: string;
        size: number;
        page: number;
      }
    >({
      query: ({ filterBy = "All", page, size, userid }) => ({
        url: "/web/aobInterceptor/aobSqlAdapter/getUnreadPushNotificationStatementNew",
        method: "POST",
        body: {
          filterBy,
          page,
          size,
          userid,
        },
      }),
    }),
    getTotalUnreadNotification: builder.query<
      { total: number },
      {
        userid: string;
        filterBy?: string;
      }
    >({
      query: ({ filterBy = "All", userid }) => ({
        url: "/web/aobInterceptor/aobSqlAdapter/getTotalUnreadPushNotificationNew",
        method: "POST",
        body: {
          filterBy,
          userid,
        },
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useGetTotalUnreadNotificationQuery,
} = notificationsApi;
