import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IInitialState {
  fatcaStatus: boolean;
}

const initialState: IInitialState = {
  fatcaStatus: false
};

const FatcaSlice = createSlice({
  name: "fatcaStatus",
  initialState,
  reducers: {
    setFatcaStatus: (state, action: PayloadAction<boolean>) => {
      state.fatcaStatus = action.payload;
    }
  },
});

export const {
  setFatcaStatus,
} = FatcaSlice.actions;
export default FatcaSlice.reducer;

