export const PAGE_LOGIN = "PAGE-LOGIN";
export const PAGE_NEWS = "PAGE-NEWS";
export const PAGE_DASHBOARD = "PAGE-DASHBOARD";
export const PAGE_PROFILE = "PAGE-PROFILE";
export const PAGE_MYTEAM = "PAGE-MYTEAM";
export const PAGE_MYTEAM_DETAIL = "PAGE-MYTEAM-DETAIL";
export const PAGE_RINCIAN_MAPA = "PAGE-RINCIAN-MAPA";

export const PAGE_DETAIL_NEWS = "PAGE-DETAIL-NEWS";
export const PAGE_SECRETARY = "PAGE-SECRETARY";
export const PAGE_DETAIL_SECRETARY = "PAGE-DETAIL-SECRETARY";
export const PAGE_HELP = "PAGE-HELP";
export const PAGE_FAQ = "PAGE-FAQ";
export const PAGE_PRIVACYPOLICY = "PAGE-PRIVACYPOLICY";
export const PAGE_DICTIONARY = "PAGE-DICTIONARY";
export const PAGE_DICTIONARY_SEARCH = "PAGE-DICTIONARY-SEARCH";
export const PAGE_DAFTARISTILAH = "PAGE-DAFTARISTILAH";

export const PAGE_PERSISTENCY = "PAGE-PERSISTENCY";
export const PAGE_PERSISTENCY_ROLLING = "PAGE-PERSISTENCY-ROLLING";
export const PAGE_PERSISTENCY_ROLLING_DETAIL =
  "PAGE-PERSISTENCY-ROLLING-DETAIL";

export const PAGE_PERSISTENCY_LAMA = "PAGE-PERSISTENCY-LAMA";
export const PAGE_PERSISTENCY_LAMA_DETAIL = "PAGE-PERSISTENCY-LAMA-DETAIL";

export const PAGE_PRODUKSI = "PAGE-PRODUKSI";
export const PAGE_PRODUKSI_DETAIL = "PAGE-PRODUKSI-DETAIL";
export const PAGE_PRODUKSI_RINCIAN = "PAGE-PRODUKSI-RINCIAN";

export const PAGE_POLIS = "PAGE-POLIS";
export const PAGE_POLIS_RINCIAN = "PAGE-POLIS-RINCIAN";

export const PAGE_TASKLIST = "PAGE-TASKLIST";
export const PAGE_DOCUMENT = "PAGE-DOCUMENT";

export const PAGE_PROPOSAL = "PAGE-PROPOSAL";
export const PAGE_PROPOSAL_RINCIAN = "PAGE-PROPOSAL-RINCIAN";

export const ACTION_PROFILE_DATA_PRIBADI = "ACTION-PROFILE-DATA-PRIBADI";
export const ACTION_PROFILE_DATA_KEAGENAN = "ACTION-PROFILE-DATA-KEAGENAN";
export const ACTION_PROFILE_CPD_AAJI = "ACTION-PROFILE-CPD-AAJI";
export const ACTION_PROFILE_CPD_AASI = "ACTION-PROFILE-CPD-AASI";

export const ACTION_PERSISTENCY_FILTER = "ACTION-PERSISTENCY-FILTER";
export const ACTION_PERSISTENCY_SEND_MAIL = "ACTION-PERSISTENCY-SEND-MAIL";
export const ACTION_PERSISTENCY_DOWNLOAD_DATA =
  "ACTION-PERSISTENCY-DOWNLOAD-DATA";

export const ACTION_PERSISTENCY_ROLLING_TAB = "ACTION-PERSISTENCY-ROLLING-TAB";
export const ACTION_PERSISTENCY_ROLLING_FILTER =
  "ACTION-PERSISTENCY-ROLLING-UNIT-FILTER";
export const ACTION_PERSISTENCY_ROLLING_POLIS_SELECTION =
  "ACTION-PERSISTENCY-ROLLING-POLIS-SELECTION";
export const ACTION_PERSISTENCY_ROLLING_SORT =
  "ACTION-PERSISTENCY-ROLLING-SORT";
export const ACTION_PERSISTENCY_ROLLING_DOWNLOAD_ROW =
  "ACTION-PERSISTENCY-DOWNLOAD-ROW";
export const ACTION_PERSISTENCY_ROLLING_SEND_MAIL =
  "ACTION-PERSISTENCY-SEND-MAIL";
export const ACTION_PERSISTENCY_ROLLING_PAGE_SIZE =
  "ACTION-PERSISTENCY-ROLLING-PAGE-SIZE";

export const ACTION_PERSISTENCY_ROLLING_DETAIL_FILTER =
  "ACTION-PERSISTENCY-ROLLING-DETAILS-FILTER";
export const ACTION_PERSISTENCY_ROLLING_DETAIL_SORT =
  "ACTION-PERSISTENCY-ROLLING-DETAIL-SORT";
export const ACTION_PERSISTENCY_ROLLING_DETAIL_PAGE_SIZE =
  "ACTION-PERSISTENCY-ROLLING-DETAIL-PAGE-SIZE";

export const ACTION_PERSISTENCY_LAMA_TAB = "ACTION-PERSISTENCY-LAMA-TAB";
export const ACTION_PERSISTENCY_LAMA_FILTER =
  "ACTION-PERSISTENCY-LAMA-UNIT-FILTER";
export const ACTION_PERSISTENCY_LAMA_POLIS_SELECTION =
  "ACTION-PERSISTENCY-LAMA-POLIS-SELECTION";
export const ACTION_PERSISTENCY_LAMA_SORT = "ACTION-PERSISTENCY-LAMA-SORT";
export const ACTION_PERSISTENCY_LAMA_DOWNLOAD_ROW =
  "ACTION-PERSISTENCY-DOWNLOAD-ROW";
export const ACTION_PERSISTENCY_LAMA_SEND_MAIL = "ACTION-PERSISTENCY-SEND-MAIL";
export const ACTION_PERSISTENCY_LAMA_PAGE_SIZE =
  "ACTION-PERSISTENCY-LAMA-PAGE-SIZE";

export const ACTION_PERSISTENCY_LAMA_DETAIL_FILTER =
  "ACTION-PERSISTENCY-LAMA-DETAILS-FILTER";
export const ACTION_PERSISTENCY_LAMA_DETAIL_SORT =
  "ACTION-PERSISTENCY-LAMA-DETAIL-SORT";
export const ACTION_PERSISTENCY_LAMA_DETAIL_PAGE_SIZE =
  "ACTION-PERSISTENCY-LAMA-DETAIL-PAGE-SIZE";

export const ACTION_LOGIN_SUCCESS = "ACTION-LOGIN-SUCCESS";
export const ACTION_KPM_CLICK_MAPS = "ACTION-KPM-CLICK-MAPS";
export const ACTION_SEARCH_KPM = "ACTION-SEARCH-KPM";
export const ACTION_SELECT_CITY_KPM = "ACTION-SELECT-CITY-KPM";
export const ACTION_SORTING_KPM = "ACTION-SORTING-KPM";
export const ACTION_SEARCH_RS = "ACTION-SEARCH-RS";
export const ACTION_CLICK_LUPA_PASSWORD = "ACTION-CLICK-LUPA-PASSWORD";

export const ACTION_RINCIAN_MAPA_FILTER = "ACTION-RINCIAN-MAPA-FILTER";
export const ACTION_RINCIAN_MAPA_DOWNLOAD = "ACTION-RINCIAN-MAPA-DOWNLOAD";
export const ACTION_RINCIAN_MAPA_SEARCH = "ACTION-RINCIAN-MAPA-SEARCH";

export const ACTION_NEWS_DETAIL = "ACTION-NEWS-DETAIL";
export const ACTION_NEWS_DETAIL_DOWNLOAD = "ACTION-NEWS-DETAIL-DOWNLOAD";

export const ACTION_FILTER_DASHBOARD = "ACTION-FILTER-DASHBOARD";
export const ACTION_DOWNLOAD_DASHBOARD = "ACTION-DOWNLOAD-DASHBOARD";
export const ACTION_ERROR_CHART_DASHBOARD = "ACTION-ERROR-CHART-DASHBOARD";

export const Landing = "Landing";
export const Login_Success = "Login_Success";
export const KPM_Province = "KPM_Province";
export const RS_List = "RS_List";
export const Dashboard_Page = "Dashboard_Page";
export const MAPA_Page = "MAPA_Page";
export const Profile_Page = "Profile_Page";
export const Tim_Saya_Page = "Tim_Saya_Page";
export const Bantuan_Page = "Bantuan_Page";
export const Notifikasi_List = "Notifikasi_List";
export const News_Page = "News_Page";
export const Sekretaris_Page = "Sekretaris_Page";
export const Daftar_Tugas_Page = "Daftar_Tugas_Page";
export const Download_Dokumen_Page = "Download_Dokumen_Page";
export const View_Data_Produksi = "View_Data_Produksi";
export const View_Detail_Data_Produksi = "View_Detail_Data_Produksi";
export const Rincian_Transaksi_Produksi = "Rincian_Transaksi_Produksi";
export const Rincian_Transaksi_Individu = "Rincian_Transaksi_Individu";
export const View_Data_Persistensi = "View_Data_Persistensi";
export const View_Data_Persistensi_Rolling = "View_Data_Persistensi_Rolling";
export const View_Data_Persistensi_Lama = "View_Data_Persistensi_Lama";
export const View_Data_Proposal = "View_Data_Proposal";
export const Rincian_Proposal = "Rincian_Proposal";
export const View_Data_Polis = "View_Data_Polis";
export const Rincian_Polis = "Rincian_Polis";
