import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  TRincianUnitResponse,
  TRincianIndividuResponse,
  TGroup,
} from "../../services/produksi";
type Sort = {
  id: string;
  desc: boolean;
};
export interface RincianState {
  rincian_unit: TRincianUnitResponse[] | undefined;
  rincian_individu: TRincianIndividuResponse[] | undefined;
  rincian_group: TGroup[] | undefined;
  rincian_adond: TGroup[] | undefined;

  page: number;
  size: number;
  filter: string;
  agentType: string;
  total: number;
  clientName: string;
  agentNumber: string;
  sort: Sort | null;
  kategori: string;
  search: string;
}

const initialState: RincianState = {
  rincian_unit: [],
  rincian_individu: [],
  rincian_group: [],
  rincian_adond: [],
  page: 1,
  size: 10,
  filter: "",
  agentType: "",
  total: 0,
  clientName: "",
  agentNumber: "",
  sort: null,
  kategori: "",
  search: "",
};

export const rincianProduksiSlice = createSlice({
  name: "rincian_produksi",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    setFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    setAgentType: (state, action: PayloadAction<string>) => {
      state.agentType = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setClientName: (state, action: PayloadAction<string>) => {
      state.clientName = action.payload;
    },
    setAgentNumber: (state, action: PayloadAction<string>) => {
      state.agentNumber = action.payload;
    },
    setSort: (state, action: PayloadAction<Sort | null>) => {
      state.sort = action.payload;
    },
    setKategori: (state, action: PayloadAction<string>) => {
      state.kategori = action.payload;
    },

    resetPayload: (state) => {
      state.page = 1;
      state.size = 10;
      state.filter = "";
      state.agentType = "";
      state.clientName = "";
      state.agentNumber = "";
      state.sort = null;
      state.kategori = "";
      state.search = "";
    },

    setRincian: (
      state,
      action: PayloadAction<TRincianUnitResponse[] | undefined>
    ) => {
      state.rincian_unit = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setRincianIndividu: (
      state,
      action: PayloadAction<TRincianIndividuResponse[] | undefined>
    ) => {
      state.rincian_individu = action.payload;
    },
    setRincianGroup: (state, action: PayloadAction<TGroup[] | undefined>) => {
      state.rincian_group = action.payload;
    },
    setRincianAdonad: (state, action: PayloadAction<TGroup[] | undefined>) => {
      state.rincian_adond = action.payload;
    },
  },
});

export const {
  setPage,
  setAgentNumber,
  setAgentType,
  setClientName,
  setFilter,
  setSize,
  setSort,
  setTotal,
  setRincian,
  setKategori,
  resetPayload,
  setSearch,
  setRincianIndividu,
  setRincianGroup,
  setRincianAdonad,
} = rincianProduksiSlice.actions;

export default rincianProduksiSlice.reducer;
