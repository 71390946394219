import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export type IGetOffice = { data: IGetOfficeRespone; expirationTime: number };
export type IGetOfficeRespone = OfficeData[];

export interface OfficeData {
  officeAddr1: string;
  officeAddr2: string;
  officeAddr3: string;
  officeName: string;
  officePhone: string;
  city_code: string;
  province_code: string;
}

export type IGetProvinceResponse = ProvinceData[];
export interface ProvinceData {
  longitude: number;
  latitude: number;
  province_code: string;
  name: string;
}

const metaBaseQuery: BaseQueryFn<string | FetchArgs> = async (
  args,
  api,
  extraOptions
) => {
  const baseResult: any = await fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      return headers;
    },
  })(args, api, extraOptions);

  return {
    ...baseResult,
  };
};
export const kantorKeagenanApi = createApi({
  reducerPath: "kantorKeagenanApi",

  baseQuery: metaBaseQuery,
  endpoints: (builder) => ({
    getOffice: builder.query<
      IGetOfficeRespone,
      {
        filterProvinceCode: string;
      }
    >({
      query: ({ filterProvinceCode }) => {
        return {
          url: "/general/publicInformation/office",
          method: "POST",
          body: {
            page: 1,
            size: 999,
            filterCityCode: "",
            filterProvinceCode,
            search: "",
          },
        };
      },

      transformResponse: (
        res: IGetOfficeRespone & { error?: number },
        meta,
        arg
      ) => {
        const data = res.map((data, i) => ({ ...data, id: i }));

        return data;
      },
    }),
    getProvince: builder.query<IGetProvinceResponse, void>({
      query: () => ({
        url: "/general/publicInformation/province",
        method: "POST",
        body: {
          page: 1,
          size: 999,
          filterProvinceCode: "",
          search: "",
        },
      }),
    }),
  }),
});

export const { useGetOfficeQuery, useGetProvinceQuery } = kantorKeagenanApi;
