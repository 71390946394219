import { ReactComponent as Whatsapp } from "../../assets/u_whatsapp2.svg";
import { ReactComponent as ArrowRight } from "../../assets/u_angle-right-b.svg";
import { ReactComponent as CommentQuestion } from "../../assets/u_comment-question.svg";
import { ReactComponent as Ubooks } from "../../assets/u_books.svg";
import { ReactComponent as ULock } from "../../assets/u_lock-access.svg";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as analyticConst from "../../resource/analytic_const";
import { logEvents } from "../../utils/analytics";
import { useSessionStorage } from "usehooks-ts";
import { Login } from "../../services/login";

export default function Bantuan() {
  const navigate = useNavigate();
  const [user] = useSessionStorage<Login | undefined>("user", undefined);

  useEffect(() => {
    logEvents("Bantuan", {
      content_id: analyticConst.Bantuan_Page,
      agent_code: user?.agentData.agentNumber,
    });
  }, []);
  return (
    <>
      <p className="font-lato font-bold text-2xl mb-5">Bantuan</p>
      <div className="bg-white rounded-md w-full px-6 pt-2 pb-5">
        <Card
          icon={<Whatsapp />}
          description="Whatsapp business layanan bantuan terkait fitur dan fungsi yang ada
          pada aplikasi PRUForce."
          title="Bantuan Aplikasi dan Digital Friend"
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_HELP_WHATSAPP}`)
          }
        />
        <Card
          icon={<CommentQuestion />}
          description="Daftar pertanyaan yang sering ditanyakan seputar produk dan layanan yang tersedia di Prudential Indonesia"
          title="FAQ"
          onClick={() => navigate("/help/faq")}
        />
        <Card
          icon={<Ubooks />}
          title="Dictionary"
          description="Panduan berisi istilah asuransi dan medis yang terdapat dalam SPAJ dan Ilustrasi, termasuk definisi dan penjelasannya"
          onClick={() => navigate("/help/dictionary")}
        />
        <Card
          icon={<EnvelopeIcon className="w-[30px] h-[30px] text-[#ED1B2E]" />}
          title="Hubungi Kami"
          onClick={() => navigate("/help/contactus")}
        />
        <Card
          icon={<ULock />}
          title="Kebijakan Privasi PT Prudential Life Assurance"
          onClick={() => navigate("/help/privacypolicy")}
        />
      </div>
    </>
  );
}

const Card: React.FC<{
  icon: ReactElement;
  title: string;
  description?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ icon, description, onClick, title }) => (
  <div
    className="w-full bg-gray-50 rounded-md p-5 flex gap-5 mt-5 cursor-pointer"
    onClick={onClick}
  >
    {/* <Whatsapp /> */}
    {icon}
    <div className="flex justify-between w-full items-center">
      <div className="flex flex-col gap-1">
        <p className="font-bold font-lato">
          {/* Bantuan Aplikasi dan Digital Friend */}
          {title}
        </p>
        <p className="font-lato">
          {/* Whatsapp business layanan bantuan terkait fitur dan fungsi yang ada
          pada aplikasi PRUForce. */}
          {description}
        </p>
      </div>
      <div>
        <ArrowRight />
      </div>
    </div>
  </div>
);
