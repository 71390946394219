import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface ClaimHistoryResponse {
  bank: string;
  productId: string;
  productHtml: string;
  claimDecisionDate: string;
  approvedAmount: number;
  creationDate: string;
  insuranceBenefit: string;
  claimStatus: string;
  remarks: string;
}

export interface ClaimHistoryResponseAlt {
  message: string;
}

export const claimHistoryApi = createApi({
  reducerPath: "riwayatKlaim",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getClaimHistory: builder.query<ClaimHistoryResponse[], any>({
      query: ({ type, policyNumber, salesforceId, agentCode }) => ({
        url: "/web/policy/findClaimHistory",
        method: "POST",
        body: {
          policyNumber,
          type,
          salesforceId,
          agentCode,
        },
      }),
      transformResponse: (response: ClaimHistoryResponse[]) => {
        return Array.isArray(response) ? response : [];
      },
    }),
  }),
});

export const { useGetClaimHistoryQuery } = claimHistoryApi;
