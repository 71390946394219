import { useState, Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from ".";
import {
  ISecretary,
  useGetListMenuSecretaryQuery,
  useGetSecretaryDetailQuery,
} from "../../services/secretary";
import { useSessionStorage } from "usehooks-ts";
import { Login } from "../../services/login";
import { ReactComponent as Check } from "../../assets/check-2.svg";
import { ReactComponent as Close } from "../../assets/close.svg";
import Modal from "../../Components/atom/modal";
const ToggleData = (props: any) => {
  const [showRestrictionModal, setShowRestrictionModal] = useState(false);

  const location = useLocation();
  const data = location?.state as { data: ISecretary };
  let { id } = useParams();
  const objData = data?.data || {};
  const [user] = useSessionStorage<Login | undefined>("user", undefined);

  const { data: secretaryData, isLoading } = useGetSecretaryDetailQuery({
    pruforceId: id as string,
    agentCode: user?.agentData.agentNumber || "",
  });
  const { data: menus, isLoading: isLoadingMenu } =
    useGetListMenuSecretaryQuery({
      secretariesType: objData.secretariesType
        ? objData.secretariesType
        : secretaryData?.secretariesType || "",
      agentCode: user?.agentData.agentNumber || "",
    });

  const half = Math.ceil(menus && menus?.length > 0 ? menus.length / 3 : 0);

  const firstHalf = menus?.slice(0, half);
  const secondHalf = menus?.slice(half);

  return (
    <div className="lg:w-[100%] h-full bg-white rounded-lg p-6 grid sm:grid-cols-2 mb-4 gap-4 sm:gap-10 justify-between">
      <div className="flex flex-col gap-4 ">
        {isLoading || isLoadingMenu ? (
          <Loader />
        ) : (
          firstHalf?.map((item, index) => (
            <Fragment key={index}>
              {item.map((v, i) => (
                <span
                  onClick={() => {
                    setShowRestrictionModal(true);
                  }}
                  className={`flex cursor-pointer flex-col gap-4 ${
                    !Number.isInteger(v.priority) ? "ml-5" : ""
                  }`}
                  key={i}
                >
                  <div className="flex justify-between items-center">
                    <p
                      className={`${
                        Number.isInteger(v.priority) ? "font-bold" : ""
                      } text-[14px] md:text-[16px]`}
                    >
                      {v.description}
                    </p>
                    {secretaryData?.allowAccess.includes(v.name) ? (
                      <Check className="text-green-500" width={30} />
                    ) : (
                      <Close className="text-red-500" width={30} />
                    )}
                  </div>
                  {/* <Toggle
                    checked={secretaryData?.allowAccess.includes(v.name)}
                    onChange={() => {}}
                    disabled
                  >
                    <Toggle.Label
                      className={`${
                        Number.isInteger(v.priority) ? "font-bold" : ""
                      } text-[14px] md:text-[16px]`}
                    >
                      {v.description}
                    </Toggle.Label>
                  </Toggle> */}
                  <div className="w-full bg-[#E5E7EB] h-[1px]" />
                </span>
              ))}
            </Fragment>
          ))
        )}
      </div>

      <div className="flex flex-col gap-4 ">
        {isLoading || isLoadingMenu ? (
          <Loader />
        ) : (
          secondHalf?.map((item, index) => (
            <Fragment key={index}>
              {item.map((v, i) => (
                <span
                  onClick={() => {
                    setShowRestrictionModal(true);
                  }}
                  className={`flex cursor-pointer flex-col gap-4 ${
                    !Number.isInteger(v.priority) ? "ml-5" : ""
                  }`}
                  key={i}
                >
                  <div className="flex justify-between items-center">
                    <p
                      className={`${
                        Number.isInteger(v.priority) ? "font-bold" : ""
                      } text-[14px] md:text-[16px]`}
                    >
                      {v.description}
                    </p>
                    {secretaryData?.allowAccess.includes(v.name) ? (
                      <Check className="text-green-500" width={30} />
                    ) : (
                      <Close className="text-red-500" width={30} />
                    )}
                  </div>
                  {/* <Toggle
                    checked={secretaryData?.allowAccess.includes(v.name)}
                    onChange={() => {}}
                    disabled
                  >
                    <Toggle.Label
                      className={`${
                        Number.isInteger(v.priority) ? "font-bold" : ""
                      } text-[14px] md:text-[16px]`}
                    >
                      {v.description}
                    </Toggle.Label>
                  </Toggle> */}
                  <div className="w-full bg-[#E5E7EB] h-[1px]" />
                </span>
              ))}
            </Fragment>
          ))
        )}
      </div>
      <Modal
        type="info"
        show={showRestrictionModal}
        confirmButtonText="Ok"
        description="Untuk membuat perubahan pada daftar sekretaris/admin, silakan akses aplikasi PRUForce mobile."
        onBackdropPress={() => setShowRestrictionModal(false)}
        onConfirm={() => setShowRestrictionModal(false)}
      />
    </div>
  );
};

export default ToggleData;
