import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { groupBy, maxBy } from "lodash";

export type TOption = {
  key: string;
  value: string;
};
export type TPolicy = {
  totalapidecreased: number;
  productcode: string;
  productid: string;
  policynumber: string;
  totalapilapsed: number;
  clientname: string;
  policystatus: string;
  totalapiissued: number;
  productHtml: string;
  policycode: string;
  totalapiincreased: number;
};
type TResult = {
  result: TPersistencyCR[];
};

interface IGetPersistencyGraphCRPayload {
  agentCode: string;
}

export interface IFindAllPolicyStatus {
  agentNumber: string;
  salesforceId: string;
  agentCode: string;
  period: string;
}
export interface IFindAllPolicy {
  page?: number;
  size?: number;
  agentNumber: string;
  filterBy: string;
  filterVal: string;
  searchVal: string;
  sortVal: string;
  salesforceId: string;
  agentCode: string;
  period: string;
}

export interface IFindAllPolicyStatusResponse {
  API: TOption[];
  PolicyStatus: TOption[];
}

export interface IFindAllPolicyResponse {
  result: TPolicy[];
  total: number;
  date: string;
}

export interface IFindAllAgentTypeCR {
  agentNumber: string;
  persistencyCode: string;
  salesforceId: string;
  agentCode: string;
  period: string;
  channelType: number;
}
export interface IFindAllAgentTypeCRResponse {
  AgentType: TOption[];
  Rolling: TOption[];
  Current: TOption[];
}

export interface IFindAllPersistencyCR {
  page?: number;
  size?: number;
  agentNumber: string;
  leaderNumber: string;
  filterBy: string;
  filterVal: string;
  searchVal: string;
  sortBy: string;
  sortVal: string;
  salesforceId: string;
  agentCode: string;
  persistencyCode: string;
  period: string;
  channelType: number;
}

export type TPersistencyCR = {
  agentType: string;
  apiDecreasedRolling: number;
  clientName: string;
  agentNumber: string;
  apiLapsedRolling: number;
  policyFlag: string;
  totalNetAPICurrent: number;
  apiIncreasedCurrent: number;
  apiIssuedRolling: number;
  apiIncreasedRolling: number;
  apiIssuedCurrent: number;
  totalNetAPIRolling: number;
  apiDecreasedCurrent: number;
  apiLapsedCurrent: number;
};

export interface IFindAllPersistencyCRResponse {
  All: TResult;
  Sharia: TResult;
  Conventional: TResult;
  date: string;
}

export type TPersistencyDownload = {
  agentNumber: string;
  agentNumberLogin: string;
  dataPerbaharui: string;
  detailUnit: any;
  detailUnitSharia: any;
  detailUnitConventional: any;
  persistencyCode: string;
  email?: string;
};

export type TPersistencyDownloadResponse = {
  addition: {
    agentName: string;
    email: string;
  };
  contenttype: string;
  file: string;
  filename: string;
  filetype: string;
  isSuccessful: boolean;
  status?: number;
};
export interface PayloadPerssistencySummaryKpm {
  officeCode: string;
  date_start: string;
  interval_number: string;
  agent_cd: string;
}

export type TtotalResponse = {
  message: string;
  status: string;
  total: number;
};
export type TPersistencyDownloadSendEmailKPM = {
  agent_cd: string;
  date_start: string;
  email: string;
  interval_number: string;
  officeCode: string;
};
export const persistencyApi = createApi({
  reducerPath: "persistencyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getPersistencyGraphCR: builder.query<any, IGetPersistencyGraphCRPayload>({
      query: (payload) => ({
        url: "/web/persistency/newods/findPersistencyGraphCR",
        method: "POST",
        body: payload,
      }),
    }),
    findAllPolicyStatusRolling: builder.query<
      IFindAllPolicyStatusResponse,
      IFindAllPolicyStatus
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/findAllPolicyStatusRolling",
        method: "POST",
        body: payload,
      }),
    }),

    findAllPolicyRolling: builder.mutation<
      IFindAllPolicyResponse,
      IFindAllPolicy
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/findAllPolicyRolling",
        method: "POST",
        body: payload,
      }),
    }),

    findAllPolicyStatusCurrent: builder.query<
      IFindAllPolicyStatusResponse,
      IFindAllPolicyStatus
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/findAllPolicyStatusCurrent",
        method: "POST",
        body: payload,
      }),
    }),

    findAllPolicyCurrent: builder.mutation<
      IFindAllPolicyResponse,
      IFindAllPolicy
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/findAllPolicyCurrent",
        method: "POST",
        body: payload,
      }),
    }),

    persistencyDownload: builder.mutation<
      TPersistencyDownloadResponse,
      TPersistencyDownload
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/download-summary-detail",
        method: "POST",
        body: payload,
      }),
    }),
    persistencySendEmail: builder.mutation<
      TPersistencyDownloadResponse,
      TPersistencyDownload
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/email-summary-detail",
        method: "POST",
        body: payload,
      }),
    }),

    getPerssistencySummaryKpm: builder.query<
      any,
      PayloadPerssistencySummaryKpm
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/summary-kpm",
        method: "POST",
        body: payload,
      }),
      transformResponse: (rawResult: any) => {
        if (!rawResult) return null;
        let group = groupBy(rawResult.kpm.All, (item) => {
          return [item["accounting_month"], item["accounting_year"]];
        });
        let grouparr = Object.values(group);
        let temp: any = [];

        for (let d of grouparr) {
          if (d.length > 1) {
            let max = maxBy(d, (o: any) => {
              return o.snap_date;
            });
            temp.push(max);
          } else {
            for (let c of d) {
              temp.push(c);
            }
          }
        }

        let newmap: any = temp.map((d: any) => {
          return {
            period: d.period,
            totalprsctyapirolling: d.rolling_persistency_api,
            totalprsctyapicurrent: d.persistency_api,
          };
        });

        return { graph: newmap, raw: rawResult, date: rawResult.date };
      },
    }),

    findAllAgentTypeCR: builder.query<
      IFindAllAgentTypeCRResponse,
      IFindAllAgentTypeCR
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/findAllAgentTypeCR",
        method: "POST",
        body: payload,
      }),
    }),
    findAllPersistencyCR: builder.mutation<
      IFindAllPersistencyCRResponse,
      IFindAllPersistencyCR
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/findAllPersistencyCR",
        method: "POST",
        body: payload,
      }),
    }),

    persistencyCRTotal: builder.mutation<TtotalResponse, IFindAllPersistencyCR>(
      {
        query: (payload) => ({
          url: "/web/persistency/newods/findAllPersistencyCRTotal",
          method: "POST",
          body: payload,
        }),
      }
    ),
    persistencyCurrentTotal: builder.mutation<TtotalResponse, IFindAllPolicy>({
      query: (payload) => ({
        url: "/web/persistency/newods/findAllPolicyCurrentTotal",
        method: "POST",
        body: payload,
      }),
    }),
    persistencyRollingTotal: builder.mutation<TtotalResponse, IFindAllPolicy>({
      query: (payload) => ({
        url: "/web/persistency/newods/findAllPolicyRollingTotal",
        method: "POST",
        body: payload,
      }),
    }),
    persistencyDownloadSendEmailKPM: builder.mutation<
      TPersistencyDownloadResponse,
      TPersistencyDownloadSendEmailKPM
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/download/report",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetPersistencyGraphCRQuery,

  useFindAllPolicyRollingMutation,
  useFindAllPolicyStatusRollingQuery,

  useFindAllPolicyCurrentMutation,
  useFindAllPolicyStatusCurrentQuery,

  usePersistencyDownloadMutation,
  useGetPerssistencySummaryKpmQuery,
  usePersistencySendEmailMutation,

  useFindAllAgentTypeCRQuery,
  useFindAllPersistencyCRMutation,

  usePersistencyCRTotalMutation,
  usePersistencyCurrentTotalMutation,
  usePersistencyRollingTotalMutation,
  usePersistencyDownloadSendEmailKPMMutation,
} = persistencyApi;
