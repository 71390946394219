import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IInitialState {
  payloadState: {
    currentFilterDropdown: string | null;
    selectField: string | null;
    textField: string | null;
    page: number;
    size: number;
    total: number;
    sortDir: string;
    sortBy: string;
    agentName: string;
    agentNum: string;
    policyName: string;
    policyNum: string;
    assuredName: string;
    productName: string;
    search: string;
    syariah: string;
    filterBy: string;
    filterVal: string;
  };
}

const initialState: IInitialState = {
  payloadState: {
    currentFilterDropdown: null,
    selectField: null,
    textField: "",
    page: 1,
    size: 10,
    total: 1,
    sortDir: "asc",
    sortBy: "agentName",
    agentName: "",
    agentNum: "",
    policyName: "",
    policyNum: "",
    assuredName: "",
    productName: "",
    search: "",
    syariah: "",
    filterBy: "",
    filterVal: "",
  },
};

const UnitPolisSlice = createSlice({
  name: "UnitPolis",
  initialState,
  reducers: {
    setCurrentFilterDropdown: (state, action: PayloadAction<string | null>) => {
      state.payloadState.currentFilterDropdown = action.payload;
    },
    setSelectField: (state, action: PayloadAction<string | null>) => {
      state.payloadState.selectField = action.payload;
    },
    setTextField: (state, action: PayloadAction<string | null>) => {
      state.payloadState.textField = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.payloadState.page = action.payload;
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.payloadState.size = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.payloadState.total = action.payload;
    },
    setFilterBy: (state, action: PayloadAction<string>) => {
      state.payloadState.filterBy = action.payload;
    },
    setFilterVal: (state, action: PayloadAction<string>) => {
      state.payloadState.filterVal = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.payloadState.sortDir = action.payload;
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.payloadState.sortBy = action.payload;
    },
    setAgentName: (state, action: PayloadAction<string>) => {
      state.payloadState.agentName = action.payload;
    },
    setAgentNum: (state, action: PayloadAction<string>) => {
      state.payloadState.agentNum = action.payload;
    },
    setPolicyName: (state, action: PayloadAction<string>) => {
      state.payloadState.policyName = action.payload;
    },
    setPolicyNum: (state, action: PayloadAction<string>) => {
      state.payloadState.policyNum = action.payload;
    },
    setAssuredName: (state, action: PayloadAction<string>) => {
      state.payloadState.assuredName = action.payload;
    },
    setProductName: (state, action: PayloadAction<string>) => {
      state.payloadState.productName = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.payloadState.search = action.payload;
    },
    setSyariah: (state, action: PayloadAction<string>) => {
      state.payloadState.syariah = action.payload;
    },
    setEmpty: (state, action: PayloadAction<string>) => {
      if (action.payload === "reset") {
        state.payloadState.currentFilterDropdown = null;
        state.payloadState.selectField = "";
        state.payloadState.textField = "";
      }
      state.payloadState.filterBy = "";
      state.payloadState.filterVal = "All";
      state.payloadState.search = "";
      state.payloadState.agentNum = "";
      state.payloadState.agentName = "";
      state.payloadState.policyName = "";
      state.payloadState.assuredName = "";
      state.payloadState.policyNum = "";
      state.payloadState.productName = "";
      state.payloadState.page = 1;
    },
    reset: () => initialState,
  },
});

export const {
  setCurrentFilterDropdown,
  setSelectField,
  setTextField,
  setPage,
  setSize,
  setTotal,
  setSortDir,
  setSortBy,
  setAgentName,
  setAgentNum,
  setPolicyName,
  setPolicyNum,
  setAssuredName,
  setProductName,
  setSearch,
  setSyariah,
  setFilterBy,
  setFilterVal,
  setEmpty,
  reset,
} = UnitPolisSlice.actions;
export default UnitPolisSlice.reducer;
