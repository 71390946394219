import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { groupBy, maxBy } from "lodash";

interface snap {
  snap_year: number;
  snap_month: number;
}

export interface PayloadProductionSummary {
  agent_cd: string;
  tab_type: any;
  office_cd?: string;
  snap: snap;
}

export interface PayloadPerssistencySummary {
  agent_cd: string;
  date_start: string;
  interval_number: string;
}
export interface PayloadPerssistencySummaryKpm {
  officeCode: string;
  date_start: string;
  interval_number: string;
  agent_cd: string;
}

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  tagTypes: ["PERSISTENCY", "PRODUKSI", "KPM"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      // headers.set("Authorization", dummAuth);
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getProductionSummary: builder.query<any, PayloadProductionSummary>({
      query: (payload) => ({
        url: "/web/sales-dashboard/production/summary",
        method: "POST",
        body: payload,
      }),
      providesTags: ["PRODUKSI"],
    }),
    getPerssistencySummary: builder.query<any, PayloadPerssistencySummary>({
      query: (payload) => ({
        url: "/web/persistency/newods/summary-chart",
        method: "POST",
        body: payload,
      }),
      providesTags: ["PERSISTENCY"],
    }),
    getPerssistencySummaryKpm: builder.query<
      any,
      PayloadPerssistencySummaryKpm
    >({
      query: (payload) => ({
        url: "/web/persistency/newods/summary-kpm",
        method: "POST",
        body: payload,
      }),
      providesTags: ["KPM"],
      transformResponse: (rawResult: any) => {
        if (!rawResult) return null;
        let group = groupBy(rawResult.kpm.All, (item) => {
          return [item["accounting_month"], item["accounting_year"]];
        });
        let grouparr = Object.values(group);
        let temp: any = [];
        grouparr.map((d) => {
          if (d.length > 1) {
            let max = maxBy(d, (o: any) => {
              return o.snap_date;
            });

            temp.push(max);
          } else {
            d.map((c) => {
              temp.push(c);
            });
          }
        });

        let aftersort = temp.sort(
          (a: any, b: any) =>
            a.snap_date.split("-")[1] - b.snap_date.split("-")[1]
        );

        let newmap: any = aftersort.map((d: any) => {
          return {
            period: d.period,
            totalprsctyapirolling: d.rolling_persistency_api,
            totalprsctyapicurrent: d.persistency_api,
          };
        });

        return { data: newmap, date: rawResult.date };
        // return { ...rawResult, data: newmap };
      },
    }),
  }),
});
export const {
  useGetProductionSummaryQuery,
  useGetPerssistencySummaryQuery,
  useGetPerssistencySummaryKpmQuery,
} = dashboardApi;
