import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
export const defaultMapaListPayload = {
  type: "",
  perPage: 10,
  page: 1,
  sortBy: "",
  sortType: "asc",
  agentNumber: "",
  searchKey: "",
  period: "",
  periodType: "",
};

export const periodTypeData = [
  {
    title: "MTD",
    value: "month",
  },
  {
    title: "YTD",
    value: "year",
  },
];

export const types = [
  {
    title: "Unit",
    value: "unit",
  },
  {
    title: "Group",
    value: "group",
  },
];

type IMonthYear = {
  id: string;
  name: string;
};

export const month = [
  { id: "01", name: "Januari" },
  { id: "02", name: "Februari" },
  { id: "03", name: "Maret" },
  { id: "04", name: "April" },
  { id: "05", name: "Mei" },
  { id: "06", name: "Juni" },
  { id: "07", name: "Juli" },
  { id: "08", name: "Agustus" },
  { id: "09", name: "September" },
  { id: "10", name: "Oktober" },
  { id: "11", name: "November" },
  { id: "12", name: "Desember" },
];

export const year = Array(4)
  .fill("")
  .map((_, i) => ({
    id: (moment().year() - i).toString(),
    name: (moment().year() - i).toString(),
  }));

export const currentMonth = month.find(
  (data) => data.id === ("0" + (new Date().getMonth() + 1)).slice(-2)
);
export const currentYear = year.find(
  (data) => data.id === moment().year().toString()
);

type MapaListPayload = {
  type: string;
  perPage: number;
  page: number;
  sortBy: string;
  sortType: string;
  agentNumber: string;
  searchKey: string;
  period: string;
  periodType: string;
};
export interface RincianMapaState {
  mapaListPayload: MapaListPayload;

  mapaSummaryPayload: {
    type: string;
    period: string;
    periodType: string;
    agentNumber: string;
  };

  state: {
    periodType: typeof periodTypeData[0];
    typeData: typeof types[0] | null;
    month: typeof month[0] | null;
    year: typeof year[0] | null;
    search: string;
    searchUnit: string;
    searchGroup: string;
  };
}

const initialState: RincianMapaState = {
  mapaListPayload: {
    type: "",
    perPage: 10,
    page: 1,
    sortBy: "",
    sortType: "asc",
    agentNumber: "",
    searchKey: "",
    period: `${currentYear?.id}-${currentMonth?.id}`,
    periodType: "month",
  },
  mapaSummaryPayload: {
    type: "",
    period: "",
    periodType: "",
    agentNumber: "",
  },
  state: {
    periodType: periodTypeData[0],
    typeData: null,
    month: currentMonth || null,
    year: currentYear || null,
    search: "",
    searchGroup: "",
    searchUnit: "",
  },
};

export const rincianMapaSlice = createSlice({
  name: "rincianMapa",
  initialState,
  reducers: {
    setTypeData: (state, action: PayloadAction<typeof types[0]>) => {
      state.state.typeData = action.payload;
      state.mapaListPayload.type = action.payload.value;
      state.mapaListPayload.page = 1;

      state.mapaListPayload.searchKey =
        action.payload.value === "unit"
          ? state.state.searchUnit
          : state.state.searchGroup;
    },
    setPeriodType: (state, action: PayloadAction<typeof periodTypeData[0]>) => {
      state.state.periodType = action.payload;
      state.mapaListPayload.period = `${currentYear?.id}-${currentMonth?.id}`;
      state.mapaListPayload.periodType = action.payload.value;
      state.state.month = currentMonth as IMonthYear;
      state.state.year = currentYear as IMonthYear;
      state.mapaListPayload.searchKey = "";
      state.state.search = "";
      state.mapaListPayload.page = 1;
      state.state.searchUnit = "";
      state.state.searchGroup = "";
    },
    setMonth: (state, action: PayloadAction<typeof month[0] | null>) => {
      state.state.month = action.payload;
      state.mapaListPayload.period = `${state.state.year?.id}-${action.payload?.id}`;
      state.mapaListPayload.page = 1;
      state.mapaListPayload.searchKey = "";
      state.state.search = "";
      state.state.searchGroup = "";
      state.state.searchUnit = "";
    },
    setYear: (state, action: PayloadAction<typeof year[0] | null>) => {
      state.state.year = action.payload;
      if (state.state.periodType.value === "year") {
        if (action.payload?.id !== currentYear?.id) {
          state.mapaListPayload.period = `${action.payload?.id}-12`;
        } else {
          state.mapaListPayload.period = `${action.payload?.id}-${currentMonth?.id}`;
        }
      } else {
        state.mapaListPayload.period = `${action.payload?.id}-${state.state.month?.id}`;
      }
      state.mapaListPayload.page = 1;
      state.mapaListPayload.searchKey = "";
      state.state.search = "";
      state.state.searchGroup = "";
      state.state.searchUnit = "";
    },
    setSearch: (state, action: PayloadAction<string>) => {
      if (state.state.typeData?.value === "unit") {
        state.state.searchUnit = action.payload;
      }
      if (state.state.typeData?.value === "group") {
        state.state.searchGroup = action.payload;
      }
    },
    setMapaListPayload: (state, action: PayloadAction<MapaListPayload>) => {
      state.mapaListPayload = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTypeData,
  setPeriodType,
  setMonth,
  setYear,
  setSearch,
  setMapaListPayload,
} = rincianMapaSlice.actions;

export default rincianMapaSlice.reducer;
