import { Pie, Cell, Legend } from "recharts";
import { ChartPie } from "../../../Components/atom/chart";
import { LoaderChart } from "../../../Components/atom/loader-chart";
import { memo } from "react";
import moment from "moment";
interface PieInterface {
  name: string;
  value: number;
}
type TManPower = {
  data: any;
  loading: boolean;
};
export const ManPowerChart = memo((props: TManPower) => {
  const { data, loading } = props;
  const objData: PieInterface[] = [];

  const colors = ["#ED1B2E", "#FFA500", "#DEAA88", "#FDB3A3"];
  if (loading) return <LoaderChart title="Man Power" />;

  for (let i = 0; i < data.length; i++) {
    objData.push({
      name: data[i].agentType,
      value: data[i].totalManpower,
    });
  }

  const decimalToPercent = (decimal: number) => {
    return Math.round(decimal * 100);
  };

  const legendFormatter = (value: string, entry: any) => {
    return (
      <span className="text-sm">{`${value}: ${
        entry.payload.value
      } (${decimalToPercent(entry.payload.percent)}%)`}</span>
    );
  };

  let date = moment().utcOffset(7).format("LLLL");
  return (
    <div className=" w-full h-full    bg-white rounded-lg p-4 printable">
      <div className="flex justify-between items-start">
        <div>
          <p className="font-lato text-[18px] font-bold ">Man Power</p>
          <p className=" text-[11px]">Data diperbarui {date} WIB</p>
        </div>
      </div>

      <ChartPie data={objData}>
        <Pie dataKey="value" innerRadius={40} outerRadius={80} data={objData}>
          {objData.map((entry: PieInterface, index: number) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          formatter={legendFormatter}
        />
      </ChartPie>
    </div>
  );
});
