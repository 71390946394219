import React from 'react'

import { IconedTitlePropType } from './type'

const IconedTitle: React.FC<IconedTitlePropType> = ({src, title, headerTag, alt}) => {

    const CustomTag: keyof JSX.IntrinsicElements = `h${headerTag}`

    return (
        <CustomTag className="font-bold font-lato text-primary flex gap-[0.5rem] justify-start items-center text-xl">
            <img src={src} alt={alt} />
            {title}
        </CustomTag>
    )
}

export default IconedTitle