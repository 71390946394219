import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const lastDataUpdateApi = createApi({
  reducerPath: "lastDataUpdate",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLastUpdatedDatePolis: builder.query<{ latest: string }, any>({
      query: ({
        salesforceId,
        agentCode,
      }: {
        salesforceId: string | undefined;
        agentCode: string | undefined;
      }) => ({
        url: "/web/policy/findLastUpdate",
        method: "POST",
        body: {
          salesforceId,
          agentCode,
        },
      }),
    }),
    getLastUpdatedDateProposal: builder.query<{ latest: string }, any>({
      query: ({
        salesforceId,
        agentCode,
      }: {
        salesforceId: string | undefined;
        agentCode: string | undefined;
      }) => ({
        url: "/web/proposal/newOds/findLastUpdate",
        method: "POST",
        body: {
          salesforceId,
          agentCode,
        },
      }),
    }),
  }),
});

export const { useGetLastUpdatedDatePolisQuery,useGetLastUpdatedDateProposalQuery } = lastDataUpdateApi;
