import { useSessionStorage } from "usehooks-ts";
import { Login } from "../services/login";

export const AccessMVP2 = () => {
  const [user] = useSessionStorage<Login | undefined>("user", undefined);

  if (user?.isPilotMode) {
    return true;
  }
  return false;
};
