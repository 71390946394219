import { FC } from "react";
import { Bar, Line, Pie, Cell } from "recharts";
import { ChartBar, ChartPie } from "../../../Components/atom/chart";
import { memo, useEffect } from "react";
import { LoaderChart } from "../../../Components/atom/loader-chart";
import moment from "moment";
import BtnRefresh from "../btnRefresh";
import * as analyticConst from "../../../resource/analytic_const";
import { logEvents } from "../../../utils/analytics";
const SAMPLE_DATA = [
  { 2021: 2134, 2022: 4123, name: "Jan", growth: 1324, total: 324 },
  { 2021: 1234, 2022: 432, name: "Feb", growth: 234, total: 1234 },
  { 2021: 324, 2022: 0, name: "Mar", growth: NaN, total: 250 },
  { 2021: 1234, 2022: 234, name: "Apr", growth: NaN, total: 300 },
  { 2021: 234, 2022: 423, name: "May", growth: 234, total: 231 },
  { 2021: 324, 2022: 234, name: "Jun", growth: NaN, total: 0 },
  { 2021: 1234, 2022: 1234, name: "Jul", growth: 342, total: 234 },
  { 2021: 234, 2022: 1423, name: "Aug", growth: NaN, total: 0 },
  { 2021: 2134, 2022: 423, name: "Sep", growth: 243, total: 0 },
  { 2021: 0, 2022: 0, name: "Oct", growth: NaN, total: 0 },
  { 2021: 0, 2022: 0, name: "Nov", growth: NaN, total: 0 },
  { 2021: 0, 2022: 0, name: "Dec", growth: NaN, total: 0 },
];

interface PieInterface {
  name: string;
  value: number;
}
//test
const PIE_SAMPLE_DATA = [
  { name: "AG", value: 123 },
  { name: "AA", value: 456 },
  { name: "MA", value: 789 },
];

export interface ErrorchartProps {
  data?: any;
  loading: boolean;
  title: string;
  isError?: boolean;
  onRefresh: () => void;
}

export const ErrorChart: FC<ErrorchartProps> = memo((props: any) => {
  const { loading, title, isError, onRefresh } = props;
  var idLocale = require("moment/locale/id");
  moment.updateLocale("id", idLocale);
  useEffect(() => {
    if (!loading) {
      logEvents("select_content", {
        error_chart_dashboard: title,
        content_id: analyticConst.ACTION_ERROR_CHART_DASHBOARD,
      });
    }
  }, []);
  if (loading) return <LoaderChart title={title} />;

  return (
    <div className="h-full w-full bg-white rounded-lg p-4 overflow-hidden printable">
      <p className="font-lato text-[18px] font-bold mb-6 pb-6">{title}</p>
      <p className=" text-[11px]"></p>
      <div className="relative w-[100%] h-[100%]">
        <ChartBar data={SAMPLE_DATA} type="nominal">
          <Bar yAxisId="left" dataKey={2021} barSize={15} fill="#FDB3A3" />
          <Bar yAxisId="left" dataKey={2022} barSize={15} fill="#F98174" />

          <Line
            type="monotone"
            dataKey="growth"
            stroke="#374151"
            strokeWidth={2}
            dot={false}
            yAxisId="right"
          />
        </ChartBar>
        <div className="z-[2px] w-[100%] absolute bg-white/[.8] inset-0 flex justify-center  items-center -top-12">
          <div className="text-center -mt-[100px] flex flex-col items-center justify-center gap-3">
            {isError && (
              <div>
                <BtnRefresh onRefresh={onRefresh} />
                <p className="font-lato text-gray-400">Refresh</p>
              </div>
            )}
            <p className="font-lato font-bold text-center text-gray-400 text-lg ">
              {isError
                ? "Maaf, data tidak tersedia. Mohon coba beberapa saat lagi."
                : "Data tidak tersedia."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
type TErrorPieChart = {
  loading: boolean;
  isError: boolean;
  onRefresh: () => void;
};
export const ErrorPieChart = memo((props: TErrorPieChart) => {
  const { loading, isError, onRefresh } = props;
  const colors = ["#ED1B2E", "#F45151", "#F98174", "#FDB3A3"];
  useEffect(() => {
    if (!loading) {
      logEvents("select_content", {
        error_chart_dashboard: "Man Power",
        content_id: analyticConst.ACTION_ERROR_CHART_DASHBOARD,
      });
    }
  }, []);
  if (loading) return <LoaderChart title="Man Power" />;

  return (
    <div className=" w-full h-full bg-white rounded-lg p-4 relative z-1 printable">
      <p className="font-lato text-[18px] font-bold ">Man Power</p>
      <ChartPie data={PIE_SAMPLE_DATA}>
        <Pie
          dataKey="value"
          innerRadius={40}
          outerRadius={80}
          data={PIE_SAMPLE_DATA}
        >
          {PIE_SAMPLE_DATA.map((entry: PieInterface, index: number) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </ChartPie>
      {/* <div className="z-2 w-[100%] h-[80%] absolute bg-white/[.8] inset-0 flex justify-center items-center top-10">
        <p className="font-lato font-bold text-gray-400 text-lg ml-2 pb-8">
          {isError
            ? "Maaf, data tidak tersedia. Mohon coba beberapa saat lagi."
            : "Data tidak tersedia."}
        </p>
      </div> */}
      <div className="z-[2px] w-[100%] absolute bg-white/[.8] inset-0 flex justify-center  items-center">
        <div className="text-center  flex flex-col items-center justify-center gap-3">
          {isError && (
            <div>
              <BtnRefresh onRefresh={onRefresh} />
              <p className="font-lato text-gray-400">Refresh</p>
            </div>
          )}
          <p className="font-lato font-bold text-center text-gray-400 text-lg ">
            {isError
              ? "Maaf, data tidak tersedia. Mohon coba beberapa saat lagi."
              : "Data tidak tersedia."}
          </p>
        </div>
      </div>
    </div>
  );
});
