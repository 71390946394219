import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
type TGetDataPribadi = {
  policyNumber: string;
  salesforceId: string;
  agentCode: string;
  agentNumber: string;
  pageType: string;
};
type TGetDataPribadiResponse = {
  address3: string;
  address2: string;
  address1: string;
  proposalNumber: string;
  homePhone: string;
  policyNumber: string;
  dateOfBirth: string;
  clientNumber: string;
  idNumber: string;
  mobilePhone: string;
  officePhone: string;
  address5: string;
  address4: string;
  policyHolderName: string;
  email: string;
  clientMailAddress?: string;
};

type TGetDataInvestasi = {
  policyNumber: string;
  type: string;
  salesforceId: string;
  agentCode: string;
};
type TGetDataInvestasiResponse = {
  fundType: string;
  unitPrice: number;
  pricingDate2: string;
  pricingDate: string;
  unitBalance: number;
  currencySymbol: string;
  fundName: string;
  unitValue: number;
};

type TGetDataPolis = {
  policyNumber: string;
  type: string;
  salesforceId: string;
  agentCode: string;
  agentNumber: string;
  pageType: string;
};
export type TBeneficiary = {
  beneficiaryRelation: string;
  clientName: string;
  dateOfBirth: string;
};
type TMainLifeAssuredDetails = {
  address3: string;
  address2: string;
  clientName: string;
  address1: string;
  clientStatus: string;
  address5: string;
  address4: string;
  clientNumber: string;
};
type TGetDataPolisResponse = {
  product: string;
  productId: string;
  mainLifeAssuredSmokerStatus: string;
  productHtml: string;
  boosterProteksi: string;
  premiumStatus: string;
  boosterInvestasiExtra: string;
  orphan: boolean;
  mainLifeAssured: string;
  beneficiary: TBeneficiary[];
  mainLifeAssuredClientNumber: string;
  mainLifeAssuredBirthDate: string;
  mainLifeAssuredDetails: TMainLifeAssuredDetails[];
  additionalLifeAssured: any[];
  proposalReceivedDate: string;
  status: string;
  policyDeliveryDate: string;
  policyIssueDate: string;
  premiumDueDate: string;
  policyRiskDate: string;
  originalAgentName: string;
  transferDate: string;
};

type TGetAlokasiDana = {
  policyNumber: string;
  salesforceId: string;
  agentCode: string;
  agentNumber?: string;
  pageType?: string;
};

type TGetAlokasiDanaResponse = {
  fundPercentage: number;
  fundName: string;
  key: string;
};

type TGetInformasiPenerimaanResponse = {
  EPolicyDeliveryDate: string;
  EPolicyReceivedDate: string;
  EPolicyReceiver: string;
  EPolicyReceiverDate: string;
  agentNumber: string;
  benefitBillDate: string;
  billingChannel: string;
  contractDate: string;
  crTable: string;
  email: string;
  endDate: string;
  inceptionDate: string;
  nextPremiumDue: string;
  occurrenceDate: string;
  paidToDate: string;
  paymentOptionCurrency: string;
  paymentOptionFrequency: string;
  policyHolderName: string;
  policyHolderNumber: string;
  policyNumber: string;
  policyStatusCode: string;
  premiumStatusCode: string;
  productCode: string;
  productName: string;
  sourceOfBusiness: string;
  statusSect: string;
  sumAssured: string;
  totalPremium: string;
};
export interface Data {
  agentNumber: string;
  agentCode: string;
  type: string;
  filterBy?: string;
  filterVal?: string;
  sortBy?: string;
  sortDir?: string;
  orderBy?: string;
  orderDir?: string;
  size?: number;
  page: number;
  statusList?: string[];
  salesForceId?: string;
  searchWords: string;
  channelType?: string;
  advSearchDateFrom?: string;
  advSearchDateTo?: string;
  advSearchPolicyHolderName?: string;
  advSearchMainLifeAssured?: string;
  advSearchPolicyNumber?: string;
  advSearchProductName?: string;
  advSearchAgentNumber?: string;
  advSearchAgentName?: string;
  advSearchStatus?: string;
  advSearchAgentType?: string;
  advSearchOrphan?: string;
  advSearchIssuedDateFrom?: string;
  advSearchIssuedDateTo?: string;
  advSearchSellingAgentCode?: string;
  advSearchSellingAgentName?: string;
  syariah?: string;
}
type TPremiResponse = {
  coverageDebt: number;
  suspendAmountPremium: number;
  singlePremium: number;
  payor: string;
  premiumDueDate: string;
  paymentMethod: string;
  currencySymbol: string;
  currency: string;
  paymentFrequency: string;
  instalmentPremium: number;
  suspendAmountForTopUp: number;
};
export type TTotalResponse = {
  total: number;
  [key: string]: any;
};
export type TGetPolisResponse = {
  total: number;
  list: any;
};

export type TSuratMenyurat = {
  clientRoleCode: string;
  policyNumber: string;
  agentCode: string;
};
export const polisApi = createApi({
  reducerPath: "polisApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getDataPribadi: builder.query<TGetDataPribadiResponse, TGetDataPribadi>({
      query: (payload) => ({
        url: "/web/policy/findPersonalData",
        method: "POST",
        body: payload,
      }),
    }),
    getDataPolis: builder.query<TGetDataPolisResponse, TGetDataPolis>({
      query: (payload) => ({
        url: "/web/policy/findPolicyData",
        method: "POST",
        body: payload,
      }),
    }),
    getAlokasiDana: builder.query<TGetAlokasiDanaResponse[], TGetAlokasiDana>({
      query: (payload) => ({
        url: "/web/policy/findFundAllocation",
        method: "POST",
        body: payload,
      }),
    }),
    getAlokasiDanaBasic: builder.query<
      TGetAlokasiDanaResponse[],
      TGetAlokasiDana
    >({
      query: (payload) => ({
        url: "/web/policy/findFundAllocationBasicSaver",
        method: "POST",
        body: payload,
      }),
    }),
    getAlokasiDanaTopUp: builder.query<
      TGetAlokasiDanaResponse[],
      TGetAlokasiDana
    >({
      query: (payload) => ({
        url: "/web/policy/findFundAllocationTopUpSaver",
        method: "POST",
        body: payload,
      }),
    }),
    getAlokasiDanaPRUBooster: builder.query<
      TGetAlokasiDanaResponse[],
      TGetAlokasiDana
    >({
      query: (payload) => ({
        url: "/web/policy/findFundAllocationPRUBoostSaver",
        method: "POST",
        body: payload,
      }),
    }),
    getDataInvestasi: builder.query<
      TGetDataInvestasiResponse[],
      TGetDataInvestasi
    >({
      query: (payload) => ({
        url: "/web/policy/findFundHolding",
        method: "POST",
        body: payload,
      }),
    }),
    getDataInvestasiBasic: builder.query<
      TGetDataInvestasiResponse[],
      TGetDataInvestasi
    >({
      query: (payload) => ({
        url: "/web/policy/findFundHoldingBasicSaver",
        method: "POST",
        body: payload,
      }),
    }),
    getDataInvestasiTopUp: builder.query<
      TGetDataInvestasiResponse[],
      TGetDataInvestasi
    >({
      query: (payload) => ({
        url: "/web/policy/findFundHoldingTopUpSaver",
        method: "POST",
        body: payload,
      }),
    }),
    getDataInvestasiPRUBooster: builder.query<
      TGetDataInvestasiResponse[],
      TGetDataInvestasi
    >({
      query: (payload) => ({
        url: "/web/policy/findFundHoldingPRUBoostSaver",
        method: "POST",
        body: payload,
      }),
    }),
    getInformasiPenerimaan: builder.query<
      TGetInformasiPenerimaanResponse,
      { policyNumber: string; agentCode: string }
    >({
      query: ({ policyNumber, agentCode }) => ({
        url: "/web/policy/getDataEPolicy",
        method: "GET",
        params: { policyNumber, agentCode },
      }),
    }),

    getIndividuPolis: builder.query<TGetPolisResponse, Data>({
      query: (payload) => ({
        url: "/web/policy/webFindPolicyByStatus",
        method: "POST",
        body: payload,
      }),
    }),
    getUnitPolis: builder.query<TGetPolisResponse, Data>({
      query: (payload) => ({
        url: "/web/policy/webFindUnitNew",
        method: "POST",
        body: payload,
      }),
    }),
    getGroupPolis: builder.query<TGetPolisResponse, Data>({
      query: (payload) => ({
        url: "/web/policy/webFindGroup",
        method: "POST",
        body: payload,
      }),
    }),
    getPremi: builder.query<TPremiResponse, TGetDataPolis>({
      query: (payload) => ({
        url: "/web/policy/findPremiumData",
        method: "POST",
        body: payload,
      }),
    }),
    getIndividuTotal: builder.query<TTotalResponse, Data>({
      query: (payload) => ({
        url: "/web/policy/webFindPolicyByStatus",
        method: "POST",
        body: payload,
      }),
    }),
    getUnitTotal: builder.query<TTotalResponse, Data>({
      query: (payload) => ({
        url: "/web/policy/webFindUnitNew",
        method: "POST",
        body: payload,
      }),
    }),
    getGroupTotal: builder.query<TTotalResponse, Data>({
      query: (payload) => ({
        url: "/web/policy/webFindGroup",
        method: "POST",
        body: payload,
      }),
    }),
    getSuratMenyurat: builder.query<any, TSuratMenyurat>({
      query: (payload) => ({
        url: "/web/general/licensechecking/client/address-poscode",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetDataPribadiQuery,
  useGetDataPolisQuery,
  useGetAlokasiDanaQuery,
  useGetAlokasiDanaBasicQuery,
  useGetAlokasiDanaTopUpQuery,
  useGetAlokasiDanaPRUBoosterQuery,
  useGetDataInvestasiQuery,
  useGetDataInvestasiBasicQuery,
  useGetDataInvestasiTopUpQuery,
  useGetDataInvestasiPRUBoosterQuery,
  useGetInformasiPenerimaanQuery,
  useGetIndividuPolisQuery,
  useGetUnitPolisQuery,
  useGetGroupPolisQuery,
  useGetPremiQuery,
  useGetGroupTotalQuery,
  useGetIndividuTotalQuery,
  useGetUnitTotalQuery,
  useGetSuratMenyuratQuery,
} = polisApi;
