import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface LoginRes extends Login {
  oldAuth: Login;
}
export interface Login {
  username: string;
  token: string;
  access_token: string;
  token_type?: null | string;
  expires_in: number;
  agent_agentTypeId: string;
  agent_scope: string;
  agent_needApproval: boolean;
  agent_channelType: string;
  mobileMenu?: MobileMenuEntity[] | null;
  refreshToken?: null;
  roles?: null | string;
  menus?: null | string;
  name?: null | string;
  errorCode: string;
  errorMessage?: null | string;
  result?: null | string;
  recruiter?: null | string;
  agentData: AgentData;
  oldAuth: Login;
}
export interface MobileMenuEntity {
  name: string;
  active: boolean;
  isWidget: boolean;
  isAdmin: boolean;
}
export interface AgentData {
  transactionId: string;
  transactionTime: string;
  aajiCpdCode: string;
  aajiLicenceExpiredDate: string;
  aajiLicenceNumber: string;
  aajiLicenceStartDate: string;
  aajiLicenceType: string;
  aajiTemporary: string;
  aasiCpdCode: string;
  aasiLicenceExpiredDate: string;
  aasiLicenceNumber: string;
  aasiLicenceStartDate: string;
  aasiLicenceType: string;
  aasiTemporary: string;
  activeStatus: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  adoaCode: string;
  adoaName: string;
  adsCode: string;
  adsName: string;
  agentEmailAddress: string;
  agentMobilephone1: string;
  agentMobilephone2: string;
  agentMobilephone3: string;
  agentNumber: string;
  agentType: string;
  appointedDate: string;
  clientName: string;
  clientNumber: string;
  currentYearSanction: number;
  dateOfBirth: string;
  idNumber: string;
  joinDate: string;
  leaderName: string;
  leaderNumber: string;
  leaderOfficeCode: string;
  licenseExpiryDate: string;
  licenseNumber: string;
  marital: string;
  office: string;
  officeCode: string;
  overallSanction: number;
  passedCourse?: string[] | null;
  raddCode: string;
  raddName: string;
  reportingManagerId: string;
  responseCode: string;
  userType: string;
  focusBusiness: string;
  leaderShariaContractDate: string;
  leaderConvContractDate: string;
  shariaContractDate: string;
  convContractDate: string;
  agentAccountName: string;
  agentAccountNumber: string;
  agentBankCode: string;
  agentBankName: string;
  agentBankCodeBABR: string;
  agentBankNameBABR: string;
  channelType: string;
  isAdmin: boolean;
  isEligibleFeedback: boolean;
  kpmList: KpmList[];
}

export interface KpmList {
  officeCd: string;
  officeName: string;
}

export type IGetOfficeRespone = OfficeData[];

export interface OfficeData {
  officeAddr1: string;
  officeAddr2: string;
  officeAddr3: string;
  officeName: string;
  officePhone: string;
  city_code: string;
  province_code: string;
}

export type IGetProvinceResponse = ProvinceData[];
export interface ProvinceData {
  longitude: number;
  latitude: number;
  province_code: string;
  name: string;
}

export type IGetCityResponse = CityData[];
export interface CityData {
  city_code: string;
}

const metaBaseQuery: BaseQueryFn<FetchArgs> = async (
  args,
  api,
  extraOptions
) => {
  const baseResult: any = await fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      return headers;
    },
  })(args, api, extraOptions);

  if (args?.url === "/auth/api/login") {
    const oldAuth = await fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
      prepareHeaders: (headers) => {
        return headers;
      },
    })({ ...args, url: "/oldauth/api/login" }, api, extraOptions);

    if (oldAuth?.data) {
      baseResult.data.oldAuth = oldAuth?.data;
    } else {
      baseResult.error.oldAuth = oldAuth?.error;
    }

    return {
      ...baseResult,
    };
  }

  return {
    ...baseResult,
  };
};

export const login2Api = createApi({
  reducerPath: "login2Api",
  baseQuery: metaBaseQuery,
  endpoints: (builder) => ({
    login2: builder.mutation<LoginRes, { username: string; password: string }>({
      query: ({ username, password }) => ({
        url: "/auth/api/login",
        method: "POST",
        body: {
          username,
          password,
          channel: "agen",
        },
      }),
    }),
  }),
});

export const { useLogin2Mutation } = login2Api;
