import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface IFatcaPayload {
  agentCode: string;
  agentNumber: string;
}
export interface Fatca {
  responseCode: string;
  responseDescription: string;
  id: any;
  agentNumber: string;
  agentName: any;
  approvalDate: any;
  officeCode: any;
  fromDate: any;
  toDate: any;
  leaderCode: any;
  leaderName: any;
  sortBy: any;
  defaultView: any;
  start: any;
  end: any;
  totalRecord: any;
  checkData: string;
  totalSearch: any;
}

export const fatcaApi = createApi({
  reducerPath: "fatcaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      headers.set("Authorization", "");
      if (token) {
        headers.set("Authorization", "Bearer " + token);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    checkAgentApproval: builder.query<Fatca, IFatcaPayload>({
      query: (payload) => ({
        url: "/web/fatca/pru/checkAgentApproval",
        method: "POST",
        body: payload,
      }),
    }),
    addFatcaApproval: builder.query<Fatca, IFatcaPayload>({
      query: (payload) => ({
        url: "/web/fatca/pru/addFatcaApproval",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useCheckAgentApprovalQuery, useAddFatcaApprovalQuery } =
  fatcaApi;
