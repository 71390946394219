import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface HospitalCityInterface {
  hospital_city: string;
}

export interface HospitalInterface {
  hospital_name: string;
  hospital_address1: string;
  hospital_phone1: string;
  [key: string]: any;
}

export interface PayloadSearchHospitalInterface {
  page: number;
  size: number;
  filterCityCode: string;
  search: string;
  isHospitalFriend: string;
}

export const hospitalApi = createApi({
  reducerPath: "hospitalApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getHospitals: builder.query<HospitalInterface[], PayloadSearchHospitalInterface>({
      query: (payload) => ({
        url: "/general/publicInformation/hospital",
        method: "POST",
        body: payload,
      }),
      transformResponse: (rawResult: { hospitalList: HospitalInterface[] }) =>
        rawResult.hospitalList.map((data, i) => ({ ...data, id: i + 1 })),
    }),
    getHospitalCities: builder.query<string[], void>({
      query: () => ({
        url: "/general/publicInformation/hospital/getAllCity",
        method: "POST",
      }),
      transformResponse: (rawResult: HospitalCityInterface[]) => {
        return rawResult.map((d: HospitalCityInterface) => d.hospital_city);
      },
    }),
  }),
});

export const {
  useGetHospitalCitiesQuery,
  useGetHospitalsQuery,
  useLazyGetHospitalCitiesQuery,
  useLazyGetHospitalsQuery,
} = hospitalApi;
