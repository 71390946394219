import { Listbox } from "@headlessui/react";
import React from "react";

export interface OptionsRenderPropArg {
  open: boolean;
}

type IButton = React.FC<{
  children?: React.ReactNode;
  className?: string;
}>;
type IButtonClear = React.FC<{
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;
type IOptions = React.FC<{
  children?:
    | React.ReactNode
    | ((
        bag: OptionsRenderPropArg
      ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>);
}>;
type IOption = React.FC<{
  value: any;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}>;

type ISelect = React.FC<{
  value: any;
  onChange(value: any): void;
  children?: React.ReactNode;
  className?: string;
}> & {
  Button: IButton;
  ButtonClear: IButtonClear;
  ButtonArrow: () => JSX.Element;
  Options: IOptions;
  Option: IOption;
};

const Select: ISelect = ({ onChange, value, children, className }) => {
  return (
    <div className={`relative ${className}`}>
      <Listbox value={value} onChange={onChange}>
        <div
          className={`flex-row flex w-full items-center 
           flex-grow justify-end
            min-h-[45px]
          bg-white py-2  text-left text-sm leading-5 text-gray-900 focus:ring-0 rounded-[8px] border-[1px] border-[#E5E7EB] ${className}`}
        >
          {children}
        </div>
      </Listbox>
    </div>
  );
};

const Button: IButton = ({ children, className }) => (
  <Listbox.Button
    className={`flex flex-row w-full pl-3  absolute justify-between items-center text-ellipsis overflow-hidden whitespace-nowrap ${className}`}
  >
    {children}
  </Listbox.Button>
);

const ButtonClear: IButtonClear = ({ onClick }) => (
  <button className=" mr-2  z-10 " onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="w-5 h-5 "
    >
      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
    </svg>
  </button>
);
const ButtonArrow = () => (
  <button className=" ">
    <div className="mr-2 gap-4 flex-row flex items-center">
      <svg
        className="w-3 h-3"
        viewBox="0 0 12 8"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1.16994C10.8126 0.983692 10.5592 0.87915 10.295 0.87915C10.0308 0.87915 9.77736 0.983692 9.58999 1.16994L5.99999 4.70994L2.45999 1.16994C2.27263 0.983692 2.01918 0.87915 1.75499 0.87915C1.49081 0.87915 1.23736 0.983692 1.04999 1.16994C0.956266 1.26291 0.881872 1.37351 0.831103 1.49537C0.780334 1.61723 0.754196 1.74793 0.754196 1.87994C0.754196 2.01195 0.780334 2.14266 0.831103 2.26452C0.881872 2.38638 0.956266 2.49698 1.04999 2.58994L5.28999 6.82994C5.38296 6.92367 5.49356 6.99806 5.61542 7.04883C5.73728 7.0996 5.86798 7.12574 5.99999 7.12574C6.13201 7.12574 6.26271 7.0996 6.38457 7.04883C6.50643 6.99806 6.61703 6.92367 6.70999 6.82994L11 2.58994C11.0937 2.49698 11.1681 2.38638 11.2189 2.26452C11.2697 2.14266 11.2958 2.01195 11.2958 1.87994C11.2958 1.74793 11.2697 1.61723 11.2189 1.49537C11.1681 1.37351 11.0937 1.26291 11 1.16994Z"
          fill="#6D7280"
        />
      </svg>
    </div>
  </button>
);

const Options: IOptions = ({ children }) => (
  <Listbox.Options className="absolute max-h-72 z-10 top-12 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
    {children}
  </Listbox.Options>
);

const Option: IOption = ({ children, disabled, value, className }) => (
  <Listbox.Option
    className={({ active }) =>
      `${className} relative cursor-default select-none py-2 pl-5 pr-4 ${
        active ? "bg-primary text-white" : "text-gray-900"
      }`
    }
    value={value}
    disabled={disabled}
  >
    {({ selected, active, disabled }) => (
      <>
        <span
          className={`block  pl-5 ${selected ? "font-medium" : "font-normal"} ${
            disabled && "text-gray-200"
          }`}
        >
          {children}
        </span>
        {selected ? (
          <span
            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
              active ? "text-white" : "text-primary"
            } `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        ) : null}
      </>
    )}
  </Listbox.Option>
);

Select.Button = Button;
Select.ButtonClear = ButtonClear;
Select.ButtonArrow = ButtonArrow;
Select.Options = Options;
Select.Option = Option;

export { Select };
