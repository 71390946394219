import React from 'react'

import { ReactComponent as EmptyFileLogo } from '../../../assets/empty-file-icon.svg'

import { EmptyCPDBannerProps } from './types'

const EmptyCPDBanner: React.FC<EmptyCPDBannerProps> = ({ message="No Data" }) => {
    return (
        <div className='flex flex-col justify-center items-center gap-4'>
            <EmptyFileLogo/>
            <p className='font-lato font-bold text-gray-400 text-center'>{message}</p>
        </div>
    )
}

export default EmptyCPDBanner
