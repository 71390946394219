import { Bar, Line } from "recharts";
import { ChartBar } from "../../../Components/atom/chart";
import { CURRENT_YEAR, PREVIOUS_YEAR } from "../../../utils/year";
import { memo, useState, useEffect } from "react";
import { LoaderChart } from "../../../Components/atom/loader-chart";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import moment from "moment";
type TActiveRatio = {
  data: any;
  loading: boolean[];
};
export const ActiveRatioChart = memo((props: TActiveRatio) => {
  const { data, loading } = props;
  const navigate = useNavigate();
  const activeRatio = useConfigActiveRatioHooks(data);
  const [tooltip, setTooltip] = useState(false);
  const handleTooltipClose = () => {
    setTooltip(false);
  };

  const handleTooltipOpen = () => {
    setTooltip(true);
  };
  if (loading[0] || loading[1])
    return (
      <div className="h-full w-full">
        <LoaderChart title="Activity Ratio" />
      </div>
    );

  const date = moment().utcOffset(7).format("LLLL");
  return (
    <div className="h-full w-full bg-white rounded-lg p-4 printable">
      <div className="flex gap-2 items-center">
        <p
          onClick={() =>
            navigate("/mapa", {
              state: {
                type: data[0].params.type,
              },
            })
          }
          className="font-lato text-[18px] font-bold "
        >
          <Tooltip
            title="Klik judul untuk melihat detail"
            placement="top"
            arrow
          >
            <span className="cursor-pointer">Activity Ratio</span>
          </Tooltip>
        </p>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            title="Klik judul untuk melihat detail"
            placement="top"
            arrow
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={tooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <InformationCircleIcon
              onClick={handleTooltipOpen}
              className="w-[18px] h-[20.02px] text-[#4B5563] lg:hidden visible"
            />
          </Tooltip>
        </ClickAwayListener>
      </div>
      <p className="pb-6 mb-6 text-[11px]">Data diperbarui {date} WIB</p>

      <ChartBar
        data={activeRatio}
        type="percentage"
        CustomTooltip={<CustomTooltip />}
      >
        <Bar
          yAxisId="left"
          dataKey={PREVIOUS_YEAR}
          barSize={15}
          fill="#DEAA58"
        />
        <Bar
          yAxisId="left"
          dataKey={CURRENT_YEAR}
          barSize={15}
          fill="#fb5b50"
        />

        <Line
          type="monotone"
          dataKey="growth"
          stroke="#374151"
          strokeWidth={2}
          dot={false}
          yAxisId="right"
        />
      </ChartBar>
    </div>
  );
});

const mockData = [
  { name: "Jan" },
  { name: "Feb" },
  { name: "Mar" },
  { name: "Apr" },
  { name: "May" },
  { name: "Jun" },
  { name: "Jul" },
  { name: "Aug" },
  { name: "Sep" },
  { name: "Oct" },
  { name: "Nov" },
  { name: "Dec" },
];

export const getConfigActiveRatio = (props: any) => {
  if (props[0] && props[1]) {
    return mockData.map((d: any, i: number) => {
      let currentYearData =
        props[0].data[i]?.activityRatio === undefined ||
        props[0].data[i]?.activityRatio === 0
          ? 0.0000000001
          : props[0].data[i]?.activityRatio;
      let lastYearData =
        props[1].data[i]?.activityRatio === undefined ||
        props[1].data[i]?.activityRatio === 0
          ? 0.0000000001
          : props[1].data[i]?.activityRatio;

      let a = currentYearData - lastYearData;
      let total = currentYearData + lastYearData;
      let growth = (a / total) * 100;
      return {
        ...d,
        [CURRENT_YEAR]: props[0].data[i]?.activityRatio,
        [PREVIOUS_YEAR]: props[1].data[i]?.activityRatio,
        growth: growth,
        total: total,
      };
    });
  }
};

const useConfigActiveRatioHooks = (props: any) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props[0] && props[1]) {
      let new_data: any = getConfigActiveRatio(props);

      setData(new_data);
    }
  }, [props]);
  return data;
};

const CustomTooltip = ({ active, payload, label, type }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip shadow-lg"
        style={{ backgroundColor: "white", padding: 10 }}
      >
        <p className="label text-lg">{`${label} `}</p>
        <p className="intro">
          {PREVIOUS_YEAR} :{" "}
          {payload[0].payload[PREVIOUS_YEAR]
            ? payload[0].payload[PREVIOUS_YEAR].toFixed()
            : 0}
        </p>
        <p className="intro">
          {CURRENT_YEAR} :{" "}
          {payload[0].payload[CURRENT_YEAR]
            ? payload[0].payload[CURRENT_YEAR].toFixed()
            : 0}
        </p>
        <p className="intro">
          Total :{" "}
          {payload[0].payload.total ? payload[0].payload.total.toFixed() : 0}
        </p>
        <p className="intro">
          Growth :{" "}
          {payload[0].payload.growth ? payload[0].payload.growth.toFixed() : 0}%
        </p>
      </div>
    );
  }
  return null;
};
