import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../utils/theme";
interface ILoader {
  title?: string;
}
export const LoaderChart: React.FC<ILoader> = ({ title }) => (
  <div className="h-full w-full   bg-white rounded-lg p-4  ">
    {title && (
      <div className="font-lato text-[18px] font-bold ">
        <span className="cursor-pointer">{title}</span>
      </div>
    )}
    <div className="h-full w-full     flex justify-center items-center">
      <ThemeProvider theme={theme}>
        <CircularProgress color="primary" />
      </ThemeProvider>
    </div>
  </div>
);
