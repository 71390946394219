import React from "react";
import { Select } from "../select";
export type TList = {
  id: string;
  name: string;
  label: string;
};
type TSelectFilter = {
  value: TList;
  onChange: (v: TList) => void;
  list_tenaga_pemasar: TList[];
  list_kpm_manager: TList[];
};
export default function SelectFilter(props: TSelectFilter) {
  const { value, onChange, list_tenaga_pemasar, list_kpm_manager } = props;
  return (
    <div>
      <Select value={value} onChange={onChange}>
        <Select.Button>{value.label}</Select.Button>
        <Select.ButtonArrow />
        <Select.Options>
          {list_tenaga_pemasar.length ? (
            <p className="font-bold ml-3 py-3">Tenaga Pemasar</p>
          ) : null}
          {list_tenaga_pemasar.map((filter) => (
            <Select.Option key={filter.label} disabled={false} value={filter}>
              {filter.label}
            </Select.Option>
          ))}
          {list_kpm_manager.length ? (
            <p className="font-bold ml-3 py-3">KPM Manager</p>
          ) : null}
          {list_kpm_manager.map((filter) => (
            <Select.Option key={filter.label} disabled={false} value={filter}>
              {filter.label}
            </Select.Option>
          ))}
        </Select.Options>
      </Select>
    </div>
  );
}
