import React from "react";

interface ILicenseCard {
  image?: string;
  licenseType: "AAJI" | "AASI";
  variant: "gray";
  licenseCode?: string;
  endDate?: string;
  CPDCode?: string;
  children?: string;
}

const variants = {
  gray: {
    gradient: "bg-gradient-to-b from-[#F4F4F4] to-[#D6D6D6]",
    textColor: `text-[#424242]`,
    borderColor: `border-[#D6D6D6]`,
  },
};

export const NoLicenseCard: React.FC<ILicenseCard> = ({
  variant,
  licenseType,
  image,
  children
}) => {
  return (
    <div className={`w-full ${variants[variant].gradient} rounded-md p-5 h-[130px] md:h-[210px]`}>
      <div className="flex flex-row justify-between items-center">
        <p
          className={
            `font-bold font-lato text-[70%] md:text-[90%] lg:text-lg ` + variants[variant].textColor
          }
        >
          Lisensi {licenseType}
        </p>
        <img src={image} className="w-[20%] grayscale" alt="aaji-logo" />
      </div>

      <div className={`border-b-2 ${variants[variant].borderColor} mt-2 md:mt-4`} />

      <div className="mt-2 md:mt-5 flex items-center">
        <p className="font-lato text-center py-1 lg:py-4 text-[11px] md:text-[100%] text-[#424242]">{children}</p>
      </div>
    </div>
  );
};
