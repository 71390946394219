import { useState, ReactNode, FC, useEffect } from "react";
import { ReactComponent as Home } from "../../assets/u_home.svg";
import { ReactComponent as RightArrow } from "../../assets/u_rightarrow.svg";
import { ReactComponent as Document } from "../../assets/document-search.svg";
import { useNavigate, useParams } from "react-router-dom";
import ProfileOrange from "../../assets/profile-orange.png";
import AccessOrange from "../../assets/access-orange.png";
import ToggleData from "./toggleData";
import { useGetSecretaryDetailQuery } from "../../services/secretary";
import { Skeleton } from "@mui/material";
import * as analyticConst from "../../resource/analytic_const";
import { logEvents } from "../../utils/analytics";
import { useSessionStorage } from "usehooks-ts";
import { Login } from "../../services/login";

import { ReactComponent as Check } from "../../assets/check-2.svg";
import { ReactComponent as Close } from "../../assets/close.svg";
export function convertBase64ToBlob(base64: string) {
  // Split into two parts
  const parts = base64.split(";base64,");

  // Hold the content type
  const imageType = parts[0].split(":")[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
}

const DetailSekretaris = () => {
  let { id } = useParams();
  const [user] = useSessionStorage<Login | undefined>("user", undefined);

  const { data: secretaryData, isLoading } = useGetSecretaryDetailQuery({
    pruforceId: id as string,
    agentCode: user?.agentData.agentNumber || "",
  });

  const [toggle, setToggle] = useState(
    secretaryData?.status === "1" ? true : false
  );
  useEffect(() => {
    logEvents("page_view", {
      content_id: analyticConst.PAGE_DETAIL_SECRETARY,
    });
  }, []);

  return (
    <>
      <BreadCrumbs />
      <div className="flex flex-col justify-between lg:flex-row gap-6 mb-2 lg:mb-4 h-[48px] lg:items-center">
        <h1 className="font-lato text-[24px] font-extrabold text-[#1F2937] ">
          Rincian Sekretaris/Admin
        </h1>
      </div>

      <div className="lg:w-[100%] h-full bg-white rounded-lg p-6 flex flex-col justify-between mb-4 gap-4 ">
        <div className="flex lg:flex-row justify-between flex-col">
          <div className="flex gap-[24px] lg:flex-row flex-col lg:items-start items-center">
            <Loaders
              isLoading={isLoading}
              variant="circular"
              width={100}
              height={100}
            >
              <img
                src={secretaryData?.profilePicPath}
                className={`w-[100px] h-[100px] rounded-full ${
                  secretaryData?.status !== "1" ? "grayscale blur-[2px]" : ""
                }`}
                alt=""
              />
            </Loaders>
            <div className="text-left md:text-center lg:text-left mb-4 lg:mb-0 self-start md:self-center">
              <h1 className="font-lato text-[20px] font-bold mb-[13px]">
                <Loaders isLoading={isLoading}>
                  {secretaryData?.secretariesName}
                </Loaders>
              </h1>
              <div className="flex gap-[18px] mb-[16px] flex-col md:flex-row">
                <div className="flex gap-[2px] flex-col">
                  <p className="font-lato text-[12px] text-[#9CA3AF]">
                    Nomor Telepon
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <Loaders isLoading={isLoading}>
                      <p className="font-lato text-[14px] font-bold">
                        {secretaryData?.phoneNumber}
                      </p>
                    </Loaders>
                  </div>
                </div>
                <div className="flex gap-[2px] flex-col">
                  <p className="font-lato text-[12px] text-[#9CA3AF]">Email</p>
                  <div className="flex flex-row gap-2 items-center">
                    <Loaders isLoading={isLoading}>
                      <p className="font-lato text-[14px] font-bold">
                        {secretaryData?.emailAddress}
                      </p>
                    </Loaders>
                  </div>
                </div>
                <div className="flex gap-[2px] flex-col">
                  <p className="font-lato text-[12px] text-[#9CA3AF]">
                    Nomor KTP
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <Loaders isLoading={isLoading}>
                      <p className="font-lato text-[14px] font-bold">
                        {secretaryData?.idNumber}
                      </p>
                    </Loaders>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 items-center lg:items-start">
                <span className="flex items-center flex-row gap-2">
                  <div className="flex gap-2 items-center w-full">
                    <p
                      className={`text-[14px] ${
                        secretaryData?.status === "1"
                          ? "text-gray-750"
                          : "text-[#9CA3AF]"
                      } font-weight-700`}
                    >
                      {secretaryData?.status === "1" ? "Aktif" : "Tidak Aktif"}
                    </p>
                    {secretaryData?.status === "1" ? (
                      <Check className="text-green-500" width={30} />
                    ) : (
                      <Close className="text-red-500" width={30} />
                    )}
                  </div>
                  {/* <Toggle
                    checked={secretaryData?.status === "1"}
                    disabled={true}
                  >
                    <Toggle.Label
                      className={`text-[14px] ${
                        secretaryData?.status === "1"
                          ? "text-gray-750"
                          : "text-[#9CA3AF]"
                      } font-weight-700`}
                    >
                      {secretaryData?.status === "1" ? "Aktif" : "Tidak Aktif"}
                    </Toggle.Label>
                  </Toggle> */}
                </span>
                {secretaryData?.status !== "1" && (
                  <p className="font-lato text-[12px] text-[#9CA3AF]">
                    Tidak diaktifkan oleh leader
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex gap-[14px]">
              <img
                src={ProfileOrange}
                alt="Profile Orange"
                className="w-[40px] h-[40px]"
              />
              <div>
                <p className="font-lato text-[12px] text-[#9CA3AF] mb-[2px]">
                  PRUForce ID
                </p>
                <Loaders isLoading={isLoading}>
                  <p className="font-lato text-[14px] font-bold">
                    {secretaryData?.pruforceId}
                  </p>
                </Loaders>
              </div>
            </div>
            <div className="flex gap-[14px]">
              <img
                src={AccessOrange}
                alt="Profile Orange"
                className="w-[40px] h-[40px]"
              />
              <div>
                <p className="font-lato text-[12px] text-[#9CA3AF] mb-[2px]">
                  Jenis Akses
                </p>
                <Loaders isLoading={isLoading}>
                  <p className="font-lato text-[14px]  font-bold">
                    {secretaryData?.secretariesType}
                  </p>
                </Loaders>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-[#E5E7EB] h-[1px]" />
        <div className="w-full flex flex-col md:flex-row justify-end items-start gap-2">
          <div className="flex flex-col md:flex-row gap-4 h-[80%]">
            <button
              onClick={() => {
                const blobUrl = URL.createObjectURL(
                  convertBase64ToBlob(secretaryData?.idNumberPath || "")
                );

                window.open(blobUrl, "_blank");
              }}
              className="flex flex-row gap-2 justify-center items-center bg-[#F3F4F6] h-[40px] w-[200px] rounded-md hover:bg-gray-200"
            >
              <Document />
              <p className="font-lato font-bold text-[14px]">
                Lihat Lampiran KTP
              </p>
            </button>
            <button
              onClick={() => {
                const blobUrl = URL.createObjectURL(
                  convertBase64ToBlob(secretaryData?.contractPath || "")
                );

                window.open(blobUrl, "_blank");
              }}
              className="flex flex-row gap-2 justify-center items-center bg-[#F3F4F6] h-[40px] w-[200px] rounded-md hover:bg-gray-200"
            >
              <Document />
              <p className="font-lato font-bold text-[14px]">
                Lihat Kontrak Kerja
              </p>
            </button>
          </div>
        </div>
      </div>

      <ToggleData toggle={toggle} setToggle={setToggle} />
    </>
  );
};

export const Loaders: FC<{
  children: ReactNode;
  variant?: "text" | "rectangular" | "rounded" | "circular" | undefined;
  width?: string | number | undefined;
  height?: string | number | undefined;
  isLoading: boolean;
}> = ({ children, variant, height, width, isLoading }) => {
  return isLoading ? (
    <Skeleton
      variant={variant}
      width={width}
      height={height}
      style={{ minWidth: 60 }}
    />
  ) : (
    <>{children}</>
  );
};

const BreadCrumbs = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row gap-3 mb-3 items-center">
      <Home className="cursor-pointer" onClick={() => navigate("/")} />
      <RightArrow />
      <p
        className="font-lato text-primary cursor-pointer"
        onClick={() => navigate("/secretary/")}
      >
        Daftar Sekretaris/Admin
      </p>
      <RightArrow />
      <p className="font-lato text-[#9DA3AA]">Detail</p>
    </div>
  );
};

export default DetailSekretaris;
