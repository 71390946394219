import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface Datum {
  id: string;
  type: string;
  istilah: string;
  definisi: string;
  penyebab: string;
  komplikasi: string;
  istilahLain: string;
  searchByAlphabet: string;
}

export interface Data {
  data: Datum[];
  totalRow: number;
}

export interface Dictionary {
  code: string;
  message: string;
  data: Data;
}

export const dictionaryApi = createApi({
  reducerPath: "dictionaryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      headers.set("Authorization", "");
      if (token) {
        headers.set("Authorization", "Bearer " + token);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    searchDictionary: builder.query<
      Dictionary | any,
      { istilah: string; searchByAlphabet: string; type: string }
    >({
      query: ({ istilah, searchByAlphabet, type }) => ({
        url: "/web/meto/dictionaries/v1/dictionary/findBy",
        method: "POST",
        body: {
          data: {
            istilah,
            searchByAlphabet,
            type,
          },
        },
      }),
    }),
  }),
});

export const { useSearchDictionaryQuery } = dictionaryApi;
