import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type TInitialState = {
  unauthorize_modal: boolean;
};

const initialState: TInitialState = {
  unauthorize_modal: false,
};

const AppSlice = createSlice({
  name: "AppSlice",
  initialState,
  reducers: {
    setUnAuthorizeModal: (state, action: PayloadAction<boolean>) => {
      state.unauthorize_modal = action.payload;
    },
  },
});

export const { setUnAuthorizeModal } = AppSlice.actions;

export default AppSlice.reducer;
