import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface KantorKeagenanState {
  showTable: boolean;
  payload: {
    filterCityCode: string;
    filterProvinceCode: string;
    search: string;
  };
}

const initialState: KantorKeagenanState = {
  showTable: false,
  payload: {
    filterCityCode: "",
    filterProvinceCode: "",
    search: "",
  },
};

export const kantorKeagenanSlice = createSlice({
  name: "kantorKeagenan",
  initialState,
  reducers: {
    showTable: (state) => {
      state.showTable = true;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.payload.filterCityCode = action.payload;
    },
    setProvinceCode: (state, action: PayloadAction<string>) => {
      state.payload.filterProvinceCode = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.payload.search = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showTable, setCity, setProvinceCode, setSearch } = kantorKeagenanSlice.actions;

export default kantorKeagenanSlice.reducer;
