import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
type TGetDataPribadi = {
  policyNumber: string;
  salesforceId: string;
  agentCode: string;
  agentNumber: string;
  pageType: string;
};
type TGetDataPribadiResponse = {
  address3: string;
  address2: string;
  address1: string;
  clientMailAddress: string;
  proposalNumber: string;
  homePhone: string;
  policyNumber: string;
  dateOfBirth: string;
  clientNumber: string;
  idNumber: string;
  mobilePhone: string;
  officePhone: string;
  address5: string;
  address4: string;
  policyHolderName: string;
  email: string;
};

type TGetDataPolis = {
  policyNumber: string;
  type: string;
  salesforceId: string;
  agentCode: string;
  agentNumber: string;
  pageType: string;
};
export type TBeneficiary = {
  beneficiaryRelation: string;
  clientName: string;
  dateOfBirth: string;
};
type TMainLifeAssuredDetails = {
  address3: string;
  address2: string;
  clientName: string;
  address1: string;
  clientStatus: string;
  address5: string;
  address4: string;
  clientNumber: string;
};
type TGetDataPolisResponse = {
  product: string;
  productId: string;
  mainLifeAssuredSmokerStatus: string;
  productHtml: string;
  boosterProteksi: string;
  premiumStatus: string;
  boosterInvestasiExtra: string;
  orphan: boolean;
  mainLifeAssured: string;
  beneficiary: TBeneficiary[];
  mainLifeAssuredClientNumber: string;
  mainLifeAssuredBirthDate: string;
  mainLifeAssuredDetails: TMainLifeAssuredDetails[];
  additionalLifeAssured: any[];
  proposalReceivedDate: string;
  status: string;
};

type TGetAlokasiDana = {
  policyNumber: string;
  salesforceId: string;
  agentCode: string;
  agentNumber?: string;
  pageType?: string;
};

type TGetAlokasiDanaResponse = {
  fundPercentage: number;
  fundName: string;
  key: string;
};

type TGetInformasiPenerimaanResponse = {
  EPolicyDeliveryDate: string;
  EPolicyReceivedDate: string;
  EPolicyReceiver: string;
  EPolicyReceiverDate: string;
  agentNumber: string;
  benefitBillDate: string;
  billingChannel: string;
  contractDate: string;
  crTable: string;
  email: string;
  endDate: string;
  inceptionDate: string;
  nextPremiumDue: string;
  occurrenceDate: string;
  paidToDate: string;
  paymentOptionCurrency: string;
  paymentOptionFrequency: string;
  policyHolderName: string;
  policyHolderNumber: string;
  policyNumber: string;
  policyStatusCode: string;
  premiumStatusCode: string;
  productCode: string;
  productName: string;
  sourceOfBusiness: string;
  statusSect: string;
  sumAssured: string;
  totalPremium: string;
};

export interface Proposal {
  data: Data[];
  column: Column;
  length: number;
  map: (v: any) => any;
  filter: Filter[];
}

export interface Data {
  agentNumber: string;
  agentCode: string;
  type: string;
  filterBy?: string;
  filterVal?: string;
  sortBy?: string;
  sortDir?: string;
  orderBy?: string;
  orderDir?: string;
  size?: number;
  page: number;
  statusList?: string[];
  salesForceId?: string;
  searchWords: string;
  channelType?: string;
  advSearchDateFrom?: string;
  advSearchDateTo?: string;
  advSearchPolicyHolderName?: string;
  advSearchMainLifeAssured?: string;
  advSearchPolicyNumber?: string;
  advSearchProductName?: string;
  advSearchAgentNumber?: string;
  advSearchAgentName?: string;
  advSearchStatus?: string;
  advSearchAgentType?: string;
  advSearchOrphan?: string;
  advSearchIssuedDateFrom?: string;
  advSearchIssuedDateTo?: string;
  advSearchSellingAgentCode?: string;
  advSearchSellingAgentName?: string;
  syariah?: string;
}

export interface Filter {
  agentCode: string;
  agentNumber: string;
  type: string;
  channelType?: string;
  salesforceId?: string;
  statusList?: string[];
}

export interface Column {
  rows: string;
}

type TPremiResponse = {
  coverageDebt: number;
  suspendAmountPremium: number;
  singlePremium: number;
  payor: string;
  premiumDueDate: string;
  paymentMethod: string;
  currencySymbol: string;
  currency: string;
  paymentFrequency: string;
  instalmentPremium: number;
  suspendAmountForTopUp: number;
};

export type TTotalResponse = {
  total: number;
  [key: string]: any;
};

export type TGetProposalResponse = {
  total: number;
  list: any;
};
export const proposalApi = createApi({
  reducerPath: "proposalApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getDataPribadi: builder.query<TGetDataPribadiResponse, TGetDataPribadi>({
      query: (payload) => ({
        url: "/web/proposal/newOds/findPersonalData",
        method: "POST",
        body: payload,
      }),
    }),
    getDataPolis: builder.query<TGetDataPolisResponse, TGetDataPolis>({
      query: (payload) => ({
        url: "/web/proposal/newOds/findPolicyData",
        method: "POST",
        body: payload,
      }),
    }),
    getIndividuProposal: builder.query<TGetProposalResponse, Data>({
      query: (payload) => ({
        url: "/web/proposal/web/findPolicyByStatus",
        method: "POST",
        body: payload,
      }),
    }),
    getUnitProposal: builder.query<TGetProposalResponse, Data>({
      query: (payload) => ({
        url: "/web/proposal/web/findUnitNew",
        method: "POST",
        body: payload,
      }),
    }),
    getUnitProposalFilter: builder.query<Data[], Filter>({
      query: (payload) => ({
        url: "/web/proposal/newOds/findFilterUnitNew",
        method: "POST",
        body: payload,
      }),
    }),
    getGroupProposal: builder.query<TGetProposalResponse, Data>({
      query: (payload) => ({
        url: "/web/proposal/web/findGroup",
        method: "POST",
        body: payload,
      }),
    }),
    getGroupProposalFilter: builder.query<Data[], Filter>({
      query: (payload) => ({
        url: "/web/proposal/newOds/findFilterGroup",
        method: "POST",
        body: payload,
      }),
    }),
    getPremi: builder.query<TPremiResponse, TGetDataPolis>({
      query: (payload) => ({
        url: "/web/proposal/newOds/findPremiumData",
        method: "POST",
        body: payload,
      }),
    }),
    getAlokasiDana: builder.query<TGetAlokasiDanaResponse[], TGetAlokasiDana>({
      query: (payload) => ({
        url: "/web/proposal/newOds/findFundAllocation",
        method: "POST",
        body: payload,
      }),
    }),
    getAlokasiDanaBasic: builder.query<
      TGetAlokasiDanaResponse[],
      TGetAlokasiDana
    >({
      query: (payload) => ({
        url: "/web/proposal/newOds/findFundAllocationBasicSaver",
        method: "POST",
        body: payload,
      }),
    }),
    getAlokasiDanaTopUp: builder.query<
      TGetAlokasiDanaResponse[],
      TGetAlokasiDana
    >({
      query: (payload) => ({
        url: "/web/proposal/newOds/findFundAllocationTopUpSaver",
        method: "POST",
        body: payload,
      }),
    }),
    getAlokasiDanaPRUBooster: builder.query<
      TGetAlokasiDanaResponse[],
      TGetAlokasiDana
    >({
      query: (payload) => ({
        url: "/web/proposal/newOds/findFundAllocationPRUBoostSaver",
        method: "POST",
        body: payload,
      }),
    }),
    getInformasiPenerimaan: builder.query<
      TGetInformasiPenerimaanResponse,
      { policyNumber: string }
    >({
      query: ({ policyNumber }) => ({
        url: "/web/proposal/epolicy/getDataEPolicy",
        method: "GET",
        params: { policyNumber },
      }),
    }),
    getIndividuTotal: builder.query<TTotalResponse, Data>({
      query: (payload) => ({
        url: "/web/proposal/web/findPolicyByStatus",
        method: "POST",
        body: payload,
      }),
    }),
    getUnitTotal: builder.query<TTotalResponse, Data>({
      query: (payload) => ({
        url: "/web/proposal/web/findUnitNew",
        method: "POST",
        body: payload,
      }),
    }),
    getGroupTotal: builder.query<TTotalResponse, Data>({
      query: (payload) => ({
        url: "/web/proposal/web/findGroup",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetIndividuProposalQuery,
  useGetUnitProposalQuery,
  useGetUnitProposalFilterQuery,
  useGetGroupProposalQuery,
  useGetGroupProposalFilterQuery,
  useGetDataPribadiQuery,
  useGetDataPolisQuery,
  useGetPremiQuery,
  useGetAlokasiDanaQuery,
  useGetAlokasiDanaBasicQuery,
  useGetAlokasiDanaTopUpQuery,
  useGetAlokasiDanaPRUBoosterQuery,
  useGetInformasiPenerimaanQuery,
  useGetIndividuTotalQuery,
  useGetUnitTotalQuery,
  useGetGroupTotalQuery,
} = proposalApi;
