import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AgentData } from "./login";
import { PayloadMyTeamDetailInterface } from "./myteam";

export const myteamJbossApi = createApi({
  reducerPath: "myteamJbossApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token) {
        headers.set("Authorization", "Bearer " + token);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMyTeamDetail: builder.mutation<AgentData, PayloadMyTeamDetailInterface>({
      query: (payload) => ({
        url: "/agentprofile/old/findByAgentNumber",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetMyTeamDetailMutation } = myteamJbossApi;
