import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface NewsListResponse {
  id: number;
  title: string;
  start_publish_date: string;
  end_publish_date: string;
  description: string;
  news_type_id: string;
  agent_type: string;
  image_name: string;
  file_name: string;
  type_name: string;
  type_file: string;
  newsTypeIcon: string;
}

export interface NewsListPayload {
  page: number;
  max: number;
  agentNumber: string;
  searchBy: string;
  filterByCategory: string;
  sortBy: string;
  agentType?: string;
  salesforceId?: string;
}

export interface NewsTypeContent {
  createdBy: string;
  createdDate: Date;
  description: string;
  id: string;
  typeFile: string;
  typeIcon: string;
  typeName: string;
  typeSrc: number;
  updatedBy: string;
}

export interface NewsTypeResponse {
  content: NewsTypeContent[];
  message: string;
  status: number;
}

export interface NewsType {
  agentNumber: string,
  agentType: string
}

export interface CampaignResponse {
  id: number;
  bannerTypeId?: any;
  description: string;
  periodDateFrom: string;
  periodDateTo: string;
  agentType: string;
  campaignType?: any;
  imageName: string;
  title: string;
  start?: any;
  end?: any;
  salesforceId?: any;
  agentNumber?: any;
  channelType?: any;
  isType: boolean;
  priority: number;
  campaignStatus?: any;
  allClient: boolean;
  filteredExistingClient: boolean;
  newClient: boolean;
  campaignCode?: any;
}

export interface CampaignPayload {
  salesforceId?: string;
  agentNumber: string;
  channelType: string;
  agentType: string;
  start: number;
  end: number;
  campaignCode: string;
}

export interface FileBase64Response {
  message: string;
  status: number;
  content: string[];
}

export interface FileBase64 {
  fileName: string;
  module: string;
}

export interface FileBase64CampaignResponse {
  imageName: string;
  imageBase64: string;
}

export interface FileBase64Campaign {
  imageName: string;
}

export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCampaign: builder.query<CampaignResponse[], CampaignPayload>({
      query: (payload) => ({ 
        url: "/web/resource/campaign/getDataBannerCampaign",
        method: "POST",
        body: payload,
      }),
    }),
    getNewsType: builder.query<NewsTypeResponse, NewsType>({
      query: (payload) => ({
        url: "/web/newsUpdate/getNewsTypeFilter",
        method: "POST",
        body: payload,
      }),
    }),
    getNewsList: builder.query<NewsListResponse | any, NewsListPayload>({
      query: (payload) => ({
        url: "/web/newsUpdate/newsListMobile",
        method: "POST",
        body: payload,
      }),
    }),
    getFileBase64: builder.query<FileBase64Response, FileBase64>({
      query: (payload) => ({
        url: "/web/base/commonFile/getFileBase64",
        method: "POST",
        body: payload,
      }),
    }),
    getCampaignFileBase64: builder.query<FileBase64CampaignResponse[], FileBase64Campaign>({
      query: (payload) => ({
        url: "/web/resource/campaign/getFile",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { 
  useGetCampaignQuery, 
  useGetNewsListQuery, 
  useLazyGetNewsListQuery, 
  useGetFileBase64Query, 
  useLazyGetFileBase64Query,
  useGetNewsTypeQuery,
  useGetCampaignFileBase64Query,
  useLazyGetCampaignFileBase64Query,
  useLazyGetCampaignQuery
} = newsApi;
