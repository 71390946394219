import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
export interface IResponse {
  data: Data[];
  params: Params;
  status: Status;
}

export interface Data {
  dataTotal: number;
  agentType: string;
  netApe: number;
  netCase: number;
  activeAgent: boolean;
  agentName: string;
  agentNumber: string;
}

export interface IMapaAgent {
  data: ListAgentMapa[];
  params: Params;
  status: Status;
}

export interface ListAgentMapa {
  dataTotal: number;
  agentType: string;
  netApe: number;
  netCase: number;
  activeAgent: boolean;
  agentName: string;
  agentNumber: string;
}

export interface Params {
  dataTotal: number;
  period: string;
  perPage: number;
  sortType: string;
  totalPage: string;
  sortBy: string;
  agentNumber: string;
  searchKey: string;
  page: number;
  type: string;
  filterBy: any;
}

export interface Status {
  code: number;
  message: string;
  shortCode: string;
}

export interface ISummaryMapa {
  data: SummaryMapaData;
  params: ParamsSummaryMapa;
  status: StatusSummaryMapa;
}

export interface SummaryMapaData {
  noLicensed: number;
  activityRatio: number;
  licensed: number;
  caseApe: number;
  avgActiveAgent: number;
  caseSize: number;
  manPower: number;
  activeAgent: number;
  caseAgent: number;
  ape: number;
  casePerActiveAgent: number;
  avgManpower?: number;
}

export interface ParamsSummaryMapa {
  period: string;
  periodType: string;
  agentNumber: string;
  type: string;
}

export interface StatusSummaryMapa {
  code: number;
  message: string;
  shortCode: string;
}

export interface IMapaSummary {
  type: string;
  period: string;
  agentNumber: string;
  periodType: string;
}

export const mapaApi = createApi({
  reducerPath: "mapaApi",
  tagTypes: ["MANPOWER", "ACTIVITY_RATIO", "ACTIVE_AGENT"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders(headers) {
      // console.log(token);
      // headers.set("Authorization", "Bearer " + token?.access_token);
      // headers.set("Authorization", dummyAuth);
      const token = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token) {
        // headers.set("Authorization", "Bearer " + token);
        headers.set("Authorization", token);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
    // mode: "no-cors",
  }),
  endpoints: (builder) => ({
    getMapa: builder.query<
      IMapaAgent,
      {
        type: string;
        perPage: number;
        page: number;
        sortBy: string;
        sortType: string;
        agentNumber: string;
        searchKey: string;
        period: string;
        periodType: string;
      }
    >({
      query: ({
        type,
        perPage,
        page,
        sortBy,
        sortType,
        agentNumber,
        searchKey,
        period,
        periodType,
      }) => ({
        url: "/mapa-dashboard/dashboard/agent/list",
        method: "GET",
        params: {
          type,
          perPage,
          page,
          sortBy,
          sortType,
          agentNumber,
          searchKey,
          period,
          periodType,
        },
      }),

      transformResponse: (res: IMapaAgent, meta, arg) => {
        const data = res.data.map((data, i) => ({
          ...data,
          id: i + (arg.page - 1) * arg.perPage,
        }));
        res.data = data;
        return res;
      },
    }),
    getActiveAgent: builder.query<
      IResponse,
      {
        type: string;
        period: string | number;
        agentNumber: string;
        periodType: string;
      }
    >({
      query: ({ type, period, agentNumber, periodType }) => ({
        url: "/mapa-dashboard/dashboard/productivity/detail",
        method: "GET",
        params: { type, period, agentNumber, periodType },
      }),
      providesTags: ["ACTIVE_AGENT"],
    }),
    getActiveRatio: builder.query<
      IResponse,
      {
        type: string;
        period: string | number;
        agentNumber: string;
        periodType: string;
      }
    >({
      query: ({ type, period, agentNumber, periodType }) => ({
        url: "/mapa-dashboard/dashboard/manpower/detail",
        method: "GET",
        params: { type, period, agentNumber, periodType },
      }),
      providesTags: ["ACTIVITY_RATIO"],
    }),
    getManPower: builder.query<
      IResponse,
      {
        type: string;
        period: string | number;
        agentNumber: string;
        periodType: string;
      }
    >({
      query: ({ type, period, agentNumber, periodType }) => ({
        url: "/mapa-dashboard/dashboard/manpower/agent-type",
        method: "GET",
        params: { type, period, agentNumber, periodType },
      }),
      providesTags: ["MANPOWER"],
    }),
    getMapaSummary: builder.query<ISummaryMapa, IMapaSummary>({
      query: ({ type, agentNumber, period, periodType }) => ({
        url: "/mapa-dashboard/dashboard/list",
        method: "GET",
        params: {
          type,
          periodType,
          period,
          agentNumber,
        },
      }),
    }),
  }),
});

export const {
  useGetMapaQuery,
  useLazyGetMapaQuery,
  useGetActiveAgentQuery,
  useGetActiveRatioQuery,
  useGetManPowerQuery,
  useGetMapaSummaryQuery,
} = mapaApi;
