import React from "react";
import { ReactComponent as PrudentialLogo } from "../../assets/prudential-logo.svg";
import { ReactComponent as PruforceLogo } from "../../assets/pruforce.svg";
const NotFound = () => {
  return (
    <div
      className={`w-full h-screen flex justify-center items-center flex-col gap-4`}
    >
      <div className="flex justify-center items-center gap-4">
        <PrudentialLogo />
        <PruforceLogo />
      </div>
      <div className="flex justify-center text-center items-center ">
        <p className="font-lato text-gray-700 font-bold">
          Portal Informasi dan Laporan Tenaga Pemasar
        </p>
      </div>
      <h1 className="text-3xl">404 Not Found</h1>
    </div>
  );
};
export default NotFound;
