import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { CPDElement } from "./types";

export const CPDApi = createApi({
  reducerPath: "CPDApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      headers.set("Authorization", "");
      if (token) {
        headers.set("Authorization", "Bearer " + token);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getAllCPD: builder.query<CPDElement[], any>({
      query: ({ agentCode }) => ({
        url: "/web/lms/gateway/v2/lms-cpd/agent-cpd/search",
        method: "POST",
        body: {
          data: {
            agentCode,
          },
          page: 1,
          size: 99999,
        },
      }),
      transformResponse: (rawResult: { data: { data: CPDElement[] } }) =>
        rawResult.data.data,
    }),
  }),
});

export const { useGetAllCPDQuery } = CPDApi;
