import { Bar, Line } from "recharts";
import { ChartBar } from "../../../Components/atom/chart";
import { CURRENT_YEAR, PREVIOUS_YEAR } from "../../../utils/year";
import { memo, useState, useEffect } from "react";
import { LoaderChart } from "../../../Components/atom/loader-chart";
import { Tooltip } from "@mui/material";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import moment from "moment";
import { Link } from "react-router-dom";
import currencyFormatter from "../../../utils/currencyFormatter";
import { AccessMVP2 } from "../../../utils/whitelist";

type TNetApe = {
  [key: string]: number;
};
type TData = {
  currentYearNetAPEDatas: TNetApe;
  lastYearNetAPEDatas: TNetApe;
  [key: string]: any;
};
type TProductionChart = {
  data: TData;
  loading: boolean;
};
export const ProductionChart = memo((props: TProductionChart) => {
  const { data, loading } = props;

  const production = useConfigBarProductionHooks(data);
  const [tooltip, setTooltip] = useState(false);
  const accessMVP2 = AccessMVP2();

  const handleTooltipClose = () => {
    setTooltip(false);
  };

  const handleTooltipOpen = () => {
    setTooltip(true);
  };
  if (loading)
    return (
      <div className="w-full h-full">
        <LoaderChart title="Produksi" />
      </div>
    );
  var idLocale = require("moment/locale/id");
  moment.updateLocale("id", idLocale);
  var retrieveDate = new Date(data.last_update);
  let date = moment(retrieveDate).format("LLLL");
  return (
    <div className="h-full w-full  bg-white rounded-lg p-4 pb-8 printable">
      <div className="flex gap-2 items-center  ">
        <div className="font-lato text-[18px] font-bold ">
          {accessMVP2 ? (
            <Tooltip
              title="Klik judul untuk melihat detail"
              placement="top"
              arrow
            >
              <Link className="cursor-pointer" to="/produksi">
                Produksi
              </Link>
            </Tooltip>
          ) : (
            <Tooltip title="Segera Hadir" placement="top" arrow>
              <Link className="cursor-pointer" to="#">
                Produksi
              </Link>
            </Tooltip>
          )}
        </div>

        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            title={
              accessMVP2 ? "Klik judul untuk melihat detail" : "Segera Hadir"
            }
            placement="top"
            arrow
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={tooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <InformationCircleIcon
              onClick={handleTooltipOpen}
              className="w-[18px] h-[20.02px] text-[#4B5563] lg:hidden visible"
            />
          </Tooltip>
        </ClickAwayListener>
      </div>
      <p className="pb-6 mb-6 text-[11px]">Data diperbarui {date} WIB</p>

      <ChartBar
        data={production}
        type="nominal"
        CustomTooltip={<CustomTooltip />}
      >
        <Bar
          yAxisId="left"
          dataKey={PREVIOUS_YEAR}
          barSize={15}
          fill="#DEAA58"
        />
        <Bar
          yAxisId="left"
          dataKey={CURRENT_YEAR}
          barSize={15}
          fill="#fb5b50"
        />

        <Line
          type="monotone"
          dataKey="growth"
          stroke="#374151"
          strokeWidth={2}
          dot={false}
          yAxisId="right"
        />
      </ChartBar>
    </div>
  );
});

interface Temp {
  month: string;
  value: number;
}

const monthNames: any = {
  JAN: 1,
  FEB: 2,
  MAR: 3,
  APR: 4,
  MAY: 5,
  JUN: 6,
  JUL: 7,
  AUG: 8,
  SEP: 9,
  OCT: 10,
  NOV: 11,
  DEC: 12,
};
const mockData = [
  { name: "Jan" },
  { name: "Feb" },
  { name: "Mar" },
  { name: "Apr" },
  { name: "May" },
  { name: "Jun" },
  { name: "Jul" },
  { name: "Aug" },
  { name: "Sep" },
  { name: "Oct" },
  { name: "Nov" },
  { name: "Dec" },
];
export const getConfigBarProduction = (props: any) => {
  if (props) {
    let cy: Temp[] = Object.keys(props.currentYearNetAPEDatas).map((key) => {
      return { month: key, value: props.currentYearNetAPEDatas[key] };
    });
    let ly: Temp[] = Object.keys(props.lastYearNetAPEDatas).map((key) => {
      return { month: key, value: props.lastYearNetAPEDatas[key] };
    });

    let currentYears: Temp[] = cy.sort((a: Temp, b: Temp) => {
      return monthNames[a.month] - monthNames[b.month];
    });
    let lastYears: Temp[] = ly.sort((a: Temp, b: Temp) => {
      return monthNames[a.month] - monthNames[b.month];
    });

    let newData: any = mockData.map((d: any, i: number) => {
      let currentYearData =
        currentYears[i]?.value === undefined || currentYears[i]?.value === 0
          ? 0.0000000001
          : currentYears[i]?.value;
      let lastYearData =
        lastYears[i]?.value === undefined || lastYears[i]?.value === 0
          ? 0.0000000001
          : lastYears[i]?.value;

      let a = currentYearData - lastYearData;
      let total = currentYearData + lastYearData;
      let growth = (a / total) * 100;
      return {
        ...d,
        [CURRENT_YEAR]: currentYears[i].value,
        [PREVIOUS_YEAR]: lastYears[i].value,
        growth: growth,
        total: currentYears[i].value + lastYears[i].value,
      };
    });

    return newData;
  }
};
const useConfigBarProductionHooks = (props: any) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props) {
      const newData = getConfigBarProduction(props);
      setData(newData);
    }
  }, [props]);
  return data;
};

const CustomTooltip = ({ active, payload, label, type }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip shadow-lg"
        style={{ backgroundColor: "white", padding: 10 }}
      >
        <p className="label text-lg">{`${label} `}</p>
        <p className="intro">
          {PREVIOUS_YEAR} :{" "}
          {currencyFormatter("IDR", payload[0].payload[PREVIOUS_YEAR])}
        </p>
        <p className="intro">
          {CURRENT_YEAR} :{" "}
          {currencyFormatter("IDR", payload[0].payload[CURRENT_YEAR])}
        </p>
        <p className="intro">
          Total : {currencyFormatter("IDR", payload[0].payload.total)}
        </p>
        <p className="intro">
          Growth :{" "}
          {payload[0].payload.growth ? payload[0].payload.growth.toFixed() : 0}%
        </p>
      </div>
    );
  }

  return null;
};
