import React from "react";

interface ILicenseCard {
  image?: string;
  licenseType: "AAJI" | "AASI";
  variant: "blue" | "green";
  licenseCode?: string;
  endDate?: string;
  CPDCode?: string;
}

const variants = {
  blue: {
    gradient: "bg-gradient-to-b from-[#FFEFF4] to-[#FEC0D5]",
    textColor: `text-[#AF1E32]`,
    borderColor: `border-[#FEBFD5]`,
  },
  green: {
    gradient: "bg-gradient-to-b from-[#DCFCE7] to-[#DBEAFE]",
    textColor: `text-[#15803D]`,
    borderColor: `border-[#BBF7D0]`,
  },
};

export const LicenseCard: React.FC<ILicenseCard> = ({
  variant,
  licenseType,
  image,
  CPDCode,
  endDate,
  licenseCode,
}) => {
  return (
    <div className={`w-full ${variants[variant].gradient} rounded-md p-5 h-[130px] md:h-full`}>
      <div className="flex flex-row justify-between items-center">
        <p
          className={
            `font-bold font-lato text-[70%] md:text-[90%] lg:text-lg ` + variants[variant].textColor
          }
        >
          Lisensi {licenseType}
        </p>
        <img src={image} className="w-[20%]" alt="aaji-logo" />
      </div>

      <div className={`border-b-2 ${variants[variant].borderColor} mt-2 md:mt-4`} />

      <div className="mt-2 md:mt-5">
        <div className="grid grid-cols-2 gap-x-4 w-[80%]">
          <div className="">
            <p className="font-lato text-gray-750 font-bold text-[50%] md:text-[75%] lg:text-[100%]">Kode Lisensi</p>
          </div>
          <div className="font-lato text-gray-750 ">
            <p className="break-all md:break-normal text-[50%] md:text-[75%] md:text-[75%] lg:text-[100%]">{licenseCode}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4 w-[80%] mt-2">
          <div className="">
            <p className="font-lato text-gray-750 font-bold text-[50%] md:text-[75%] lg:text-[100%]">
              Tanggal Berakhir
            </p>
          </div>
          <div className="font-lato text-gray-750 text-[50%] md:text-[75%] lg:text-[100%]">{endDate}</div>
        </div>
        <div className="grid grid-cols-2 gap-x-4 w-[80%] mt-2">
          <div className="">
            <p className="font-lato text-gray-750 font-bold text-[50%] md:text-[75%] lg:text-[100%] ">CPD Code</p>
          </div>
          <div className="font-lato text-gray-750 text-[50%] md:text-[75%] lg:text-[100%]">{CPDCode}</div>
        </div>
      </div>
    </div>
  );
};
