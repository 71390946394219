import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getConfigBarProduction } from "../pages/Dashboard/ProductionChart/";
import { getConfigPersistency } from "../pages/Dashboard/PerssistencyChart";
import "jspdf-autotable";
import { CURRENT_YEAR, PREVIOUS_YEAR } from "./year";
import currencyFormatter from "./currencyFormatter";
import { getConfigActiveAgent } from "../pages/Dashboard/ActiveAgentChart";
import { getConfigActiveRatio } from "../pages/Dashboard/ActiveRatioChart";
import moment from "moment";
interface IPrintToPDF {
  filterType: {
    id: string;
    name: string;
    label: string;
  };
  production: any;
  persistency: any;
  activeAgent: any;
  activityRatio: any;
}

export async function printToPDF({
  filterType,
  production,
  persistency,
  activeAgent,
  activityRatio,
}: IPrintToPDF) {
  let padding = 70;
  try {
    const dataProduction = getConfigBarProduction(production);
    const dataPersistensi = getConfigPersistency(persistency);
    const dataActiveAgent = getConfigActiveAgent(activeAgent || []);
    const dataActivityRatio = getConfigActiveRatio(activityRatio || []);
    const canvas = document.querySelectorAll(".printable");
    const pdf = new jsPDF("p", "pt", "a4");

    const user = JSON.parse(sessionStorage.getItem("user") || "");
    let imgHeight: number[] = [];

    for (let i = 0; i < canvas.length; i++) {
      const element = canvas[i];
      const canvasss = await html2canvas(element as HTMLElement, {
        logging: false,
        scale: 2,
      });

      const imgData = canvasss.toDataURL("image/png", 1.0);

      const imgProps = pdf.getImageProperties(imgData);

      const pdfWidth = pdf.internal.pageSize.getWidth();

      if (i === 0) {
        if (filterType.id === "I") {
          pdf.setFont("", "bold");
          pdf.text("PRODUKTIVITAS DASHBOARD", pdfWidth / 2, 20, {
            align: "center",
          });
          pdf.setFont("", "normal");
          // pdf.setFontSize(9);

          const txt = `${user.agentData.officeCode} - ${
            user.agentData.office.split("-")[0]
          }`;
          var dim = pdf.getTextDimensions(txt, {
            maxWidth: pdfWidth - 10,
          });
          pdf.text(txt, pdfWidth / 2, 40, {
            align: "center",
            maxWidth: pdfWidth - 10,
          });
          if (dim.h <= 16) {
            padding = padding;
          } else if (dim.h > 16 && dim.h < 35) {
            padding = 80;
          } else if (dim.h > 35 && dim.h < 53) {
            padding = 100;
          } else if (dim.h > 53 && dim.h < 72) {
            padding = 130;
          } else {
            padding = 140;
          }
        }

        if (filterType.id === "U" || filterType.id === "G") {
          pdf.setFont("", "bold");
          pdf.text("PRODUKTIVITAS DASHBOARD", pdfWidth / 2, 20, {
            align: "center",
          });
          pdf.setFont("", "normal");
          // pdf.setFontSize(9);
          // pdf.text(
          // `${user.agentData.officeCode} - ${
          //   user.agentData.office.split("-")[0]
          // }`,
          //   pdfWidth / 2,
          //   40,
          //   {
          //     align: "center",
          //   }
          // );
          // const s =
          //   "ExELLENT STARS MULTIVISION TOWER LT. 5 JL. KUNINGAN MULIA LOT 9B Jakarta ExELLENT STARS MULTIVISION TOWER LT. 5 JL. KUNINGAN MULIA LOT 9B Jakarta  ExELLENT STARS MULTIVISION TOWER LT. 5 JL. KUNINGAN MULIA LOT 9B Jakarta ExELLENT STARS MULTIVISION TOWER LT. 5 JL. KUNINGAN MULIA LOT 9B Jakarta ExELLENT STARS MULTIVISION TOWER LT. 5 JL. KUNINGAN MULIA LOT 9B Jakarta";
          const txt = `${user.agentData.officeCode} - ${
            user.agentData.office.split("-")[0]
          }`;
          pdf.text(txt, pdfWidth / 2, 40, {
            align: "center",
            maxWidth: pdfWidth - 10,
          });
          var dim = pdf.getTextDimensions(txt, {
            maxWidth: pdfWidth - 10,
          });

          const renderX = () => {
            if (dim.h <= 16) {
              return 60;
            } else if (dim.h > 16 && dim.h < 35) {
              padding = 80;
              return 78;
            } else if (dim.h > 35 && dim.h < 53) {
              padding = 100;
              return 95;
            } else if (dim.h > 53 && dim.h < 72) {
              padding = 130;
              return 120;
            } else {
              padding = 140;
              return 130;
            }
          };
          pdf.text(
            `${
              filterType.name.charAt(0).toUpperCase() + filterType.name.slice(1)
            } ${user.agentData.agentNumber} - ${
              user.agentData.agentAccountName
            }`,
            pdfWidth / 2,
            renderX(),
            {
              align: "center",
            }
          );
        }
        if (filterType.id === "K") {
          pdf.setFont("", "bold");
          pdf.text("PRODUKTIVITAS DASHBOARD", pdfWidth / 2, 20, {
            align: "center",
          });
          pdf.setFont("", "normal");
          // pdf.setFontSize(9);

          const txt = `${filterType.label} - ${
            user.agentData.office.split("-")[0]
          }`;
          pdf.text(txt, pdfWidth / 2, 40, {
            align: "center",
            maxWidth: pdfWidth - 10,
          });
          var dim = pdf.getTextDimensions(txt, {
            maxWidth: pdfWidth - 10,
          });

          const renderX = () => {
            if (dim.h <= 16) {
              return 60;
            } else if (dim.h > 16 && dim.h < 35) {
              padding = 80;
              return 78;
            } else if (dim.h > 35 && dim.h < 53) {
              padding = 100;
              return 95;
            } else if (dim.h > 53 && dim.h < 72) {
              padding = 130;
              return 120;
            } else {
              padding = 140;
              return 130;
            }
          };
          pdf.text(
            `${filterType.name.toUpperCase()} ${user.agentData.agentNumber} - ${
              user.agentData.agentAccountName
            }`,
            pdfWidth / 2,
            renderX(),
            {
              align: "center",
            }
          );
        }
      }

      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width + padding;
      imgHeight.push(pdfHeight + 10);

      pdf.addImage(
        imgData,
        "PNG",
        0,
        i === 0 ? 0 + padding : 0,
        pdfWidth,
        pdfHeight - padding
      );

      if (element.textContent?.toLocaleLowerCase().includes("produksi")) {
        (pdf as any).autoTable({
          columns: [
            {
              dataKey: "name",
              header: "Bulan",
            },
            {
              dataKey: PREVIOUS_YEAR,
              header: PREVIOUS_YEAR,
            },
            {
              dataKey: CURRENT_YEAR,
              header: CURRENT_YEAR,
            },
            {
              dataKey: "growth",
              header: "Growth",
            },
            {
              dataKey: "total",
              header: "Total",
            },
          ],
          body: dataProduction.map((data: any) => ({
            [PREVIOUS_YEAR]: currencyFormatter("IDR", data[PREVIOUS_YEAR] || 0),
            [CURRENT_YEAR]: currencyFormatter("IDR", data[CURRENT_YEAR] || 0),
            growth: (parseInt(data.growth) || 0) + "%",
            total:
              data[PREVIOUS_YEAR] !== undefined &&
              data[CURRENT_YEAR] !== undefined
                ? currencyFormatter("IDR", data.total || 0)
                : currencyFormatter("IDR", data.total.toFixed() || 0),
            name: data.name,
          })),
          startY: pdfHeight,
          showHead: "firstPage",
          styles: { overflow: "hidden" },
        });
      }

      if (element.textContent?.toLowerCase().includes("persistensi")) {
        const headerTable = [{ dataKey: "id", header: "" }];
        for (let i = 0; i < dataPersistensi?.length; i++) {
          headerTable.push({
            dataKey: dataPersistensi[i].name,
            header: dataPersistensi[i].name,
          });
        }

        const bodyTable: any = [{}, {}];

        for (let i = 0; i < dataPersistensi?.length; i++) {
          const d = dataPersistensi[i];

          bodyTable[1][d.name as keyof Object] = d.old + "%";
          bodyTable[0][d.name as keyof Object] = d.rolling + "%";
        }
        bodyTable[0]["id"] = "Persistensi Rolling";
        bodyTable[1]["id"] = "Persistensi Lama";

        (pdf as any).autoTable({
          columns: headerTable,
          body: bodyTable,
          startY: pdfHeight - padding,
          showHead: "firstPage",
          styles: { overflow: "hidden" },
        });
      }

      if (element.textContent?.toLowerCase().includes("active agent")) {
        (pdf as any).autoTable({
          columns: [
            {
              dataKey: "name",
              header: "Bulan",
            },
            {
              dataKey: PREVIOUS_YEAR,
              header: PREVIOUS_YEAR,
            },
            {
              dataKey: CURRENT_YEAR,
              header: CURRENT_YEAR,
            },
            {
              dataKey: "growth",
              header: "Growth",
            },
            {
              dataKey: "total",
              header: "Total",
            },
          ],
          body: dataActiveAgent?.map((data: any) => ({
            [PREVIOUS_YEAR]: parseInt(data[PREVIOUS_YEAR] || 0),
            [CURRENT_YEAR]: parseInt(data[CURRENT_YEAR] || 0),
            growth: (parseInt(data.growth) || 0) + "%",
            total: parseInt(data.total.toFixed() || 0),
            name: data.name,
          })),
          startY: pdfHeight - padding,
          showHead: "firstPage",
          styles: { overflow: "hidden" },
        });
      }
      if (element.textContent?.toLowerCase().includes("activity ratio")) {
        (pdf as any).autoTable({
          columns: [
            {
              dataKey: "name",
              header: "Bulan",
            },
            {
              dataKey: PREVIOUS_YEAR,
              header: PREVIOUS_YEAR,
            },
            {
              dataKey: CURRENT_YEAR,
              header: CURRENT_YEAR,
            },
            {
              dataKey: "growth",
              header: "Growth",
            },
          ],
          body: dataActivityRatio?.map((data: any) => ({
            [PREVIOUS_YEAR]: parseInt(data[PREVIOUS_YEAR] || 0) + "%",
            [CURRENT_YEAR]: parseInt(data[CURRENT_YEAR] || 0) + "%",
            growth: (parseInt(data.growth) || 0) + "%",
            name: data.name,
          })),
          startY: pdfHeight - padding,
          showHead: "firstPage",
          styles: { overflow: "hidden" },
        });
      }

      if (canvas.length - 1 !== i) {
        pdf.addPage();
      }
    }

    pdf.save(
      `dashboard-graph-${user.username}-${
        filterType.name
      }-${moment().year()}-${moment().format("MM")}-${moment().format(
        "DD"
      )}.pdf`
    );
  } catch (error) {
    console.log(error);
  }
}
