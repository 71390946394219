import { ReactComponent as BagIcon } from "../../../assets/u_bag.svg";
import { LicenseCard } from "../../../Components/atom/license-card";
import { NoLicenseCard } from "../../../Components/atom/license-card/noLicense";
import aaji from "../../../assets/aaji.png";
import aasi from "../../../assets/aasi.png";
import React, { ReactNode, useEffect, useState } from "react";
import { Button } from "../../../Components/atom/button";

import { useSessionStorage } from "usehooks-ts";
import { Login } from "../../../services/login";
import {
  useCheckAgentApprovalQuery,
  useAddFatcaApprovalQuery,
} from "../../../services/fatca";
import toast, { Toaster } from "react-hot-toast";
import { Toast } from "../../../Components/atom/toast";
import { LoadingCircle } from "../../../Components/Loading/loadingCircle";
import { setFatcaStatus } from "./slice";
import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";

const monthIDN = [
  { month: "Januari" },
  { month: "Februari" },
  { month: "Maret" },
  { month: "April" },
  { month: "Mei" },
  { month: "Juni" },
  { month: "Juli" },
  { month: "Agustus" },
  { month: "September" },
  { month: "Oktober" },
  { month: "November" },
  { month: "Desember" },
];

const renderDate = (props: any) => {
  let fixedProps = "";
  if (props) {
    if (props.length > 1 && props.length < 10) {
      let yearSub = props.substring(0, 4);
      let monthSub = props.substring(4, 6);
      let daySub = props.substring(6, 8);
      fixedProps = yearSub + "-" + monthSub + "-" + daySub;
    }

    let fullDate = new Date(
      props.length > 1 && props.length < 10 ? fixedProps : props
    );
    let date = fullDate.getDate();
    let month = fullDate.getMonth();
    let year = fullDate.getFullYear();
    let dateIndonesia = date + " " + monthIDN[month].month + " " + year;
    return dateIndonesia;
  } else {
    return "-";
  }
};

export const DataKeagenan = () => {
  const [data] = useSessionStorage<Login | undefined>("user", undefined);
  const fatcaStatus = useSelector(
    (state: RootState) => state.fatcaStatus.fatcaStatus
  );

  const {
    data: checkFatca,
    isLoading: checkLoading,
    isError,
  } = useCheckAgentApprovalQuery({
    agentCode: data ? data.agentData.agentNumber : "",
    agentNumber: data ? data.agentData.agentNumber : "",
  });

  return (
    <div className="bg-white rounded-md">
      <Header />
      <LicenseCards />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
        <div>
          <RowDescription
            title="Kode Agen"
            description={
              data && data.agentData.agentNumber !== ""
                ? data.agentData.agentNumber
                : "-"
            }
          />
          <RowDescription
            title="Kode Manajer Keagenan"
            description={
              data && data.agentData.leaderNumber !== ""
                ? data.agentData.leaderNumber
                : "-"
            }
          />
          <RowDescription
            title="Nama Manajer Keagenan"
            description={
              data && data.agentData.leaderName !== ""
                ? data.agentData.leaderName
                : "-"
            }
          />
          <RowDescription
            title="Persetujuan Fatca"
            description={
              checkLoading
                ? "-"
                : !fatcaStatus && checkFatca?.responseDescription !== "Y"
                ? "-"
                : "Setuju"
            }
          />
          {checkLoading ? (
            <LoadingCircle />
          ) : isError ? (
            <span className="font-lato text-primary font-bold">
              (Error Loading Persetujuan FATCA)
            </span>
          ) : (
            <FatcaCard
              responseDescription={checkFatca?.responseDescription || ""}
            />
          )}
        </div>

        <div className="">
          <CoDescription title="Jumlah Sanksi">
            <CoDescriptionChildren
              title="Tahun Ini"
              desciption={data ? data.agentData.currentYearSanction : "-"}
            />
            <CoDescriptionChildren
              title="Keseluruhan"
              desciption={data ? data.agentData.overallSanction : "-"}
            />
          </CoDescription>
          <CoDescription title="Perjanjian Keagenan">
            <CoDescriptionChildren
              title="Konvensional"
              desciption={renderDate(
                data ? data.agentData.convContractDate : "-"
              )}
            />
            <CoDescriptionChildren
              title="Syariah"
              desciption={renderDate(
                data ? data.agentData.shariaContractDate : "-"
              )}
            />
          </CoDescription>
          <CoDescription title="Perjanjian Manajer Keagenan">
            <CoDescriptionChildren
              title="Konvensional"
              desciption={renderDate(
                data ? data.agentData.leaderConvContractDate : "-"
              )}
            />
            <CoDescriptionChildren
              title="Syariah"
              desciption={renderDate(
                data ? data.agentData.leaderShariaContractDate : "-"
              )}
            />
          </CoDescription>
        </div>
      </div>
      <Toaster containerStyle={{ top: 80 }} />
    </div>
  );
};

interface IFatcaCard {
  responseDescription: string; //get response Description value from check Fatca Query above
}

const FatcaCard = (props: IFatcaCard) => {
  const { responseDescription } = props;
  const fatcaStatus = useSelector(
    (state: RootState) => state.fatcaStatus.fatcaStatus
  );
  const [data] = useSessionStorage<Login | undefined>("user", undefined);
  const dispatch = useDispatch();
  const [add, setAdd] = useState<boolean>(false);
  const {
    isFetching: addFatcaFetching,
    isError,
    isSuccess,
  } = useAddFatcaApprovalQuery(
    {
      agentCode: data ? data.agentData.agentNumber : "",
      agentNumber: data ? data.agentData.agentNumber : "",
    },
    { skip: !add }
  );

  const handleAddFatca = () => {
    if (isError) {
      toast.custom((t) => (
        <Toast t={t} type="error">
          Error dalam menyetujui FATCA
        </Toast>
      ));
      return setAdd(false);
    }
    if (isSuccess) {
      return dispatch(setFatcaStatus(true));
    }
  };

  useEffect(() => {
    if (!addFatcaFetching) {
      handleAddFatca();
    }
  }, [addFatcaFetching]);

  return (
    <div
      className={`w-full rounded-lg bg-[#F9FAFB] py-2 px-5 border-[1px] border-[#FDB3A3] mt-8`}
    >
      <FatcaTerms />
      <div className="flex flex-row gap-2 mt-5">
        {addFatcaFetching ? (
          <LoadingCircle />
        ) : !isSuccess && responseDescription !== "Y" && !fatcaStatus ? (
          <Button onClick={() => setAdd(true)}>Setuju</Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const FatcaTerms = () => (
  <>
    <div>
      <p className="font-lato text-gray-800 font-bold">Persetujuan FATCA</p>
    </div>
    <div className="mt-2">
      <p className="text-sm">
        Dengan ini saya menyatakan telah memahami dan menyetujui untuk:
      </p>
    </div>
    <div className="mt-2">
      <div className="flex gap-2 flex-row flex-wrap">
        <div className="">
          <p className="text-sm font-lato text-gray-750">1.</p>
        </div>
        <div className="flex-1">
          <p className="text-sm font-lato text-gray-750">
            Memastikan Nasabah melengkapi dokumen tambahan sehubungan dengan
            FATCA
          </p>
        </div>
      </div>
    </div>
    <div className="mt-2">
      <div className="flex gap-2 flex-row flex-wrap">
        <div className="">
          <p className="text-sm font-lato text-gray-750">2.</p>
        </div>
        <div className="flex-1">
          <p className="text-sm font-lato text-gray-750">
            Tidak menyarankan Nasabah untuk menghindari FATCA
          </p>
        </div>
      </div>
    </div>
    <div className="mt-2">
      <div className="flex gap-2 flex-row flex-wrap">
        <div className="">
          <p className="text-sm font-lato text-gray-750">3.</p>
        </div>
        <div className="flex-1">
          <p className="text-sm font-lato text-gray-750">
            Tidak memberikan saran apapun mengenai pengisian FATCA kepada
            Nasabah
          </p>
        </div>
      </div>
    </div>
  </>
);

const CoDescription: React.FC<{ title: string; children: ReactNode }> = ({
  title,
  children,
}) => (
  <div className="flex flex-col mt-3">
    <div>
      <p className="font-lato font-bold text-gray-750">{title}</p>
    </div>
    {children}
  </div>
);
const CoDescriptionChildren: React.FC<{
  title: string;
  desciption: string | number | undefined;
}> = ({ title, desciption }) => (
  <div className="ml-5 mt-2">
    <div className="flex gap-2 lg:gap-0 lg:mr-28 flex-row flex-wrap">
      <div className="w-1/2">
        <p className="font-lato text-gray-750">{title}</p>
      </div>
      <div className="">
        <p className="font-lato text-gray-750">{desciption}</p>
      </div>
    </div>
  </div>
);

const RowDescription: React.FC<{
  title: string;
  description: string | undefined;
}> = ({ description, title }) => (
  <div className="flex mt-2 gap-2 lg:gap-0 lg:mr-28 flex-row flex-wrap">
    <div className="w-1/2">
      <p className="font-lato font-bold text-gray-750">{title}</p>
    </div>
    <div className="">
      <p className="font-lato text-gray-750">{description}</p>
    </div>
  </div>
);

const LicenseCards = () => {
  const [data] = useSessionStorage<Login | undefined>("user", undefined);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-5 gap-y-5 mt-5">
        {data?.oldAuth.agentData.aajiLicenceNumber &&
        data?.oldAuth.agentData.aajiLicenceType ? (
          <div>
            <LicenseCard
              CPDCode={
                data && data.oldAuth.agentData.aajiCpdCode !== ""
                  ? data.oldAuth.agentData.aajiCpdCode
                  : "-"
              }
              licenseType="AAJI"
              licenseCode={
                data && data.oldAuth.agentData.aajiLicenceNumber !== ""
                  ? data.oldAuth.agentData.aajiLicenceNumber
                  : "-"
              }
              endDate={renderDate(
                data && data.oldAuth.agentData.aajiLicenceExpiredDate !== ""
                  ? data.oldAuth.agentData.aajiLicenceExpiredDate
                  : "-"
              )}
              variant="blue"
              image={aaji}
            />
          </div>
        ) : !data?.oldAuth.agentData.aajiLicenceType ? (
          <div>
            <NoLicenseCard licenseType="AAJI" variant="gray" image={aaji} />
          </div>
        ) : (
          !data?.oldAuth.agentData.aajiLicenceNumber &&
          data?.oldAuth.agentData.aajiLicenceType && (
            <div>
              <NoLicenseCard
                licenseType="AAJI"
                variant="gray"
                image={aaji}
                children={`Lisensi belum tersedia. Silakan melakukan pendaftaran jadwal ujian lisensi Anda melalui aplikasi PRUForce.`}
              />
            </div>
          )
        )}
        {data?.oldAuth.agentData.aasiLicenceNumber &&
        data?.oldAuth.agentData.aasiLicenceType ? (
          <div>
            <LicenseCard
              CPDCode={
                data && data.oldAuth.agentData.aasiCpdCode !== ""
                  ? data.oldAuth.agentData.aasiCpdCode
                  : "-"
              }
              licenseType="AASI"
              licenseCode={
                data && data.oldAuth.agentData.aasiLicenceNumber !== ""
                  ? data.oldAuth.agentData.aasiLicenceNumber
                  : "-"
              }
              endDate={renderDate(
                data && data.oldAuth.agentData.aasiLicenceExpiredDate !== ""
                  ? data.oldAuth.agentData.aasiLicenceExpiredDate
                  : "-"
              )}
              variant="green"
              image={aasi}
            />
          </div>
        ) : !data?.oldAuth.agentData.aasiLicenceType ? (
          <div>
            <NoLicenseCard licenseType="AASI" variant="gray" image={aasi} />
          </div>
        ) : (
          !data?.oldAuth.agentData.aasiLicenceNumber &&
          data?.oldAuth.agentData.aasiLicenceType && (
            <div>
              <NoLicenseCard
                licenseType="AASI"
                variant="gray"
                image={aasi}
                children={`Lisensi belum tersedia. Silakan melakukan pendaftaran jadwal ujian lisensi Anda melalui aplikasi PRUForce.`}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

const Header = () => {
  const [data] = useSessionStorage<Login | undefined>("user", undefined);
  return (
    <>
      <div className="flex flex-row gap-2 items-center">
        <div className="active">
          <BagIcon className="text-primary" />
        </div>
        <p className="font-lato font-bold text-[20px] text-primary">
          Fokus Bisnis
        </p>
      </div>
      <div className="flex mt-3">
        <div className="p-1 pl-4 pr-4 bg-[#F2F7E8] rounded-md">
          <p className="font-lato text-[#7DAD2B]">
            {data?.agentData.focusBusiness === "S" ? "Syariah" : "Konvensional"}
          </p>
        </div>
      </div>
      <div className="mt-2">
        <p className="font-lato text-gray-800">
          {data?.agentData.focusBusiness === "S" ? (
            <span>
              Fokus Bisnis yang Anda pilih adalah{" "}
              <span className="font-bold">Syariah</span>, maka PTKP akan
              diterapkan atas penghasilan bruto yang diterima dari PT Prudential
              Sharia Life Assurance
            </span>
          ) : (
            <span>
              Fokus Bisnis yang Anda pilih adalah{" "}
              <span className="font-bold">Konvensional</span>, maka PTKP akan
              diterapkan atas penghasilan bruto yang diterima dari PT Prudential
              Life Assurance
            </span>
          )}
        </p>
      </div>
    </>
  );
};
