import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface RSRekananState {
  payload: {
    filterCityCode: string;
    page: number;
    search: string;
    size: number;
    hospital_city: string;
    isHospitalFriend: string;
  };
}

const initialState: RSRekananState = {
  payload: {
    page: 1,
    size: 1000,
    filterCityCode: "",
    search: "",
    hospital_city: "",
    isHospitalFriend: "true",
  },
};

export const rsRekananSlice = createSlice({
  name: "rumahSakitRekanan",
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.payload.search = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.payload.hospital_city = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSearch, setCity } = rsRekananSlice.actions;

export default rsRekananSlice.reducer;
