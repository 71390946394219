import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import filter from "../../Components/atom/SelectFilter/filter.json";
export type TUsedFilter = {
  id: string;
  name: string;
  label: string;
  officeCd?: string;
};
export type TKpmList = {
  officeName: string;
  officeCd: string;
};

export type Tabs = "individu" | "unit" | "group" | "adonad" | "KPM";
export type ITabs = "I" | "U" | "G" | "ADONAD" | "K" | string;
export const month = [
  { id: 1, name: "Januari" },
  { id: 2, name: "Februari" },
  { id: 3, name: "Maret" },
  { id: 4, name: "April" },
  { id: 5, name: "Mei" },
  { id: 6, name: "Juni" },
  { id: 7, name: "Juli" },
  { id: 8, name: "Agustus" },
  { id: 9, name: "September" },
  { id: 10, name: "Oktober" },
  { id: 11, name: "November" },
  { id: 12, name: "Desember" },
];

export const year = [
  { id: moment().year() - 2, name: (moment().year() - 2).toString() },
  { id: moment().year() - 1, name: (moment().year() - 1).toString() },
  { id: moment().year(), name: moment().year().toString() },
];

const currentMonth = month.find((data) => data.id === moment().month() + 1);
const currentYear = year.find((data) => data.id === moment().year());

export interface IInitialState {
  tab: Tabs;
  prodYear: typeof year[0];
  month: typeof month[0];
  year: typeof year[0];
  currentData: "month" | "year";
  choosen_kpm: string;
  usedFilter: TUsedFilter;
  listYear: typeof year[0][];
  payload: {
    agent_cd: string;
    tab_type: ITabs;
    snap: {
      snap_year: number;
      snap_month: number;
    };
  };
}

const initialState: IInitialState = {
  tab: "unit",
  prodYear: currentYear || year[0],
  month: currentMonth || month[0],
  year: currentYear || year[0],
  currentData: "month",
  choosen_kpm: "",
  usedFilter: filter[0],
  listYear: [],
  payload: {
    agent_cd: "",
    tab_type: "I",
    snap: {
      snap_year: moment().year(),
      snap_month: moment().month() + 1,
    },
  },
};

const ProductionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    setProdTab: (state, action: PayloadAction<Tabs>) => {
      state.tab = action.payload;
    },
    setProdYear: (state, action: PayloadAction<typeof year[0]>) => {
      state.prodYear = action.payload;
    },
    setMonth: (state, action: PayloadAction<typeof month[0]>) => {
      state.month = action.payload;
      state.payload.snap.snap_month = action.payload.id;
    },
    setYear: (state, action: PayloadAction<typeof year[0]>) => {
      state.year = action.payload;
      state.payload.snap.snap_year = action.payload.id;
    },
    setCurrentData: (state, action: PayloadAction<"month" | "year">) => {
      state.currentData = action.payload;
      if (action.payload === "year") {
        state.payload.snap.snap_month = 0;
      }
      if (action.payload === "month") {
        state.payload.snap.snap_month = state.month.id;
      }
    },
    setTabType: (state, action: PayloadAction<ITabs>) => {
      state.payload.tab_type = action.payload;
    },
    setChoosenKPM: (state, action: PayloadAction<string>) => {
      state.choosen_kpm = action.payload;
    },
    setUsedFilter: (state, action: PayloadAction<TUsedFilter>) => {
      state.usedFilter = action.payload;
    },
    setListYear: (state, action: PayloadAction<typeof year[0][]>) => {
      state.listYear = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setProdTab,
  setProdYear,
  setMonth,
  setYear,
  setCurrentData,
  setTabType,
  setChoosenKPM,
  setUsedFilter,
  setListYear,
  reset,
} = ProductionSlice.actions;
export default ProductionSlice.reducer;
