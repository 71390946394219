import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface LoginRes {
  result: boolean;
  errorCode: string;
  errorMessage: string;
  loginAuthResponse: Login;
  loginOldAuthResponse: Login;
  isPilotMode: boolean;
}
export interface Login {
  username: string;
  token: string;
  access_token: string;
  token_type?: null | string;
  expires_in: number;
  agent_agentTypeId: string;
  agent_scope: string;
  agent_needApproval: boolean;
  agent_channelType: string;
  mobileMenu?: MobileMenuEntity[] | null;
  refreshToken?: null;
  roles?: null | string;
  menus?: null | string;
  name?: null | string;
  errorCode: string;
  errorMessage?: null | string;
  result?: null | string;
  recruiter?: null | string;
  agentData: AgentData;
  oldAuth: Login;
  isPilotMode?: boolean;
}
export interface MobileMenuEntity {
  name: string;
  active: boolean;
  isWidget: boolean;
  isAdmin: boolean;
}
export interface AgentData {
  transactionId: string;
  transactionTime: string;
  aajiCpdCode: string;
  aajiLicenceExpiredDate: string;
  aajiLicenceNumber: string;
  aajiLicenceStartDate: string;
  aajiLicenceType: string;
  aajiTemporary: string;
  aasiCpdCode: string;
  aasiLicenceExpiredDate: string;
  aasiLicenceNumber: string;
  aasiLicenceStartDate: string;
  aasiLicenceType: string;
  aasiTemporary: string;
  activeStatus: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  adoaCode: string;
  adoaName: string;
  adsCode: string;
  adsName: string;
  agentEmailAddress: string;
  agentMobilephone1: string;
  agentMobilephone2: string;
  agentMobilephone3: string;
  agentNumber: string;
  agentType: string;
  appointedDate: string;
  clientName: string;
  clientNumber: string;
  currentYearSanction: number;
  dateOfBirth: string;
  idNumber: string;
  joinDate: string;
  leaderName: string;
  leaderNumber: string;
  leaderOfficeCode: string;
  licenseExpiryDate: string;
  licenseNumber: string;
  marital: string;
  office: string;
  officeCode: string;
  overallSanction: number;
  passedCourse?: string[] | null;
  raddCode: string;
  raddName: string;
  reportingManagerId: string;
  responseCode: string;
  userType: string;
  focusBusiness: string;
  leaderShariaContractDate: string;
  leaderConvContractDate: string;
  shariaContractDate: string;
  convContractDate: string;
  agentAccountName: string;
  agentAccountNumber: string;
  agentBankCode: string;
  agentBankName: string;
  agentBankCodeBABR: string;
  agentBankNameBABR: string;
  channelType: string;
  isAdmin: boolean;
  isEligibleFeedback: boolean;
  kpmList: KpmList[];
  kpmPilotList: kpmPilotList[];
}
type kpmPilotList = {
  id: number;
  kpmList: string[];
  pilotFlag: string;
  widgetRoleId: string;
};
export interface KpmList {
  officeCd: string;
  officeName: string;
}

export type IGetOfficeRespone = OfficeData[];

export interface OfficeData {
  officeAddr1: string;
  officeAddr2: string;
  officeAddr3: string;
  officeName: string;
  officePhone: string;
  city_code: string;
  province_code: string;
}

export type IGetProvinceResponse = ProvinceData[];
export interface ProvinceData {
  longitude: number;
  latitude: number;
  province_code: string;
  name: string;
}

export type IGetCityResponse = CityData[];
export interface CityData {
  city_code: string;
}

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<
      LoginRes,
      { username: string; password: string; captchaToken: string }
    >({
      query: ({ username, password, captchaToken }) => ({
        url: "/web/auth/loginWebPortal",
        method: "POST",
        body: {
          username,
          password,
          channel: "agen",
          captchaToken,
        },
      }),
    }),

    getOffice: builder.query<
      IGetOfficeRespone,
      {
        filterCityCode: string;
        filterProvinceCode: string;
        search: string;
      }
    >({
      query: ({ filterCityCode, filterProvinceCode, search }) => ({
        url: "/general/publicInformation/office",
        method: "POST",
        body: {
          page: 1,
          size: 999,
          filterCityCode,
          filterProvinceCode,
          search,
        },
      }),

      transformResponse: (res: IGetOfficeRespone, meta, arg) => {
        const data = res.map((data, i) => ({ ...data, id: i }));
        res = data;
        return res;
      },
    }),
    getProvince: builder.query<IGetProvinceResponse, void>({
      query: () => ({
        url: "/general/publicInformation/province",
        method: "POST",
      }),
    }),

    getCity: builder.query<IGetCityResponse, void>({
      query: () => ({
        url: "/general/publicInformation/office/getAllCity",
        method: "POST",
      }),
      transformResponse: (res: IGetCityResponse) => {
        delete res[0];
        return res;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useGetOfficeQuery,
  useGetProvinceQuery,
  useGetCityQuery,
} = loginApi;
