import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IInitialValue {
  payload: {
    category_id: string | number;
    search_key: string;
    page: number;
    size: number;
  };
}

const initialState: IInitialValue = {
  payload: {
    category_id: "",
    search_key: "",
    page: 0,
    size: 10,
  },
};

export const documentDownloadSlice = createSlice({
  name: "documentDownload",
  initialState,
  reducers: {
    setCategoryID: (state, action: PayloadAction<string | number>) => {
      state.payload.category_id = action.payload;
      state.payload.size = initialState.payload.size;
    },
    setSearchPayload: (
      state,
      action: PayloadAction<{ search: string; category: number | string | null }>,
    ) => {
      state.payload.search_key = action.payload.search;
      if (action.payload.category === null) {
        state.payload.category_id = "";
      } else {
        state.payload.category_id = action.payload.category;
      }
      state.payload.size = initialState.payload.size;
    },

    setSize: (state, action: PayloadAction<number>) => {
      state.payload.size = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.payload.page = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCategoryID, setSearchPayload, setSize, setPage } = documentDownloadSlice.actions;

export default documentDownloadSlice.reducer;
