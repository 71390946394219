// import { NumericFormat } from "react-number-format";

// const idCurrencyFormatter = (currency?: string) =>
//   new Intl.NumberFormat(currency === "USD" ? "en-IN" : "id-ID", {
//     style: "currency",
//     currency: currency,
//   });
const currencyFormatter = (
  currency?: string,
  value?: any,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number
) => {
  if (currency === "IDR") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: maximumFractionDigits ? maximumFractionDigits : 2,
      minimumFractionDigits: minimumFractionDigits ? minimumFractionDigits : 2,
    })
      .format(value)
      .replace("IDR", "Rp");
  } else {
    if (value > 0) {
      return (
        "US" +
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value)
      );
    } else {
      return "0.00";
    }
  }
};

export default currencyFormatter;
