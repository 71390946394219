import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { groupBy } from "lodash";
export type ISecretaryList = ISecretary[];

export interface ISecretary {
  idNumber: string;
  secretariesName: string;
  secretariesType: string;
  phoneNumber: string;
  emailAddress: string;
  idNumberPath: any;
  allowAccess: string;
  status: string;
  statusReason: any;
  leaderNumber: string;
  leaderName: string;
  leaderEmail: any;
  profilePicPath: string;
  leaderOfficeCode: string;
  officeCode: string;
  leaderOfficeName: string;
  registrationDate: any;
  verificationDate: any;
  modifiedDate: any;
  loginDate: any;
  leaderTerminateDate: any;
  page: any;
  size: any;
  params: any;
  sort: any;
  searchKey: any;
  contractPath: any;
  pruforceId: string;
  from: any;
  to: any;
  selected: any;
  descStatus: any;
  disableReason: any;
  approveStatus: string;
  approveReason: any;
}

export interface ISecretaryDetail {
  idNumber: string;
  secretariesName: string;
  secretariesType: string;
  phoneNumber: string;
  emailAddress: string;
  idNumberPath: string;
  allowAccess: string;
  status: string;
  statusReason: any;
  leaderNumber: string;
  leaderName: string;
  leaderEmail: any;
  profilePicPath: string;
  leaderOfficeCode: string;
  officeCode: string;
  leaderOfficeName: string;
  registrationDate: any;
  verificationDate: string;
  modifiedDate: any;
  loginDate: any;
  leaderTerminateDate: any;
  page: any;
  size: any;
  params: any;
  sort: any;
  searchKey: any;
  contractPath: string;
  pruforceId: string;
  from: any;
  to: any;
  selected: any;
  descStatus: any;
  disableReason: string;
  approveStatus: string;
  approveReason: string;
}

export type SecretaryMenus = SecretaryMenu[];

export interface SecretaryMenu {
  name: string;
  description: string;
  priority: number;
}

export const secretaryApi = createApi({
  reducerPath: "secretaryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSecretary: builder.query<
      ISecretaryList,
      { leaderNumber: string | number }
    >({
      query: ({ leaderNumber }) => ({
        url: "/web/secretary/getListSecretary",
        method: "GET",
        params: {
          leaderNumber,
        },
      }),
    }),
    getSecretaryDetail: builder.query<
      ISecretaryDetail,
      { pruforceId: string | number; agentCode: string }
    >({
      query: ({ pruforceId, agentCode }) => ({
        url: "/web/secretary/getDetailSecretary",
        method: "GET",
        params: {
          pruforceId,
          agentCode,
        },
      }),
    }),
    getListMenuSecretary: builder.query<
      SecretaryMenus[],
      { secretariesType: string | number; agentCode: string }
    >({
      query: ({ secretariesType, agentCode }) => ({
        url: "/web/secretary/getListMenuSecretary",
        method: "GET",
        params: {
          secretariesType,
          agentCode,
        },
      }),
      transformResponse: (resp: SecretaryMenus) => {
        resp.sort((itemPrev, itemNext) =>
          itemPrev.priority > itemNext.priority ? 1 : -1
        );

        //Filters the array if there are items with the same priority.
        //Among the items with the same priority, take out the item that has the word "WIDGET" in the name
        const filterResult = resp.filter((item, index, self) => {
          if (
            (item.priority === self[index + 1]?.priority ||
              item.priority === self[index - 1]?.priority) &&
            item.name.includes("WIDGET")
          )
            return false;
          return true;
        });

        // const finalRes = [];
        // let temp = [];

        // for (let index = 0; index < filterResult.length; index++) {
        //   if (temp.length === 0) {
        //     temp.push(filterResult[index]);
        //     continue;
        //   }

        //   if (
        //     Math.floor(temp[0].priority) ===
        //     Math.floor(filterResult[index].priority)
        //   ) {
        //     temp.push(filterResult[index]);
        //   } else {
        //     finalRes.push(temp);
        //     temp = [];
        //     temp.push(filterResult[index]);
        //   }
        // }

        let grouping = groupBy(filterResult, (res) => {
          return Math.floor(res.priority);
        });

        return Object.values(grouping);
      },
    }),
  }),
});

export const {
  useGetSecretaryQuery,
  useGetSecretaryDetailQuery,
  useGetListMenuSecretaryQuery,
} = secretaryApi;
