import React, { Suspense, useEffect } from "react";
import EmptyProfile from "../../assets/empty-profile.png";
import { Button } from "../../Components/atom/button";
import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllCPDQuery } from "../../services/CPD/CPD";
import Agents from "./TotalAgents";
import { CPDElement } from "../../services/CPD/types";
import LoadingScreen from "./LoadingScreen";
import { DataKeagenan } from "./DataKeagenan";
import PersonalDataTab from "./PersonalData";
import { useGetEmergencyContactQuery } from "../../services/newEmergencyContact";
import { Login } from "../../services/login";

import { useSessionStorage } from "usehooks-ts";
import { BREAKPOINTS } from "../../utils/breakpoint";
import useBreakpoint from "use-breakpoint";
import * as analyticConst from "../../resource/analytic_const";
import { logEvents } from "../../utils/analytics";
const CPDAAJI = React.lazy(() => import("./CPDAAJI"));
const CPDAASITab = React.lazy(() => import("./CPDAASI"));

export default function Profile() {
  const [tab, settab] = useState("pribadi");
  const [user] = useSessionStorage<Login | undefined>("user", undefined);

  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const { data: emergencyContact, isFetching: isEmergencyContactFetching } =
    useGetEmergencyContactQuery({ agentNumber: user?.agentData.agentNumber });
  const { data: CPDData, isFetching: isCPDDataFetching } = useGetAllCPDQuery({
    agentCode: user?.agentData.agentNumber,
  });
  useEffect(() => {
    logEvents("Profile", {
      content_id: analyticConst.Profile_Page,
      agent_code: user?.agentData.agentNumber,
    });
  }, []);

  const isAgent =
    user?.agentData.userType === "agent" && user?.agentData.agentType === "AG";

  const AASIData = useMemo<CPDElement[] | undefined>(() => {
    if (CPDData) {
      return CPDData.filter((data: CPDElement) => data.type === "S");
    }
    return undefined;
  }, [CPDData]);

  const AAJIData = useMemo<CPDElement[] | undefined>(() => {
    if (CPDData) {
      return CPDData.filter((data: CPDElement) => data.type === "F");
    }

    return undefined;
  }, [CPDData]);

  return (
    <div>
      {" "}
      <h1 className="font-lato text-[24px] font-extrabold text-[#1F2937] mb-[24px]">
        Profil Saya
      </h1>
      <div className=" w-full bg-white rounded-lg mb-[16px]">
        <div className="flex p-[32px] lg:gap-0 gap-5 justify-between lg:flex-row flex-col ">
          <div className="flex gap-[24px] lg:flex-row flex-col lg:items-start items-center w-full">
            <img
              src={EmptyProfile}
              className="w-[100px] h-[100px]"
              alt="User Profile"
            />
            <div className="lg:text-left text-center w-full ">
              <div className="w-full flex justify-center lg:justify-between flex-center mb-2 ">
                <h1 className="font-lato text-[20px] font-bold mb-[13px]">
                  {user?.agentData.clientName
                    ? user?.agentData.clientName
                    : "-"}
                </h1>
                {!isAgent && breakpoint === "desktop" && <Agents />}
              </div>

              <div className="flex gap-[18px] mb-[16px] items-center justify-center lg:justify-start">
                <div className="flex gap-[8px] items-center">
                  <p className="px-[8px] py-[4px] bg-[#FEF2F2] text-[#F45151] text-[12px] font-semibold font-lato rounded-md">
                    {user?.agentData.agentType
                      ? user?.agentData.agentType
                      : "-"}
                  </p>
                  <p className="font-lato text-[14px] ">
                    {user?.agentData.agentNumber
                      ? user?.agentData.agentNumber
                      : "-"}
                  </p>
                </div>
                <div className="w-[1px] bg-[#E5E7EB] h-[26px]"></div>
                <div className="flex gap-[8px] items-center text-left   lg:w-[50%] ">
                  <p className="px-[8px] py-[4px] bg-[#EFF6FF] text-[#3B82F6] text-[12px] font-semibold font-lato rounded-md">
                    {user?.agentData.officeCode
                      ? user?.agentData.officeCode
                      : "-"}
                  </p>
                  <p className="font-lato text-[14px] break-words lg:inline hidden">
                    {user?.agentData.office ? user?.agentData.office : "-"}
                  </p>
                </div>
              </div>
              <p className="font-lato text-[14px] break-words lg:hidden block mb-5">
                {user?.agentData.office ? user?.agentData.office : "-"}
              </p>
              {!isAgent && (
                <Button
                  onClick={() => {
                    navigate("/profile/myteam");
                  }}
                >
                  Lihat Tim Saya
                </Button>
              )}
            </div>
          </div>
          {!isAgent && breakpoint !== "desktop" && <Agents />}
        </div>
        <div className="w-full h-[1px] bg-[#F3F4F6] mb-[16px]"></div>
        <div>
          <ul className="flex lg:gap-[40px] gap-[20px] lg:ml-[48px] ml-[24px] flex-wrap">
            <li
              onClick={() => {
                settab("pribadi");
                logEvents("select_content", {
                  content_id: analyticConst.ACTION_PROFILE_DATA_PRIBADI,
                });
              }}
              className={`pb-[12px] cursor-pointer   ${
                tab === "pribadi"
                  ? "border-b-2 border-[#ED1B2E] text-[#1F2937] font-bold"
                  : "text-[#4B5563] font-[400]"
              }`}
            >
              <span>Data Pribadi</span>
            </li>
            <li
              onClick={() => {
                settab("agen");
                logEvents("select_content", {
                  content_id: analyticConst.ACTION_PROFILE_DATA_KEAGENAN,
                });
              }}
              className={`pb-[12px] cursor-pointer   ${
                tab === "agen"
                  ? "border-b-2 border-[#ED1B2E] text-[#1F2937] font-bold"
                  : "text-[#4B5563] font-[400]"
              }`}
            >
              <span>Data Keagenan</span>
            </li>
            {user?.oldAuth.agentData.aajiLicenceNumber &&
              user?.oldAuth.agentData.aajiLicenceType &&
              (user?.oldAuth.agentData.aajiCpdCode ||
                user?.oldAuth.agentData.aajiLicenceExpiredDate) && (
                <li
                  onClick={() => {
                    settab("aaji");
                    logEvents("select_content", {
                      content_id: analyticConst.ACTION_PROFILE_CPD_AAJI,
                    });
                  }}
                  className={`pb-[12px] cursor-pointer  ${
                    tab === "aaji"
                      ? "border-b-2 border-[#ED1B2E] text-[#1F2937] font-bold"
                      : "text-[#4B5563] font-[400]"
                  }`}
                >
                  <span>CPD AAJI</span>
                </li>
              )}
            {user?.oldAuth.agentData.aasiLicenceNumber &&
              user?.oldAuth.agentData.aasiLicenceType &&
              (user?.oldAuth.agentData.aasiCpdCode ||
                user?.oldAuth.agentData.aasiLicenceExpiredDate) && (
                <li
                  onClick={() => {
                    settab("aasi");
                    logEvents("select_content", {
                      content_id: analyticConst.ACTION_PROFILE_CPD_AASI,
                    });
                  }}
                  className={`pb-[12px] cursor-pointer  ${
                    tab === "aasi"
                      ? "border-b-2 border-[#ED1B2E] text-[#1F2937] font-bold"
                      : "text-[#4B5563] font-[400]"
                  }`}
                >
                  <span>CPD AASI</span>
                </li>
              )}
          </ul>
        </div>
      </div>
      <div className=" w-full bg-white rounded-lg px-[34px] py-[43px]">
        {tab === "pribadi" && (
          <PersonalDataTab
            emergencyContact={emergencyContact}
            isEmergencyContactFetching={isEmergencyContactFetching}
          />
        )}
        {tab === "agen" && <DataKeagenan />}
        {tab === "aaji" && (
          <Suspense fallback={<LoadingScreen isLoading={true} />}>
            <CPDAAJI CPDData={AAJIData} isFetching={isCPDDataFetching} />
          </Suspense>
        )}
        {tab === "aasi" && (
          <Suspense fallback={<LoadingScreen isLoading={true} />}>
            <CPDAASITab CPDData={AASIData} isFetching={isCPDDataFetching} />
          </Suspense>
        )}
      </div>
    </div>
  );
}
