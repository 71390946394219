import React from "react";
import { CircularProgress } from '@mui/material' 

interface IButton {
  outlined?: boolean;
  scretch?: boolean;
  onClick?(value: any): void;
  loading?: boolean;
}

export const Button: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    IButton
> = ({ children, outlined, scretch, className, loading, onClick, ...rest }) => (
  <button
    {...rest}
    className={`
      font-medium
      rounded-lg
      text-sm
      px-5
      py-2
      border-[1px]
    border-primary
    ${outlined ? "text-primary" : "bg-primary text-white"}
    ${scretch && "w-full"}
    ${(rest.disabled || loading) && "bg-[#D1D5DB] border-[#D1D5DB]"}

    ${className}
    `}
    disabled={rest.disabled || loading}
    onClick={onClick}
  >
    {loading ?
    <CircularProgress sx={{ color: "#ED1B2E" }} size={"1rem"}/>
    :
    <>{children}</>
    }
  </button>
);
