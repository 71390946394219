import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export type ICategoryByAgentTypes = ICategoryByAgentType[];

export interface ICategoryByAgentType {
  category_id: number;
  category_description: string;
  max_file_storage: any;
  max_size_per_file: number;
  created_by: string;
  created_date: string;
  updated_by: string;
  updated_date: string;
  display_order: number;
  jsonDisplayOrder: any;
  agent_type: any;
}

export type ICollateralTypes = {
  status: number;
  message: string;
  data: ICollateralType[];
  offset: number;
  lastPages: boolean;
};

export interface ICollateralType {
  document_id: number;
  document_category: any;
  document_description: string;
  file_type: string;
  file_size: number;
  document_owner: string;
  publish_date: string;
  created_by: any;
  created_date: any;
  updated_by: any;
  updated_date: any;
  file_name: string;
  fileBase64: any;
  agent_name: any;
  search_by: any;
  search_key: any;
  page: any;
  size: any;
  order_by: any;
  sort: any;
  category_id: any;
  email: any;
  documentCategoryName: any;
  categoryDescription: string;
}

export interface IDocumentFilesResp {
  status: number;
  message: string;
  data: IDocumentFile;
}

export interface IDocumentFile {
  file_id: number;
  file_data: any;
  fileBase64: string;
  document_id: number;
  last_updated: number;
}

export interface ISendEmailResp {
  headers: IEmailHeaders;
  body: IEmailBody;
  statusCode: string;
  statusCodeValue: number;
}

export interface IEmailHeaders {
  Date: string[];
  Server: string[];
  "Content-Type": string[];
  "Content-Length": string[];
  Connection: string[];
}

export interface IEmailBody {
  httpCode: string;
  response: IEmailResponse;
}

export interface IEmailResponse {
  from: string;
  tos: string;
  ccs: any;
  bccs: any;
  subject: string;
  message: string;
  status: string;
  descriptionStatus: string;
}

export const documentDownloadApi = createApi({
  reducerPath: "documentDownloadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
    // mode: "no-cors",
  }),
  endpoints: (builder) => ({
    getCategoryByAgentType: builder.query<
      ICategoryByAgentTypes,
      { agent_type: string }
    >({
      query: ({ agent_type }) => ({
        url: "/web/collateral-management/getCategoryByAgentType",
        method: "POST",
        body: {
          agent_type: agent_type,
        },
      }),
    }),
    getCollateralDocument: builder.query<
      ICollateralTypes,
      {
        category_id: string | number;
        search_key: string;
        page: number;
        size: number;
      }
    >({
      query: ({ category_id, page, size, search_key }) => ({
        url: "/web/collateral-management/getDocumentByPRUforce",
        method: "POST",
        body: {
          category_id,
          search_key,
          page,
          size,
        },
      }),
      transformResponse: (res: ICollateralTypes, meta, args) => {
        const data = res.data.map((data, i) => ({ ...data, id: i + 1 }));
        res.data = data;
        if (args.page === 0) {
          res.offset = args.size;
        } else {
          res.offset = args.page * 10 + 10;
        }

        res.lastPages = args.size > data.length;

        return res;
      },
    }),

    getDocumentFileByCategoryID: builder.query<
      IDocumentFilesResp,
      { document_id: number | string }
    >({
      query: ({ document_id }) => ({
        url: "/web/collateral-management/getDocumentFileByCategorryID",
        method: "POST",
        body: { document_id },
      }),
    }),

    sendEmail: builder.query<
      ISendEmailResp,
      {
        document_category: string;
        document_description: string;
        document_id: string;
        document_owner: string;
        email: string;
        file_name: string;
        publish_date: string;
        file_type: string;
        agent_name: string;
      }
    >({
      query: (props) => ({
        url: "/web/collateral-management/sendEmail",
        method: "POST",
        body: props,
      }),
    }),
  }),
});

export const {
  useGetCategoryByAgentTypeQuery,
  useGetCollateralDocumentQuery,
  useLazyGetCollateralDocumentQuery,
  useGetDocumentFileByCategoryIDQuery,
  useLazyGetDocumentFileByCategoryIDQuery,
  useSendEmailQuery,
  useLazySendEmailQuery,
} = documentDownloadApi;
