import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export type TOption = {
  value: string;
  key: string;
};

export type TGetYear = {
  tab_type: string;
};

export type TRincianIndividu = {
  agentCode: string;
  agentNumber: string;
  channelType: string;
  direction: string;
  leaderNumber: string;
  orderBy: string;
  page: number;
  salesforceId: string;
  searchBy: string;
  searchBy2: string;
  searchVal: string;
  searchVal2: string;
  size: number;
  month: string;
  year: string;
};
export type TRincianIndividuResponse = {
  policyNumber: string;
  policyHolderName: string;
  productCode: string;
  policyStatus: string;
  policyValue: number;
  transactionDate: string;
  responseTimestamp: string;
  productId: string;
  productHtml: string;
  policyCount: number;
};
export type TTotalRincianIndividuResponse = {
  totalData: number;
};
export type TRincianIndividuFilter = {
  agentNumber: string;
  leaderNumber: string;
  salesforceId: string;
  agentCode: string;
};

export type TRincianIndividuYTDResponse = {
  policyNumber: string;
  policyHolderName: string;
  productCode: string;
  policyStatus: string;
  policyValue: number;
  transactionDate: string;
  responseTimestamp: string;
  productId: string;
  productHtml: string;
  policyCount: number;
};

export type TRincianUnit = {
  data: {
    agentCode: string;
    agentType: string;
    channelType: string;
    direction: string;
    leaderNumber: string;
    page: number;
    salesforceId: string;
    searchBy: string;
    searchVal: string;
    searchVal2: string;
    size: number;
    year: string;
  };
  params?: {
    month: string;
  };
};

export type TRincianUnitResponse = {
  agentNumber: string;
  agentType: string;
  clientName: string;
  clientNumber: any;
  totalNetAPI: number;
  responseTimestamp: string;
};

export type TRincianGroup = {
  officeCode?: string;
  agent_cd: string;
  tab_type: string;
  snap: {
    snap_year: string;
    snap_month: string;
  };
  page: {
    page_index: number;
    page_size: number;
    filter_field: string;
    filter_text: string;
    search_text: string;
    order_field: string;
    order_asc: boolean;
  };
};

export type TGroup = {
  total_data?: number;
  accounting_year: number;
  accounting_month: number;
  unit_number: string;
  unit_name: string;
  unit_agent_type: string;
  ape: number;
  api: number;
  api_corporate: number;
  api_syariah: number;
  api_convent: number;
  spi: number;
  spi_syariah: number;
  spi_convent: number;
  topup: number;
  topup_syariah: number;
  topup_convent: number;
  saver: number;
  saver_syariah: number;
  saver_convent: number;
};
export type TRincianGroupResponse = {
  last_update: number;
  page_index: number;
  page_size: number;
  page_next: boolean;
  page_data: TGroup[];
  filter_field: string;
  filter_text: string;
  search_text: string;
  order_field: string;
  order_asc: boolean;
  page_info: {
    agent_type: string[];
  };
};

export type TGetProductionSummary = {
  agent_cd: string;
  tab_type: string;
  office_cd: string;
  snap: {
    snap_year: number;
    snap_month: number;
  };
};

export type TDownloadProduksi = {
  agent_cd: string;
  tab_type: string;
  snap: { snap_year: string; snap_month: number };
  email: string;
  agent_cd_detail?: string;
};

export type TDownloadProduksiResponse = {
  filename: string;
  filetype: string;
  contenttype: string;
  successful: true;
  file: string;
};
export interface Production {
  last_update: number;
  lastYearNetAPE: number;
  lastYearAPI: number;
  lastYearAPICorporate: number;
  lastYearAPISyariah: number;
  lastYearAPIConvent: number;
  lastYearSPI: number;
  lastYearSPISyariah: number;
  lastYearSPIConvent: number;
  lastYearSaver: number;
  lastYearSaverSyariah: number;
  lastYearSaverConvent: number;
  lastYearTopup: number;
  lastYearTopupSyariah: number;
  lastYearTopupConvent: number;
  lastMonthNetAPE: number;
  lastMonthAPI: number;
  lastMonthAPICorporate: number;
  lastMonthAPISyariah: number;
  lastMonthAPIConvent: number;
  lastMonthSPI: number;
  lastMonthSPISyariah: number;
  lastMonthSPIConvent: number;
  lastMonthSaver: number;
  lastMonthSaverSyariah: number;
  lastMonthSaverConvent: number;
  lastMonthTopup: number;
  lastMonthTopupSyariah: number;
  lastMonthTopupConvent: number;
  currentYearNetAPE: number;
  currentYearAPI: number;
  currentYearAPICorporate: number;
  currentYearAPISyariah: number;
  currentYearAPIConvent: number;
  currentYearSPI: number;
  currentYearSPISyariah: number;
  currentYearSPIConvent: number;
  currentYearSaver: number;
  currentYearSaverSyariah: number;
  currentYearSaverConvent: number;
  currentYearTopup: number;
  currentYearTopupSyariah: number;
  currentYearTopupConvent: number;
  currentMonthNetAPE: number;
  currentMonthAPI: number;
  currentMonthAPICorporate: number;
  currentMonthAPISyariah: number;
  currentMonthAPIConvent: number;
  currentMonthSPI: number;
  currentMonthSPISyariah: number;
  currentMonthSPIConvent: number;
  currentMonthSaver: number;
  currentMonthSaverSyariah: number;
  currentMonthSaverConvent: number;
  currentMonthTopup: number;
  currentMonthTopupSyariah: number;
  currentMonthTopupConvent: number;
  lastYearNetAPEDatas: LastYearNetApedatas;
  lastYearSaverDatas: LastYearSaverDatas;
  lastYearSPIDatas: LastYearSpidatas;
  lastYearTopupDatas: LastYearTopupDatas;
  currentYearNetAPEDatas: CurrentYearNetApedatas;
  currentYearSaverDatas: CurrentYearSaverDatas;
  currentYearSPIDatas: CurrentYearSpidatas;
  currentYearTopupDatas: CurrentYearTopupDatas;
}
export interface LastYearNetApedatas {
  JUL: number;
  OCT: number;
  FEB: number;
  JUN: number;
  APR: number;
  AUG: number;
  DEC: number;
  MAY: number;
  NOV: number;
  JAN: number;
  MAR: number;
  SEP: number;
}
export interface LastYearSaverDatas {
  JUL: number;
  OCT: number;
  FEB: number;
  JUN: number;
  APR: number;
  AUG: number;
  DEC: number;
  MAY: number;
  NOV: number;
  JAN: number;
  MAR: number;
  SEP: number;
}
export interface LastYearSpidatas {
  JUL: number;
  OCT: number;
  FEB: number;
  JUN: number;
  APR: number;
  AUG: number;
  DEC: number;
  MAY: number;
  NOV: number;
  JAN: number;
  MAR: number;
  SEP: number;
}
export interface LastYearTopupDatas {
  JUL: number;
  OCT: number;
  FEB: number;
  JUN: number;
  APR: number;
  AUG: number;
  DEC: number;
  MAY: number;
  NOV: number;
  JAN: number;
  MAR: number;
  SEP: number;
}
export interface CurrentYearNetApedatas {
  JUL: number;
  OCT: number;
  FEB: number;
  JUN: number;
  APR: number;
  AUG: number;
  DEC: number;
  MAY: number;
  NOV: number;
  JAN: number;
  MAR: number;
  SEP: number;
}
export interface CurrentYearSaverDatas {
  JUL: number;
  OCT: number;
  FEB: number;
  JUN: number;
  APR: number;
  AUG: number;
  DEC: number;
  MAY: number;
  NOV: number;
  JAN: number;
  MAR: number;
  SEP: number;
}
export interface CurrentYearSpidatas {
  JUL: number;
  OCT: number;
  FEB: number;
  JUN: number;
  APR: number;
  AUG: number;
  DEC: number;
  MAY: number;
  NOV: number;
  JAN: number;
  MAR: number;
  SEP: number;
}
export interface CurrentYearTopupDatas {
  JUL: number;
  OCT: number;
  FEB: number;
  JUN: number;
  APR: number;
  AUG: number;
  DEC: number;
  MAY: number;
  NOV: number;
  JAN: number;
  MAR: number;
  SEP: number;
}

export interface ProductionPayload {
  agent_cd: string;
  tab_type: string;
  office_cd: string;
  snap: Snap;
}

export interface Snap {
  snap_year: string | number;
  snap_month: string | number;
}
export interface IGetYearModif {
  id: number;
  name: string;
}
export const produksiApi = createApi({
  reducerPath: "produksiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL_LIVE}`,
    prepareHeaders: (headers) => {
      const token_quarkus = sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token") || "")
        : null;
      const token_jboss = sessionStorage.getItem("oldtoken")
        ? JSON.parse(sessionStorage.getItem("oldtoken") || "")
        : null;
      headers.set("Authorization", "");
      if (token_quarkus && token_jboss) {
        headers.set("Authorization", "Bearer " + token_quarkus);
        headers.set("X-Old-Authorization", "Bearer " + token_jboss);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getRincianIndividuMTD: builder.mutation<
      TRincianIndividuResponse[],
      TRincianIndividu
    >({
      query: (payload) => ({
        url: "/web/production2/findAllPolicyYearly/perMonth",
        method: "POST",
        body: payload,
        params: {
          month: payload.month,
        },
      }),
    }),
    getTotalRincianIndividuMTD: builder.mutation<
      TTotalRincianIndividuResponse,
      TRincianIndividu
    >({
      query: (payload) => ({
        url: "/web/production2/findAllPolicyYearly/perMonth/count",
        method: "POST",
        body: payload,
        params: {
          month: payload.month,
        },
      }),
    }),

    getYear: builder.query<string[], TGetYear>({
      query: (payload) => ({
        url: "/web/sales-dashboard/production/getYear",
        method: "POST",
        body: payload,
      }),
    }),
    getYearModif: builder.mutation<IGetYearModif[], TGetYear>({
      query: (payload) => ({
        url: "/web/sales-dashboard/production/getYear",
        method: "POST",
        body: payload,
      }),
      transformResponse: (rawResult: any) => {
        let newRes = rawResult.map((d: string) => {
          return {
            id: parseInt(d),
            name: d,
          };
        });
        return newRes;
      },
    }),

    getRincianIndividuYTD: builder.mutation<
      TRincianIndividuResponse[],
      TRincianIndividu
    >({
      query: (payload) => ({
        url: "/web/production2/findAllPolicyYearly",
        method: "POST",
        body: payload,
      }),
    }),
    getTotalRincianIndividuYTD: builder.mutation<
      TTotalRincianIndividuResponse,
      TRincianIndividu
    >({
      query: (payload) => ({
        url: "/web/production2/findAllPolicyYearly/count",
        method: "POST",
        body: payload,
      }),
    }),

    getRincianUnitMTD: builder.mutation<TRincianUnitResponse[], TRincianUnit>({
      query: (payload) => ({
        url: "/web/production2/findAllProductionUnitYearly/perMonth",
        method: "POST",
        body: payload.data,
        params: payload.params,
      }),
    }),
    getTotalRincianUnitMTD: builder.mutation<
      TTotalRincianIndividuResponse,
      TRincianUnit
    >({
      query: (payload) => ({
        url: "/web/production2/findAllProductionUnitYearly/perMonth/count",
        method: "POST",
        body: payload.data,
        params: payload.params,
      }),
    }),

    getRincianUnitYTD: builder.mutation<TRincianUnitResponse[], TRincianUnit>({
      query: (payload) => ({
        url: "/web/production2/findAllProductionUnitYearly",
        method: "POST",
        body: payload.data,
      }),
    }),
    getTotalRincianUnitYTD: builder.mutation<
      TTotalRincianIndividuResponse,
      TRincianUnit
    >({
      query: (payload) => ({
        url: "/web/production2/findAllProductionUnitYearly/count",
        method: "POST",
        body: payload.data,
      }),
    }),

    getRincianGroupMTD: builder.mutation<TRincianGroupResponse, TRincianGroup>({
      query: (payload) => ({
        url: "/web/sales-dashboard/production/detail",
        method: "POST",
        body: payload,
      }),
    }),
    getTotalRincianGroupMTD: builder.mutation<any, TRincianGroup>({
      query: (payload) => ({
        url: "/web/sales-dashboard/production/detail/count",
        method: "POST",
        body: payload,
      }),
      transformResponse(
        baseQueryReturnValue: TRincianGroupResponse,
        meta,
        arg
      ) {
        if (baseQueryReturnValue.page_data.length) {
          return baseQueryReturnValue.page_data[0].total_data;
        } else {
          return 0;
        }
      },
    }),

    getProductionSummary: builder.query<any, TGetProductionSummary>({
      query: (payload) => ({
        url: "/web/sales-dashboard/production/summary",
        method: "POST",
        body: payload,
      }),
      transformResponse(baseQueryReturnValue: any, meta, arg) {
        const { currentYearNetAPEDatas } = baseQueryReturnValue;
        const response = {
          last_update: baseQueryReturnValue.last_update,
          currentYearNetAPEDatas: [
            {
              month: "Januari",
              value: currentYearNetAPEDatas.JAN,
            },
            {
              month: "Februari",
              value: currentYearNetAPEDatas.FEB,
            },
            {
              month: "Maret",
              value: currentYearNetAPEDatas.MAR,
            },
            {
              month: "April",
              value: currentYearNetAPEDatas.APR,
            },
            {
              month: "Mei",
              value: currentYearNetAPEDatas.MAY,
            },
            {
              month: "Juni",
              value: currentYearNetAPEDatas.JUN,
            },
            {
              month: "Juli",
              value: currentYearNetAPEDatas.JUL,
            },
            {
              month: "Agustus",
              value: currentYearNetAPEDatas.AUG,
            },
            {
              month: "September",
              value: currentYearNetAPEDatas.SEP,
            },
            {
              month: "Oktober",
              value: currentYearNetAPEDatas.OCT,
            },
            {
              month: "November",
              value: currentYearNetAPEDatas.NOV,
            },
            {
              month: "Desember",
              value: currentYearNetAPEDatas.DEC,
            },
          ],
        };
        return response;
      },
    }),

    produksiDownload: builder.mutation<
      TDownloadProduksiResponse,
      TDownloadProduksi
    >({
      query: (payload) => ({
        url: "/web/sales-dashboard/production/download/report",
        method: "POST",
        body: payload,
      }),
    }),

    getProductions: builder.query<Production, ProductionPayload>({
      query: (payload) => ({
        url: "/web/sales-dashboard/production/summary",
        method: "POST",
        body: payload,
      }),
    }),
    getRincianIndividuFilter: builder.query<TOption[], TRincianIndividuFilter>({
      query: (payload) => ({
        url: "/web/production/newods/findAllPolicyStatusYearly",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetRincianIndividuMTDMutation,
  useGetRincianIndividuYTDMutation,
  useGetTotalRincianIndividuMTDMutation,
  useGetTotalRincianIndividuYTDMutation,

  useGetRincianUnitMTDMutation,
  useGetTotalRincianUnitMTDMutation,

  useGetRincianUnitYTDMutation,
  useGetTotalRincianUnitYTDMutation,

  useGetRincianGroupMTDMutation,
  useGetTotalRincianGroupMTDMutation,

  useGetYearQuery,

  useGetProductionSummaryQuery,

  useProduksiDownloadMutation,
  useGetProductionsQuery,
  useGetRincianIndividuFilterQuery,
  useGetYearModifMutation,
} = produksiApi;
